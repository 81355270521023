import React from 'react';
import DistributerForm from './DistributerForm';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import { CommunityForm } from 'common/presentation/components';

const BecomeDistributer = () => (
	<main>
		<CommunityProvier>
			<DistributerForm />
			<CommunityForm />
		</CommunityProvier>
	</main>
);

export default BecomeDistributer;
