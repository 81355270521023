import { Typography, TypographyProps, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

interface Props extends TypographyProps {
	size?: Dimens.TILE_TEXT_XL | Dimens.TITLE_TEXT_L | Dimens.TITEL_TEXT_M;
	color?: string
}

const TitleText: React.FC<Props> = (props) => {
	const theme = useTheme();
	const {
		children, sx, size, color, ...remainingProps
	} = props;

	return (
		<Typography
			variant="h3"
			{...remainingProps}
			sx={{
				// fontWeight: Dimens.FONT_BOLDER,
				// lineHeight: {
				// 	lg: Dimens.TEXT_TITLE,
				// 	xl: Dimens.TEXT_TITLE,
				// 	md: Dimens.TEXT_TITLE_LARGE_MOBILE,
				// 	xs: Dimens.TEXT_TITLE_MOBILE,
				// },
				fontSize: {
					md: size,
				},
				color: color ?? theme.palette.textMain.main,
				...sx,
			}}
		>
			{children}

		</Typography>
	);
};

export default TitleText;
