import { PhotoCamera } from '@mui/icons-material';
import {
	IconButton,
	InputAdornment,
} from '@mui/material';
import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BackofficeTextField from '../input/BackofficeTextField';

interface FileInputProps {
	label: string;
	placeholder?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	helperText?: string;
	error?: boolean;
	name?: string
}
const FileInput:React.FC<FileInputProps> = (props) => {
	const { t } = useTranslation('translations');
	const {
		label,
		placeholder = t('no_file_choosen'),
		onChange,
		disabled,
		helperText,
		error,
		name,
	} = props;
	const fileRef = useRef<HTMLInputElement | null>(null);
	const onFocus = () => {
		fileRef.current?.click();
	};

	return (
		<>
			<input ref={fileRef} disabled={disabled} name={name} type="file" hidden onChange={onChange} />
			<BackofficeTextField
				variant="outlined"
				placeholder={placeholder}
				fullWidth
				onClick={onFocus}
				label={label}
				disabled={disabled}
				helperText={helperText}
				error={error}
				size="small"
				InputLabelProps={{ shrink: true }}
				value={placeholder}
				InputProps={{
					readOnly: true,
					endAdornment: (
						<InputAdornment position="end">
							<IconButton disabled={disabled}>
								<PhotoCamera color="primary" />
							</IconButton>
						</InputAdornment>),
				}}
			/>
		</>

	);
};

export default FileInput;
