import { Either } from '@ajtec/either-typescript';
import { IProductBackofficeRepository } from '../repository/product.repository';
import { AxiosError } from 'axios';
import { ProductBackoffice } from '../entities/backoffice-product';

export class GetProductById {
	repository: IProductBackofficeRepository;

	constructor(productRepository: IProductBackofficeRepository) {
		this.repository = productRepository;
	}

	call(id: string): Promise<Either<AxiosError, ProductBackoffice>> {
		return this.repository.getProductById(id);
	}
}
