import Dimens from 'assets/dimens';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, DefaultButton, TitleText } from 'common/presentation/components';
import { notFoundSearch } from 'assets/images';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

interface Props {
	searchText: string
}

const NoSearchResults: React.FC<Props> = (props) => {
	const { searchText } = props;
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				pt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
		>
			<BodyText
				dangerouslySetInnerHTML={{ __html: t('not_found_search_text').format(searchText) }}
				sx={{
					fontSize: {
						md: Dimens.BODY_TEXT_XL,
						xs: Dimens.BODY_TEXT_XL_MOBILE,
					},
					textTransform: 'uppercase',
					fontStyle: 'italic',
				}}
			/>
			<Stack alignItems="center" justifyContent="center" sx={{ height: '40vh' }}>
				<img src={notFoundSearch} alt="Not found" />
			</Stack>
			<Stack
				spacing={2}
				direction={{ md: 'row', xs: 'column', sm: 'column' }}
				justifyContent="space-between"
				alignItems={{ md: 'center', xs: 'flex-start', sm: 'flex-start' }}
				sx={{ mt: Dimens.TITLE_SPACING }}
			>
				<Box>
					<TitleText
						sx={{
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
							fontWeight: Dimens.FONT_WEIGHT_BOLDER,
						}}
					>
						{t('meet_our_devices').toUpperCase()}
					</TitleText>
					<BodyText
						sx={{
							fontSize: {
								md: Dimens.BODY_TEXT_XL,
								xs: Dimens.BODY_TEXT_M_MOBILE,
							},
							fontStyle: 'italic',
						}}
					>
						{t('learn_each_of_them').toUpperCase()}
					</BodyText>
				</Box>
				<DefaultButton onClick={() => navigate(AppLinks.STORE)}>
					{t('go_to_store')}
				</DefaultButton>
			</Stack>
			<Stack
				spacing={2}
				direction={{ md: 'row', xs: 'column', sm: 'column' }}
				justifyContent="space-between"
				alignItems={{ md: 'center', xs: 'flex-start', sm: 'flex-start' }}
				sx={{ mt: Dimens.TITLE_SPACING }}
			>
				<Box>
					<TitleText
						sx={{
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
							fontWeight: Dimens.FONT_WEIGHT_BOLDER,
						}}
					>
						{t('get_to_know_iokee_users').toUpperCase()}
					</TitleText>
					<BodyText
						sx={{
							fontSize: {
								md: Dimens.BODY_TEXT_XL,
								xs: Dimens.BODY_TEXT_M_MOBILE,
							},
							fontStyle: 'italic',
						}}
					>
						{t('get_to_know_iokee_users_info').toUpperCase()}
					</BodyText>
				</Box>
				<DefaultButton>
					{t('go_to_company')}
				</DefaultButton>
			</Stack>
			<Stack
				spacing={2}
				direction={{ md: 'row', xs: 'column', sm: 'column' }}
				justifyContent="space-between"
				alignItems={{ md: 'center', xs: 'flex-start', sm: 'flex-start' }}
				sx={{ mt: Dimens.TITLE_SPACING }}
			>
				<Box>
					<TitleText
						sx={{
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
							fontWeight: Dimens.FONT_WEIGHT_BOLDER,
						}}
					>
						{t('company_values').toUpperCase()}
					</TitleText>
					<BodyText
						sx={{
							fontSize: {
								md: Dimens.BODY_TEXT_XL,
								xs: Dimens.BODY_TEXT_M_MOBILE,
							},
							fontStyle: 'italic',
						}}
					>
						{t('company_values_info').toUpperCase()}
					</BodyText>
				</Box>
				<DefaultButton onClick={() => navigate(AppLinks.ABOUT_US_PAGE)}>
					{t('go_to_aboutus')}
				</DefaultButton>
			</Stack>
		</Box>
	);
};

export default NoSearchResults;
