import Dimens from 'assets/dimens';
import {
	Box, IconButton, TextField, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { logoBlack } from 'assets/icons';
import { BodyText, ClientButton, TitleText } from 'common/presentation/components';
// import { useNavigate } from 'react-router-dom';
// import AppLinks from 'assets/applinks.routes';
import { useTranslation } from 'react-i18next';
import { validateEmail, validatePassword } from 'utils/validation';
import useAuth from '../provider/auth.provider';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const LoginForm = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const isMd = useMediaQuery(theme.breakpoints.up('md'));

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const firstRenderRef = useRef(true);
	const navigate = useNavigate();
	const { login, loading, error } = useAuth();
	// const navigate = useNavigate();
	const handleLoginClick = (event: React.FormEvent) => {
		event.preventDefault();
		// navigate(AppLinks.CLIENT_AREA_DEVICE_LIST);
		login({ email, password });
	};

	useEffect(() => {
		if (!firstRenderRef.current) {
			if (!validateEmail(email)) {
				setEmailError(t('email_error'));
			} else {
				setEmailError('');
			}
			if (!validatePassword(password)) {
				setPasswordError(t('password_error'));
			} else {
				setPasswordError('');
			}
		}
		return () => { firstRenderRef.current = false; };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email, password]);

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};
	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};
	const handleForgotPasswordClick = () => {
		navigate(AppLinks.CLIENT_AREA_FORGOT_PASSWORD);
	};

	const getShowPasswordButton = () => {
		if (isPasswordVisible) {
			return (
				<IconButton onClick={() => setIsPasswordVisible(false)} sx={{ m: 0, p: 0 }}>
					<VisibilityOff />
				</IconButton>
			);
		}
		return (
			<IconButton sx={{ m: 0, p: 0 }} onClick={() => setIsPasswordVisible(true)}>
				<Visibility />
			</IconButton>
		);
	};

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			pr: { md: 0, xs: Dimens.PAGE_PADDING_MOBILE },
			pt: { md: Dimens.FOOTER_PADDING_VERTICAL, xs: '0px' },
			pb: { xs: Dimens.PAGE_PADDING_MOBILE, md: 0 },
			width: { md: '40%', xs: '100%' },
		}}
		>
			{isMd && <img src={logoBlack} alt="Logo" />}
			<TitleText sx={{
				fontSize: Dimens.TITEL_TEXT_M,
				color: theme.palette.textMain.main,
				fontWeight: Dimens.FONT_WEIGHT_BOLDER,
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM },
			}}
			>
				{t('welcome_back')}
			</TitleText>
			<BodyText sx={{ fontSize: Dimens.BODY_TEXT_XL, mt: 2 }}>
				{t('your_energy_in_your_hand').toUpperCase()}
			</BodyText>
			<BodyText sx={{ fontSize: Dimens.BODY_TEXT_M, mt: 5, display: { md: 'block', xs: 'none' } }}>
				{t('manage_your_energy_consumption')}
			</BodyText>
			<form onSubmit={handleLoginClick}>

				<TextField value={email} variant="outlined" onChange={handleEmailChange} error={Boolean(emailError)} helperText={emailError} fullWidth label="E-mail" type="email" sx={{ mt: { md: 10, xs: 2 } }} />
				<TextField
					value={password}
					InputProps={{
						endAdornment: getShowPasswordButton(),
					}}
					onChange={handlePasswordChange}
					error={Boolean(passwordError)}
					helperText={passwordError}
					variant="outlined"
					fullWidth
					label={t('password')}
					type={isPasswordVisible ? 'text' : 'password'}
					sx={{ mt: 2 }}
				/>
				<BodyText
					onClick={handleForgotPasswordClick}
					sx={{
						fontWeight: Dimens.FONT_WEIGHT_BOLD, textAlign: 'right', mt: 1, mb: 4, cursor: 'pointer',
					}}
				>
					{t('forgot_your_password')}
				</BodyText>
				{error && <BodyText sx={{ color: theme.palette.error.main, textAlign: 'center' }}>{error}</BodyText>}
				<ClientButton loading={loading} fullWidth type="submit">{t('enter')}</ClientButton>
			</form>
		</Box>
	);
};

export default LoginForm;
