/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import { ErrorResponseBase } from 'common/domain/entities/error-response';
import { ICharge, IChargeParams, IPageItem } from '../../entities/charges.model';
import { IUserData } from '../../entities/users.model'; // Ensure correct import of IUserData
import { WallboxApiImpl } from '../../data/walbox-api-impl';
import { IChargeEventData } from '../../entities/charge-event.model';

type TWallboxProvider = {
  charges: ICharge;
  loading: boolean;
  loadingEvents: boolean;
  error: ErrorResponseBase | null;
  users: IUserData[]; // Users state to be used in the dropdown list,
  chargeEventsData?: IChargeEventData;
  chargeParams?: IChargeParams;
  chargeList: IPageItem[];
  getWallboxCharges: (params: IChargeParams) => void;
  getWallboxUsers: (productId: number) => void; // Function to fetch users
  getWallboxEvent: (chargeId: number, page: number, size: number, eventData: IChargeEventData | undefined) => void; // Function to fetch users
  setChargeParams: (params: IChargeParams) => void;
  getWallboxChargesIncreasePage: () => void;
};

const WallboxContext = createContext({} as TWallboxProvider);

export const WallboxProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [charges, setCharges] = useState<ICharge>({} as ICharge);
	const [chargeList, setChargeList] = useState<IPageItem[]>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<ErrorResponseBase | null>(null);
	const [users, setUsers] = useState<IUserData[]>([]); // Users state
	const [chargeEventsData, setChargeEventsData] = useState<IChargeEventData>(); //

	const [chargeParams, setChargeParams] = useState<IChargeParams>();

	const [loadingEvents, setLoadingEvents] = useState(false);

	const [loadingMoreCharges, setLoadingMoreCharges] = useState(true);

	const WallboxApi = new WallboxApiImpl();

	// Function to fetch charges
	const getWallboxCharges = async (params: IChargeParams) => {
		setLoading(true);
		try {
			const resp = await WallboxApi.getCharges(params);
			setCharges(resp.data);
			if (params.page === 1) {
				setLoadingMoreCharges(resp.data.pageNumber !== resp.data.pagesTotal);
				setChargeList([...resp.data.pageItems]);
			} else {
				setChargeList([...chargeList, ...resp.data.pageItems]);
			}

			setError(null);
			if (resp.data.pageItems.length < (params.size ?? 10) || resp.data.pageItems.length === 0) {
				setLoadingMoreCharges(false);
			}
		} catch (mError: any) {
			setError(mError?.response?.data?.responseErrors[0]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (chargeParams) {
			getWallboxCharges(chargeParams);
		}
	}, [chargeParams]);

	const getWallboxChargesIncreasePage = () => {
		if (loadingMoreCharges) {
			const nextPage = (chargeParams?.page ?? 1) + 1;
			setChargeParams({ ...chargeParams, page: nextPage } as IChargeParams);
		}
	};

	// Function to fetch users
	const getWallboxUsers = useCallback(async (productId: number) => {
		try {
			const resp = await WallboxApi.getUsers(productId);
			if (Array.isArray(resp.data)) {
				setUsers(resp.data);
			} else {
				setUsers([]);
			}
		} catch (mError: any) {
			setUsers([]);
		}
	}, []);

	const getWallboxEvent = useCallback(async (chargeId: number, page: number, size: number, eventData: IChargeEventData | undefined) => {
		if (eventData && page > eventData.pagesTotal) {
			return;
		}
		if (eventData && page === 1) {
			setChargeEventsData(undefined);
		}
		setLoadingEvents(true);
		try {
			const resp = await WallboxApi.getChargeEvents({
				chargeId,
				page,
				size,
			});
			if (page === 1 && !eventData) {
				setChargeEventsData(resp.data);
			} else if (page !== 1) {
				setChargeEventsData((prev) => {
					if (prev) {
						return {
							itemsTotal: resp.data.itemsTotal,
							pageNumber: resp.data.pageNumber,
							pageSize: resp.data.pageSize,
							pagesTotal: resp.data.pagesTotal,
							pageItemsTotal: resp.data.pageItemsTotal,
							pageFirstItemIndex: resp.data.pageFirstItemIndex,
							pageLastItemIndex: resp.data.pageLastItemIndex,
							pageItems: [...prev.pageItems, ...resp.data.pageItems],
						};
					}
					return resp.data;
				});
			}
		} catch (mError: any) {
			setChargeEventsData(undefined);
		} finally {
			setLoadingEvents(false);
		}
	}, []);

	const value = useMemo(
		() => ({
			charges,
			loading,
			error,
			users,
			chargeEventsData,
			loadingEvents,
			chargeParams,
			chargeList,
			getWallboxCharges,
			getWallboxUsers,
			getWallboxEvent,
			setChargeParams,
			getWallboxChargesIncreasePage,
		}),
		[charges, loading, loadingEvents, error, users, chargeEventsData],
	);

	return <WallboxContext.Provider value={value}>{children}</WallboxContext.Provider>;
};

export function useWallbox() {
	return React.useContext(WallboxContext);
}
