/* eslint-disable react/no-unstable-nested-components */
import {
	Navigate,
	Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import React, { useEffect } from 'react';
import LandingPage from 'features/landing/presentation';
import AppLinks from 'assets/applinks.routes';
import { CommunityPage } from './features/community/presentation';
import CommunityDetail from './features/community/presentation/community-detail';
import Main from './Main';
import ClientAreaMain from './ClientAreaMain';
import ClientLogin from './features/client-login/presentation/login-page';
import DeviceList from './features/client-device-list/presentation';
import DeviceDetail from './features/client-device-detail/presentation';
import ClientTariff from './features/client-tariff/presentation';
import ClientBilling from './features/client-billing/presentation';
import useAuth from './features/client-login/presentation/provider/auth.provider';
import ClientAreaAuthenticated from './ClientAreaAuthenticated';
import MobileAppPage from './features/mobile-app/presentation';
import ContactUs from './features/contact-us/presentation';
import FinalizeOrder from './features/checkout/presentation/finalize-order';
import ProductSummary from './features/checkout/presentation/cart-page/ProductSummary';
import CheckoutSuccess from './features/checkout/presentation/checkout-success';
import ForgotPassword from './features/client-login/presentation/forgot-password';
import StorePage from './features/products/presentation/store';
import StartScreen from './features/find-your-iokee/presentation/start-screen';
import FindYourIokeeSteps from './features/find-your-iokee/presentation/steps';
import UserRegistration from './features/store-authentication/presentation/registration';
import FoundDevices from './features/find-your-iokee/presentation/found-devices/FoundDevices';
import ProductDetailPage from './features/products/presentation/product-detail';
import CategoryDetailPage from './features/products/presentation/device-category-detail';
import AuthPage from './features/store-authentication/presentation/auth-welcome';
import BecomeDistributer from './features/store-authentication/presentation/become-distributer';
import SearchPage from './features/search/presentation';
import BackofficeLoginPage from './features/backoffice/features/auth/presentation/login/BackofficeLoginPage';
import { useBackofficeAuth } from './features/backoffice/features/auth/presentation/provider/auth.provider';
import BackofficeOrdersPage from './features/backoffice/features/orders/presentation/BackofficeOrdersPage';
import BackofficeProductCategoriesPage
	from './features/backoffice/features/product-categories/presentation/BackofficeProductCategoriesPage';
import BackofficeAddEditProductCategories
	from './features/backoffice/features/product-categories/presentation/form/BackofficeAddEditProductCategories';
import Backoffice from './features/backoffice/Backoffice';
import BackofficeCommunityRequestsPage
	from './features/backoffice/features/community-requests/presentation/BackofficeCommunityRequestsPage';
import BackofficeAddEditProduct
	from './features/backoffice/features/products/presentation/form/BackofficeAddEditProduct';
import BackofficeProductsPage from './features/backoffice/features/products/presentation/BackofficeProductsPage';
import AboutUs from './features/about-us/presentation';
import StoreLogin from './features/store-authentication/presentation/login';
import BackofficeQuestionsPage from './features/backoffice/features/questions/presentation/BackofficeQuestionsPage';
// eslint-disable-next-line max-len
import BackofficeAddEditQuestions from './features/backoffice/features/questions/presentation/form/BackofficeAddEditQuestions';
import WallboxDetail from './features/client-wallbox-history/presentation';
import BackofficeAddEditAnswers from './features/backoffice/features/questions/presentation/answers/BackofficeAddEditAnswers';
import FindYourIokeeMain from './features/find-your-iokee/presentation/FindYourIokeeMain';

const AppRouter = () => {
	const RequireAuth = ({ children }: { children: JSX.Element }) => {
		const { user } = useAuth();
		const location = useLocation();
		const navigate = useNavigate();
		useEffect(() => {
			if (user) {
				if (location.pathname.includes('client-area')) {
					navigate(AppLinks.CLIENT_AREA_DEVICE_LIST);
				}
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [user]);

		if (!user) {
			return <Navigate to={AppLinks.CLIENT_AREA_LOGIN} state={{ from: location }} replace />;
		}

		return children;
	};

	const RequireBackofficeAuth = ({ children }: { children: JSX.Element }) => {
		const { backofficeUser } = useBackofficeAuth();
		const location = useLocation();
		const navigate = useNavigate();

		useEffect(() => {
			if (backofficeUser) {
				if (location.pathname.includes('backoffice')) {
					navigate(AppLinks.BACKOFFICE_DASHBOARD);
				}
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [backofficeUser]);

		if (!backofficeUser) {
			return <Navigate to={AppLinks.BACKOFFICE_LOGIN} state={{ from: location }} replace />;
		}

		return children;
	};

	return (
		<Routes>
			{/* Add your routes here */}
			<Route path={AppLinks.MAIN} element={<Main />}>
				<Route path={AppLinks.LANDING} element={<LandingPage />} />
				<Route path={AppLinks.COMMUNITY} element={<CommunityPage />} />
				<Route path={AppLinks.COMMUNITY_DETAIL} element={<CommunityDetail />} />
				<Route path={AppLinks.PRODUCT} element={<ProductDetailPage />} />
				<Route path={AppLinks.CHECKOUT_PAGE} element={<ProductSummary />} />
				<Route path={AppLinks.MOBILE_APP} element={<MobileAppPage />} />
				<Route path={AppLinks.CONTACT_US} element={<ContactUs />} />
				<Route path={AppLinks.FINALIZE_ORDER} element={<FinalizeOrder />} />
				<Route path={AppLinks.CHECKOUT_SUCCESS} element={<CheckoutSuccess />} />
				<Route path={AppLinks.STORE} element={<StorePage />} />
				<Route path={AppLinks.PRODUCT_CATEGORY_DETAIL} element={<CategoryDetailPage />} />
				<Route path={AppLinks.FIND_YOUR_IOKEE} element={<FindYourIokeeMain />}>
					<Route index element={<StartScreen />} />
					<Route path={AppLinks.FIND_YOUR_IOKEE_PURPOSE} element={<FindYourIokeeSteps />} />
					<Route path={AppLinks.FIND_YOUR_IOKEE_FOUND_DEVICES} element={<FoundDevices />} />
				</Route>
				<Route path={AppLinks.STORE_MAIN_AUTH_PAGE} element={<AuthPage />} />
				<Route path={AppLinks.STORE_AREA_REGISTRATION} element={<UserRegistration />} />
				<Route path={AppLinks.STORE_AREA_LOGIN} element={<StoreLogin />} />
				<Route path={AppLinks.STORE_BECOME_DISTRIBUTER} element={<BecomeDistributer />} />
				<Route path={AppLinks.ABOUT_US_PAGE} element={<AboutUs />} />
				<Route path={AppLinks.SEARCH_RESULT_PAGE} element={<SearchPage />} />
			</Route>

			<Route path={AppLinks.CLIENT_AREA} element={<ClientAreaMain />}>
				<Route path={AppLinks.CLIENT_AREA_LOGIN} element={<ClientLogin />} />
				<Route path={AppLinks.CLIENT_AREA_FORGOT_PASSWORD} element={<ForgotPassword />} />
				<Route
					path={AppLinks.CLIENT_AREA_AUTH}
					element={(
						<RequireAuth>
							<ClientAreaAuthenticated />
						</RequireAuth>
					)}
				>
					<Route path={AppLinks.CLIENT_AREA_DEVICE_LIST} element={<DeviceList />} />
					<Route path={AppLinks.CLIENT_AREA_DEVICE_DETAIL} element={<DeviceDetail />} />
					<Route path={AppLinks.CLIENT_AREA_TARIFF} element={<ClientTariff />} />
					<Route path={AppLinks.CLIENT_AREA_BILLING} element={<ClientBilling />} />
					<Route path={AppLinks.CLIENT_AREA_WALLBOX_HISTORY} element={<WallboxDetail />} />
				</Route>
			</Route>
			<Route path={AppLinks.BACKOFFICE_LOGIN} element={<BackofficeLoginPage />} />
			<Route
				path={AppLinks.BACKOFFICE_DASHBOARD}
				element={<RequireBackofficeAuth><Backoffice /></RequireBackofficeAuth>}
			>
				<Route index element={<BackofficeProductsPage />} />
				<Route path={AppLinks.BACKOFFICE_ADD_PRODUCT} element={<BackofficeAddEditProduct />} />
				<Route path={AppLinks.BACKOFFICE_EDIT_PRODUCT} element={<BackofficeAddEditProduct />} />
				<Route index element={<BackofficeAddEditProductCategories />} />
				<Route path={AppLinks.BACKOFFICE_ORDERS} element={<BackofficeOrdersPage />} />
				<Route
					path={AppLinks.BACKOFFICE_CATEGORIES}
					element={<BackofficeProductCategoriesPage />}
				/>
				<Route
					path={AppLinks.BACKOFFICE_ADD_PRODUCT_CATEGORIES}
					element={<BackofficeAddEditProductCategories />}
				/>
				<Route
					path={AppLinks.BACKOFFICE_EDIT_PRODUCT_CATEGORIES}
					element={<BackofficeAddEditProductCategories />}
				/>
				<Route
					path={AppLinks.BACKOFFICE_COMMUNITY_REQUESTS}
					element={<BackofficeCommunityRequestsPage />}
				/>
				<Route
					path={AppLinks.BACKOFFICE_QUESTIONS}
					element={<BackofficeQuestionsPage />}
				/>
				<Route
					path={AppLinks.BACKOFFICE_ADD_QUESTIONS}
					element={<BackofficeAddEditQuestions />}
				/>
				<Route
					path={AppLinks.BACKOFFICE_EDIT_QUESTIONS}
					element={<BackofficeAddEditQuestions />}
				/>
				<Route
					path={AppLinks.BACKOFFICE_ADD_ANSWERS}
					element={<BackofficeAddEditAnswers />}
				/>
			</Route>
		</Routes>
	);
};

export default AppRouter;
