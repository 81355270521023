import React from 'react';
import { Box } from '@mui/material';
import moment from 'moment';
import ChargingCard from './ChargingCard';
import { BodyText } from 'common/presentation/components';
import { IPageItem } from '../../entities/charges.model';
import { useWallbox } from '../provider/wallbox.provider';

interface ChargingDetailsProps {
  onCardClick: (charge: IPageItem) => void;
  expandedCard: string | null;
  chargeItems: IPageItem[];
}

const ChargingDetails: React.FC<ChargingDetailsProps> = ({
	onCardClick, expandedCard, chargeItems,
}) => {
	const { getWallboxChargesIncreasePage } = useWallbox();

	const handleScroll = (e: any) => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			getWallboxChargesIncreasePage();
		}
	};

	return (
		<Box
			onScroll={handleScroll}
			sx={{
				maxHeight: '400px',
				overflowY: 'scroll',
				paddingRight: '16px',
				'&::-webkit-scrollbar': { width: '8px' },
				'&::-webkit-scrollbar-thumb': { backgroundColor: '#00E5FF', borderRadius: '8px' },
			}}
		>

			{chargeItems.length ? (
				chargeItems.map((charge: IPageItem) => {
					// Safely calculate duration; handle potential date parsing errors
					let duration = 'N/A';
					try {
						const start = new Date(charge.startUtc);
						const end = new Date(charge.endUtc);
						if (!Number.isNaN(start.getTime()) && !Number.isNaN(end.getTime())) {
							const diffInMinutes = Math.round((end.getTime() - start.getTime()) / 60000);
							const durationObj = moment.duration(diffInMinutes, 'minutes');
							// Format the duration to show hours and minutes
							const hours = durationObj.hours();
							const minutes = durationObj.minutes();
							duration = hours > 0 ? `${hours} hr ${minutes} min` : `${minutes} min`;
						}
					} catch {
						duration = 'Invalid Date';
					}

					// Format the startLocal time nicely with moment
					const formattedTime = charge.startLocal
						? moment(charge.startLocal).format('DD MMM YYYY, HH:mm') : 'N/A';

					// Safely format energyCost to avoid calling toFixed on null or undefined
					const energyCost = charge.energyCost
						!== null && charge.energyCost !== undefined ? charge.energyCost.toFixed(2) : '0.00';

					return (
						<ChargingCard
							key={charge.chargeID}
							time={formattedTime}
							duration={duration}
							energy={`${((charge.energyConsumption || 0) / 1000)} kWh`}
							amount={energyCost}
							email={charge.initiator?.email || 'N/A'}
							onClick={() => onCardClick(charge)}
							selected={expandedCard === charge.chargeID.toString()}
						/>
					);
				})
			) : (
				<BodyText>No charges available.</BodyText>
			)}
		</Box>
	);
};

export default ChargingDetails;
