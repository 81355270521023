import React from 'react';
import FinalizeForm from './FinalizeForm';
import { OrderProvider } from '../provider/order-provider';

const FinalizeOrder = () => (
	<main>
		<OrderProvider>
			<FinalizeForm />
		</OrderProvider>
	</main>
);

export default FinalizeOrder;
