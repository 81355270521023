/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { IProductCategory, IProductCategoryReq } from '../../domain/entities/product-categories';
import { ICategoryRepository } from '../../domain/repository/categories-repository';
import { CategoryApiImpl } from '../remote/categories-api-impl';
import { ApiResponse } from 'common/data/entities/api-response';
import { Either, Left, Right } from '@ajtec/either-typescript';

export class CategoryRepositoryImpl implements ICategoryRepository {
	categoryApi = new CategoryApiImpl();

	async addCategory(params: IProductCategoryReq): Promise<Either<AxiosError, ApiResponse<string>>> {
		try {
			const addCategoryRes = await this.categoryApi.addCategory(params);
			return new Right(addCategoryRes);
		} catch (error: any) {
			return new Left(error);
		}
	}

	async getCategories(): Promise<Either<AxiosError, IProductCategory[]>> {
		try {
			const getCategoriesRes = await this.categoryApi.getCategories();
			return new Right(getCategoriesRes);
		} catch (error: any) {
			return new Left(error);
		}
	}

	async getCategoryById(id: string): Promise<Either<AxiosError, IProductCategory>> {
		try {
			const getCategoriesRes = await this.categoryApi.getCategoryById(id);
			return new Right(getCategoriesRes);
		} catch (error: any) {
			return new Left(error);
		}
	}

	async updateCategoryById(id: string, attachmentId: number, params: IProductCategoryReq):
		Promise<Either<AxiosError, IProductCategory>> {
		try {
			const updateCategoryRes = await this.categoryApi.updateCategoryById(id, attachmentId, params);
			return new Right(updateCategoryRes);
		} catch (error: any) {
			return new Left(error);
		}
	}

	async deleteCategoryById(id: string): Promise<Either<AxiosError, string>> {
		try {
			const deleteCategoryRes = await this.categoryApi.deleteCategoryById(id);
			return new Right(deleteCategoryRes);
		} catch (error: any) {
			return new Left(error);
		}
	}
}
