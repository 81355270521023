import { Either } from '@ajtec/either-typescript';
import { ILoginRepository } from '../repository/login-repository';

export class ForgotPassword {
	repository: ILoginRepository;

	constructor(loginRepository: ILoginRepository) {
		this.repository = loginRepository;
	}

	call(params: string): Promise<Either<string, string>> {
		return this.repository.forgotPassword(params);
	}
}
