import React from 'react';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import { CommunityForm } from 'common/presentation/components';
import AboutUsPage from './AboutUsPage';

const AboutUs = () => (
	<main>
		<CommunityProvier>
			<AboutUsPage />
			<CommunityForm />
		</CommunityProvier>
	</main>
);

export default AboutUs;
