import { Either, Left, Right } from '@ajtec/either-typescript';
import { ILandingRepository } from '../../domain/repository/landing-repository';
import { AxiosError } from 'axios';
import { ICategories } from '../../domain/entities/categories';
import { LandingApiImpl } from '../remote/landing-api-impl';
import { ErrorResponse } from 'common/domain/entities/error-response';

export class LandingRepositoryImpl implements ILandingRepository {
	landingApi = new LandingApiImpl();

	async getCategories(isVisible: boolean): Promise<Either<AxiosError, ICategories[]>> {
		try {
			const catRes = await this.landingApi.getCategories(isVisible);
			return new Right(catRes);
		} catch (error) {
			throw new Left(error);
		}
	}

	async getCategoryById(id: string): Promise<Either<ErrorResponse, ICategories>> {
		try {
			const catRes = await this.landingApi.getCategoryById(id);
			return new Right(catRes);
		} catch (error) {
			throw new Left(error);
		}
	}
}
