import { Either, Left, Right } from '@ajtec/either-typescript';
import { AuthResponse, LoginParams } from '../../domain/entities/login';
import { IAuthRepository } from '../../domain/repository/auth-repository';
import { AuthApiImpl } from '../remote/auth-api-impl';

export class AuthRepositoryImpl implements IAuthRepository {
	authApi = new AuthApiImpl();

	async login(params: LoginParams): Promise<Either<string, AuthResponse>> {
		try {
			const authRes = await this.authApi.login(params);
			return new Right(authRes);
		} catch (error) {
			throw new Left(error);
		}
	}
}
