/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
import { IUser } from 'features/client-login/domain/entities/user';
import React, {
	createContext, FC, useContext, useMemo, useState,
} from 'react';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import AppLinks from 'assets/applinks.routes';
import { useNavigate } from 'react-router-dom';
import { CLoginRepositoryImpl } from '../../data/repository/login-repository-impl';
import { Login, LoginParams } from '../../domain/usecases/login';
import { GetAlerts } from '../../domain/usecases/get-alerts';
import { IAlert } from '../../domain/entities/alert';
import { ForgotPassword } from '../../domain/usecases/forgot-password';

interface AuthContextProps {
   user?: IUser | null;
   setCurrentUser: (user: IUser | null) => void;
   loading: boolean;
   login: (params: LoginParams) => void;
   error: string;
   getAlerts: () => void;
   alerts: IAlert[];
   logoutUser: () => void;
   forgotPassword: (email:string) => void;
   forgotPasswordResp: string
}

const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const tempUser = LocalStorageWrapper.get<IUser>(LocalStorageKeys.USER);
	const [user, setUser] = useState<IUser | null>(tempUser);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [alerts, setAlerts] = useState([] as IAlert[]);
	const [forgotPasswordResp, setForgotPasswordResp] = useState('');
	const navigate = useNavigate();

	const loginRepository = new CLoginRepositoryImpl();
	const getAlerts = async () => {
		const getAlertsUseCase = new GetAlerts(loginRepository);
		const getAlertsResult = await getAlertsUseCase.call();
		if (getAlertsResult.isRight()) {
			setAlerts(getAlertsResult.value);
		} else {
			setError(getAlertsResult.error);
		}
		setLoading(false);
	};

	const login = async (params: LoginParams) => {
		setLoading(true);
		const loginUseCase = new Login(loginRepository);
		const loginResult = await loginUseCase.call(params);
		if (loginResult.isRight()) {
			setUser(loginResult.value);
			LocalStorageWrapper.set(LocalStorageKeys.USER, loginResult.value);
			getAlerts();
			navigate(AppLinks.CLIENT_AREA_DEVICE_LIST);
		} else {
			setError(loginResult.error);
		}
		setLoading(false);
	};

	const forgotPassword = async (email: string) => {
		setLoading(true);
		const forgotPasswordUseCase = new ForgotPassword(loginRepository);
		const forgotPasswordResult = await forgotPasswordUseCase.call(email);
		if (forgotPasswordResult.isRight()) {
			setForgotPasswordResp(forgotPasswordResult.value);
		} else {
			setError(forgotPasswordResult.error);
		}
		setLoading(false);
	};

	const setCurrentUser = (currentUser: IUser | null) => {
		setUser(currentUser);
	};

	const logoutUser = () => {
		LocalStorageWrapper.remove(LocalStorageKeys.USER);
		navigate(AppLinks.LANDING);
	};

	const value = useMemo(() => ({
		user,
		setCurrentUser,
		loading,
		error,
		login,
		getAlerts,
		alerts,
		logoutUser,
		forgotPasswordResp,
		forgotPassword,
	}), [user, loading, error, alerts, forgotPasswordResp]);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
	return useContext(AuthContext);
}
