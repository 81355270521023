import React from 'react';
import { TitleText } from 'common/presentation/components';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import MonophaseHidden from '../components/MonophaseHidden';

const TechnicalSpecification = () => {
	const { t } = useTranslation('translations');
	return (
		<Box
			id="technical-specification"
			sx={{
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE },
			}}
		>
			<TitleText
				sx={{
					fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
				}}
				dangerouslySetInnerHTML={{ __html: t('technical_specifications').toUpperCase() }}
			/>
			<MonophaseHidden />
		</Box>
	);
};

export default TechnicalSpecification;
