import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { IProductCategory, IProductCategoryErrors, IProductCategoryReq } from '../product-categories/domain/entities/product-categories';
import { useTranslation } from 'react-i18next';
import { CategoryRepositoryImpl } from '../product-categories/data/repository/categories-repository-impl';
import { AddCategory } from '../product-categories/domain/usecases/add-category';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { GetCategories } from '../product-categories/domain/usecases/get-categories';
import { GetCategoryById } from '../product-categories/domain/usecases/get-category-by-id';
import { UpdateCategory } from '../product-categories/domain/usecases/update-category';
import { DeleteCategoryById } from '../product-categories/domain/usecases/delete-category';

interface FetchState {
	loading: boolean;
	error: string;
}

type Props = {
	formValues: IProductCategoryReq
	formErrors: IProductCategoryErrors
	onChange: (key: string, value: string | boolean | File) => void
	submit: (id?: string) => void
	getCategories: () => void
	categories: IProductCategory[]
	loading: boolean
	getCategoryById: (id: string) => void
	deleteCategoryById: (id: string) => void
	setFormValues: React.Dispatch<React.SetStateAction<IProductCategoryReq>>
	categoriesFetchState: FetchState
}

export const ProductCategoriesContext = createContext({} as Props);

export const ProductCategoriesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { addDialog, addToast } = useFeedback();
	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState([] as IProductCategory[]);
	const [categoriesFetchState, setCategoriesFetchState] = useState<FetchState>({
		loading: false,
		error: '',
	});
	const [formValues, setFormValues] = useState<IProductCategoryReq>({
		name: '',
		file: '',
		subtitle: '',
		description: '',
		isVisible: true,
	});
	const [formErrors, setFormErrors] = useState({
		name: '',
		file: '',
		subtitle: '',
		description: '',
		isVisible: false,
	});
	const [attachmentId, setAttachmentId] = useState(0);
	const categoryRepository = new CategoryRepositoryImpl();

	const getCategories = async () => {
		setCategoriesFetchState({ error: '', loading: true });
		const getCategoriesUseCase = new GetCategories(categoryRepository);
		const categoriesResp = await getCategoriesUseCase.call();
		if (categoriesResp.isRight()) {
			setCategories(categoriesResp.value);
			setCategoriesFetchState({ error: '', loading: false });
		} else {
			addDialog({
				error: true,
				title: '',
				message: '',
			});
			setCategoriesFetchState({ error: 'An Error occured', loading: false });
		}
	};

	const deleteCategoryById = async (id: string) => {
		const deleteCategoriesUseCase = new DeleteCategoryById(categoryRepository);
		const deleteCategoriesResp = await deleteCategoriesUseCase.call(id);
		if (deleteCategoriesResp.isRight()) {
			addToast({
				error: false,
				message: '',
			});
			getCategories();
		} else {
			addDialog({
				error: true,
				title: '',
				message: '',
			});
		}
	};

	const getCategoryById = async (id: string) => {
		const getCategoriesUseCase = new GetCategoryById(categoryRepository);
		const categoriesResp = await getCategoriesUseCase.call(id);
		if (categoriesResp.isRight()) {
			setFormValues({
				name: categoriesResp.value.name,
				isVisible: categoriesResp.value.isVisible,
				subtitle: categoriesResp.value.subtitle || '',
				description: categoriesResp.value.description || '',
				file: categoriesResp.value.attachment.url,
			});
			setAttachmentId(categoriesResp.value.attachment.id);
		} else {
			addDialog({
				error: true,
				title: '',
				message: '',
			});
		}
	};

	const onChange = (key: string, value: string | boolean | File) => {
		setFormValues({ ...formValues, [key]: value });
		setFormErrors({ ...formErrors, [key]: '' });
	};

	const submit = async (id?: string) => {
		const errors = {
			name: '',
			file: '',
			subtitle: '',
			description: '',
		};
		if (!formValues.name) {
			errors.name = t('required');
		}
		if (!formValues.subtitle) {
			errors.subtitle = t('required');
		}
		if (!formValues.description) {
			errors.description = t('required');
		}
		if (!formValues.file) {
			errors.file = t('required');
		}
		setFormErrors({ ...formErrors, ...errors });
		if (errors.name || errors.file || errors.subtitle || errors.description) {
			return;
		}
		setLoading(true);
		if (id) {
			const updateCategoryUsecase = new UpdateCategory(categoryRepository);
			const updateCategoryResult = await updateCategoryUsecase.call(id, attachmentId, formValues);
			if (updateCategoryResult.isRight()) {
				addToast({
					error: false,
					message: t('success'),
				});
				setLoading(false);
			} else {
				addDialog({
					error: true,
					title: 'error',
					message: '',
				});
				setLoading(false);
			}
		} else {
			const addCategoryUseCase = new AddCategory(categoryRepository);
			const addCategoryResult = await addCategoryUseCase.call(formValues);
			if (addCategoryResult.isRight()) {
				navigate(AppLinks.BACKOFFICE_CATEGORIES);
				setLoading(false);
			} else {
				addDialog({
					error: true,
					title: 'error',
					message: '',
				});
				setLoading(false);
			}
		}
	};

	const value = useMemo(() => ({
		formValues,
		formErrors,
		onChange,
		submit,
		loading,
		categories,
		getCategories,
		getCategoryById,
		deleteCategoryById,
		setFormValues,
		categoriesFetchState,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [
		formValues,
		formErrors,
		categories,
		loading,
		categoriesFetchState,
	]);

	return (
		<ProductCategoriesContext.Provider
			value={value}
		>
			{children}
		</ProductCategoriesContext.Provider>
	);
};

export function useCategories() {
	return useContext(ProductCategoriesContext);
}
