import { Either } from '@ajtec/either-typescript';
import { Comment } from '../entities/comment';
import { ICommunityRepository } from '../repositories/community-repository';

export class GetComments {
	repository: ICommunityRepository;

	constructor(communityRepository: ICommunityRepository) {
		this.repository = communityRepository;
	}

	call(): Promise<Either<string, Comment[]>> {
		return this.repository.getComments();
	}
}
