import { Either } from '@ajtec/either-typescript';
import { ITariffDataset } from '../entities/tariff-dataset';
import { ITariffRepository } from '../repository/tariff-repository';

export class GetTariffDataSet {
	repository: ITariffRepository;

	constructor(tariffRepository: ITariffRepository) {
		this.repository = tariffRepository;
	}

	call(userProductId: number): Promise<Either<string, ITariffDataset>> {
		return this.repository.getTariffDataSet(userProductId);
	}
}
