import React, {
	FC, createContext, useContext, useMemo, useState,
} from 'react';
import { ICategories } from '../../domain/entities/categories';
import { LandingRepositoryImpl } from '../../data/repository/landing-repository-impl';
import { GetCategories } from '../../domain/usecases/GetCategories';
import { GetCategoryById } from '../../domain/usecases/get-category-by-id';
import { ErrorResponse } from '@/common/domain/entities/error-response';

type TProps = {
	loading: boolean
	categories: ICategories[]
	categoryById: ICategories | null
	categoryByIdError: ErrorResponse | null
	getCategoryById: (id: string) => void
	getCategories: () => void
}

const LandingContext = createContext({} as TProps);

export const LandingProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [categories, setCategories] = useState([] as ICategories[]);
	const [categoryById, setCategoryById] = useState<ICategories | null>(null);
	const [categoryByIdError, setCategoryByIdError] = useState<ErrorResponse | null>(null);
	const [loading, setLoading] = useState(false);

	const landingRepository = new LandingRepositoryImpl();

	const getCategories = async () => {
		setLoading(true);
		const landingUsecase = new GetCategories(landingRepository);
		const landingResult = await landingUsecase.call(true);
		if (landingResult.isRight()) {
			setCategories(landingResult.value);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const getCategoryById = async (id: string) => {
		setLoading(true);
		setCategoryByIdError(null);
		const categoryByIdUsecase = new GetCategoryById(landingRepository);
		const categoryByIdResult = await categoryByIdUsecase.call(id);
		if (categoryByIdResult.isRight()) {
			setCategoryById(categoryByIdResult.value);
		} else {
			setCategoryByIdError(categoryByIdResult.error);
			setLoading(false);
		}
	};

	const value = useMemo(() => ({
		categories,
		loading,
		categoryById,
		categoryByIdError,
		getCategories,
		getCategoryById,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [categories, loading, categoryByIdError, categoryById]);

	return (
		<LandingContext.Provider value={value}>{children}</LandingContext.Provider>
	);
};

export function useLanding() {
	return useContext(LandingContext);
}
