import { Either } from '@ajtec/either-typescript';
import { ICategoryRepository } from '../repository/categories-repository';
import { AxiosError } from 'axios';
import { IProductCategory } from '../entities/product-categories';

export class GetCategories {
	repository: ICategoryRepository;

	constructor(categoryRepository: ICategoryRepository) {
		this.repository = categoryRepository;
	}

	call(): Promise<Either<AxiosError, IProductCategory[]>> {
		return this.repository.getCategories();
	}
}
