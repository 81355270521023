import Dimens from 'assets/dimens';
import {
	Box, Checkbox, Divider, FormControlLabel, FormGroup, FormHelperText, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import ProductSummaryCard from '../components/ProductSummaryCard';
import useCart from 'common/presentation/providers/cart.provider';
import {
	BodyText, DefaultButton, DefaultInput, SizedBox, TitleText,
} from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import CartSummeryItem from '../components/CartSummeryItem';
import useOrder from '../provider/order-provider';

const FinalizeForm = () => {
	const { cartProducts, getTotalPrice } = useCart();
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	const { loading, createOrder, error } = useOrder();

	const [formData, setFormData] = React.useState({
		fullName: '',
		email: '',
		phone: '',
		privacyPolicy: false,
	});

	const [formError, setFormError] = React.useState({
		fullName: '',
		email: '',
		phone: '',
		privacyPolicy: '',
	});

	const handleFinalizeClick = () => {
		createOrder({
			fullName: formData.fullName,
			email: formData.email,
			phone: formData.phone,
			items: cartProducts.map((product) => ({
				productId: product.productId,
				productName: product.name,
				amount: product.qty,
				unitPrice: product.price,
				warrantyMonths: 12,
				quantity: product.qty,
			})),
		});
	};

	const handleEditShoppingCartClick = () => {
		navigate(AppLinks.CHECKOUT_PAGE);
	};

	const renderProducts = () => (
		<>
			{cartProducts.map((product) => (
				<CartSummeryItem title={product.name} image={product.image} />))}
		</>
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[id]: value,
		}));
	};

	const handleFormSubmit = (): null => {
		const mError = {
			fullName: '',
			email: '',
			phone: '',
			privacyPolicy: '',
		};
		if (!formData.fullName) {
			mError.fullName = t('please_enter_your_name');
		}
		if (!formData.email) {
			mError.email = t('please_enter_your_email');
		}
		if (!formData.phone) {
			mError.phone = t('please_enter_your_phone');
		}
		if (!formData.privacyPolicy) {
			mError.privacyPolicy = t('please_acknowledge_privacy_policy');
		}
		setFormError(mError);
		if (mError.fullName || mError.email || mError.phone || mError.privacyPolicy) {
			return null;
		}
		setFormError({
			fullName: '',
			email: '',
			phone: '',
			privacyPolicy: '',
		});
		if (cartProducts.length === 0) { return null; }
		handleFinalizeClick();
		return null;
	};

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
		}}
		>
			<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between">
				<Box width={{ xs: Dimens.CHECKOUT_CARD_WIDTH, md: Dimens.MD_CHECKOUT_CARD_AREA }}>
					<TitleText
						sx={{
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
							fontWeight: Dimens.FONT_WEIGHT_NORMAL,
						}}
						color={theme.palette.textMain.main}
					>
						{t('finalize_order').toUpperCase()}
					</TitleText>
					<BodyText sx={{
						fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE },
						fontWeight: Dimens.FONT_WEIGHT_LIGHT,
						mt: 1,
						fontStyle: 'italic',
					}}
					>
						{t('insert_your_data').toUpperCase()}
					</BodyText>
					<Box sx={{ mt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE } }}>
						<DefaultInput
							id="fullName"
							value={formData.fullName}
							error={!!formError.fullName}
							helperText={formError.fullName}
							onChange={handleChange}
							label={t('what_is_your_name')}
							placeholder={t('your_full_name')}
						/>
						<SizedBox height={3} />
						<DefaultInput
							id="email"
							value={formData.email}
							error={!!formError.email}
							helperText={formError.email}
							onChange={handleChange}
							label={t('what_is_your_email')}
							placeholder={t('your_email')}
						/>
						<SizedBox height={3} />
						<DefaultInput
							id="phone"
							value={formData.phone}
							error={!!formError.phone}
							helperText={formError.phone}
							onChange={handleChange}
							label={t('what_is_your_phone')}
							placeholder={t('your_phone')}
						/>
						<SizedBox height={1} />
						<BodyText sx={{ fontSize: { md: Dimens.BUTTON_TEXT, xs: Dimens.BUTTON_TEXT_MOBILE } }}>{t('phone_format_info')}</BodyText>
					</Box>
					<Divider sx={{
						mt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE },
						mb: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE },
					}}
					/>
					<BodyText sx={{
						fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE },
						fontWeight: Dimens.FONT_WEIGHT_LIGHT,
						mt: 1,
						fontStyle: 'italic',
					}}
					>
						{t('keep_me_informed').toUpperCase()}
					</BodyText>
					<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" sx={{ mt: 2 }}>
						<FormGroup>
							<FormControlLabel
								control={(
									<Checkbox
										value={formData.privacyPolicy}
										onChange={() => setFormData((prevState) => ({
											...prevState,
											privacyPolicy: !prevState.privacyPolicy,
										}))}
										icon={<RadioButtonUnchecked color="primary" />}
										checkedIcon={<RadioButtonChecked />}
									/>
								)}
								sx={{ color: theme.palette.textMain.main }}
								label={t('acknowledge_privacy_policy')}
							/>
							<FormHelperText error={!!formError.privacyPolicy}>
								{formError.privacyPolicy}
							</FormHelperText>
						</FormGroup>
						<DefaultButton
							sx={{
								mt: { md: 0, xs: 2 },
								mb: { md: 0, xs: 2 },
							}}
							loading={loading}
							onClick={handleFormSubmit}
						>
							{t('finalize_request')}
						</DefaultButton>
						<BodyText color="error">{error}</BodyText>
					</Stack>
				</Box>
				<Stack
					width={{ xs: '100%', md: '35%' }}
					justifyContent="flex-end"
					alignItems="flex-end"
				>
					{cartProducts.length > 0 && (
						<ProductSummaryCard
							renderStartComponent={renderProducts()}
							totalPrice={getTotalPrice()}
							buttonText={t('edit_shopping_cart')}
							onFinalizeClick={handleEditShoppingCartClick}
						/>
					)}
				</Stack>
			</Stack>
		</Box>
	);
};

export default FinalizeForm;
