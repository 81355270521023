/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import { ErrorResponseBase } from 'common/domain/entities/error-response';
import { Endpoints } from 'assets/Endpoints';
import { ApiResponse } from 'common/data/entities/api-response';
import { storeGet, storePost } from 'common/data/remote/store-requests';
import { IQuestion } from '../domain/question.model';
import { IFoundProduct } from '../domain/find-your-iokee';

export class FindYourIokeeApi {
	async getQuestions(): Promise<IQuestion[]> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_QUESTION,
			});
			const data = resp.data as ApiResponse<IQuestion[]>;
			return data.data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async findYourIokee(params: {
		answers: {
        answerId: number;
        devicesQuantity: number;
    }[]
	}): Promise<IFoundProduct[]> {
		try {
			const resp = await storePost({
				url: Endpoints.FIND_YOUR_IOKEE,
				data: { ...params },
			});
			const data = resp.data as ApiResponse<IFoundProduct[]>;
			return data.data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}
}
