import { AxiosError } from 'axios';
import { IProductCategory, IProductCategoryReq } from '../entities/product-categories';
import { ICategoryRepository } from '../repository/categories-repository';
import { Either } from '@ajtec/either-typescript';

export class UpdateCategory {
	repository: ICategoryRepository;

	constructor(categoryRepository: ICategoryRepository) {
		this.repository = categoryRepository;
	}

	call(id: string, attachmentId: number, params: IProductCategoryReq):
		Promise<Either<AxiosError, IProductCategory>> {
		return this.repository.updateCategoryById(id, attachmentId, params);
	}
}
