import React from 'react';
import HeroSection from './HeroSection';
import AboutIokee from './AboutIokee';
import UserStories from './UserStories';
import ProductSection from './ProductSection';
import CommunitySection from './CommunitySection';
import GreenManifesto from './GreenManifesto';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import { CommunityForm } from 'common/presentation/components';
import { LandingProvider } from './provider/landing-provider';

const LandingPage = () => (
	<main className="container">
		<LandingProvider>
			<CommunityProvier>
				<HeroSection />
				<AboutIokee />
				<UserStories />
				<ProductSection />
				<GreenManifesto />
				<CommunitySection />
				<CommunityForm />
			</CommunityProvier>
		</LandingProvider>
	</main>
);

export default LandingPage;
