import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';

interface DetailItemProps {
  label: string;
  tag: string;
  consumption: number;
  cost: number;
  date: string;
  time: string;
}

const DetailItem: React.FC<DetailItemProps> = ({
	label, tag, date, time, consumption, cost,
}) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: Dimens.CLIENT_AREA_USER_CARD_PADDING,
				backgroundColor: theme.palette.background.paper,
				borderRadius: Dimens.CLIENT_AREA_PAGE_BORDER_RADIUS_MOBILE,
				mb: 1,
				boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
			}}
		>
			<BodyText sx={{ fontWeight: Dimens.FONT_WEIGHT_BOLD, color: '#000030' }}>
				{label}
			</BodyText>
			<Box
				sx={{
					backgroundColor: '#FFFFE0',
					borderRadius: '8px',
					padding: '2px 8px',
					marginRight: '8px',
					fontSize: '12px',
					fontWeight: 'bold',
				}}
			>
				{tag}
			</Box>
			<BodyText sx={{ color: '#7F7F96', fontSize: '12px', marginRight: '16px' }}>
				{consumption / 1000}
				{' '}
				kWh
			</BodyText>
			<BodyText sx={{ color: '#7F7F96', fontSize: '12px', marginRight: '16px' }}>
				{cost}
				{' '}
				€
			</BodyText>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<BodyText sx={{ color: '#7F7F96', fontSize: '12px', marginRight: '16px' }}>
					{date}
				</BodyText>
				<BodyText sx={{ color: '#7F7F96', fontSize: '12px' }}>
					{time}
				</BodyText>
			</Box>
		</Box>
	);
};

export default DetailItem;
