import React, { JSXElementConstructor } from 'react';
import { StoreProductProvider } from './provider/product-provider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withProductContext = (WrappedComponent: JSXElementConstructor<any>) => (props: any) => (
	<StoreProductProvider>
		<WrappedComponent {...props} />
	</StoreProductProvider>
);

export default withProductContext;
