/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	FC, createContext, useContext, useMemo, useState,
} from 'react';
import { productCategories } from 'utils/product.utils';
import moment from 'moment';
import { IDevice } from '../../entities/device';
import { IReportDate } from '../../entities/report';
import { DeviceApiImpl } from '../../data/device-api-impl';

interface DeviceContextProps {
   loading: boolean;
   getUserProducts: () => void;
   error: string,
   hub?: IDevice[];
   wallBox?: IDevice[];
   analyzer?: IDevice[];
   handleClickShowReport: (ids: number[], dates: IReportDate) => Promise<Blob>
   reportLoading: boolean
}

const DeviceContext = createContext({} as DeviceContextProps);

export const DeviceProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [hub, setHub] = useState<IDevice[]>([] as IDevice[]);
	const [wallBox, setWallBox] = useState<IDevice[]>([] as IDevice[]);
	const [analyzer, setAnalyzer] = useState<IDevice[]>([] as IDevice[]);
	const [reportLoading, setReportLoading] = useState(false);

	const deviceApi = new DeviceApiImpl();

	const getUserProducts = async () => {
		setLoading(true);
		try {
			const deviceResult = await deviceApi.getUserProducts();
			setHub(deviceResult.filter(
				(device) => device.product.code === productCategories.HUB,

			));
			setWallBox(deviceResult.filter(
				(device) => (device.product.code === productCategories.WALLBOX_MONOPHASE
				|| device.product.code === productCategories.WALLBOX_THREE_PHASE),
			));
			setAnalyzer(deviceResult.filter(
				(device) => (device.product.code === productCategories.ANALYZER_MONOPHASE
					|| device.product.code === productCategories.ANALYZER_THREE_PASE
					|| device.product.code === productCategories.ANALYZER_MONOPHASE_V2
					|| device.product.code === productCategories.ANALYZER_THREE_PASE_V2),
			));
		} catch (mError: any) {
			setError(mError?.response?.data?.title);
		} finally {
			setLoading(false);
		}
	};

	const handleClickShowReport = async (ids: number[], dates: IReportDate) => {
		setReportLoading(true);
		try {
			const requestParams = {
				userProdIds: ids,
				begin: {
					year: parseInt(moment(dates.startDate).format('YYYY'), 10),
					month: parseInt(moment(dates.startDate).format('MM'), 10),
					day: parseInt(moment(dates.startDate).format('DD'), 10),
				},
				end: {
					year: parseInt(moment(dates.endDate).format('YYYY'), 10),
					month: parseInt(moment(dates.endDate).format('MM'), 10),
					day: parseInt(moment(dates.endDate).format('DD'), 10),
				},
			};
			const deviceResult = await deviceApi.exportWallboxExcel(requestParams);
			return deviceResult;
		// eslint-disable-next-line no-useless-catch
		} catch (mError: any) {
			throw mError;
		} finally {
			setLoading(false);
		}
	};

	const value = useMemo(() => ({
		loading,
		getUserProducts,
		error,
		hub,
		wallBox,
		analyzer,
		handleClickShowReport,
		reportLoading,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [
		loading,
		hub,
		wallBox,
		analyzer,
		reportLoading,
		error]);
	return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
};

export default function useDevices() {
	return useContext(DeviceContext);
}
