import Dimens from 'assets/dimens';
import {
	CounterButton,
	DefaultButton,
	TitleText,
} from 'common/presentation/components';
import {
	Box,
	Stack,
	SvgIcon,
	// useMediaQuery,
	// useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { HouseDivisionIcon } from 'assets/find-your-iokee-icons';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import { IQuestion } from '../../domain/question.model';

interface Props {
	onBackClick: () => void
	onContinueClick: () => void
	question?: IQuestion
}

const DivisionSection: React.FC<Props> = (props) => {
	const { onBackClick, onContinueClick, question } = props;
	const { t } = useTranslation('translations');
	// const theme = useTheme();
	// const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const { updateDevicesQuantity } = useFindYourIokee();
	const [quantity, setQuantity] = useState(1);

	const handleHouseDivisionsChange = (newVal: number) => {
		if (newVal > 0) {
			setQuantity((prev) => prev + 1);
		} else if (newVal < 0) {
			if (quantity <= 1) {
				setQuantity(1);
			} else {
				setQuantity((prev) => prev - 1);
			}
		}
	};

	const handleContinue = () => {
		if (question) {
			updateDevicesQuantity(question?.id, question?.answers[0].id, quantity);
			onContinueClick();
		}
	};

	// const infoText = (
	// 	<BodyText
	// 		textAlign={{ md: 'end', xs: 'start', sm: 'start' }}
	// 		sx={{
	// 			fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_S },
	// 		}}
	// 	>
	// 		{t('house_divisions_info')}
	// 	</BodyText>
	// );

	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 4 }}>
			<Box>
				<TitleText
					sx={{
						fontStyle: 'italic',
						fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
					}}
				>
					{question?.description}
				</TitleText>
				{/* {isMd && infoText} */}
			</Box>
			<Stack alignItems="center" spacing={{ md: 6, xs: 2, sm: 2 }}>
				<SvgIcon sx={{ fontSize: { md: 100, xs: 80, sm: 80 } }} color="inherit" component="div" inheritViewBox>
					<HouseDivisionIcon />
				</SvgIcon>
				<CounterButton
					increment={() => handleHouseDivisionsChange(1)}
					decrement={() => handleHouseDivisionsChange(-1)}
					count={quantity}
				/>
			</Stack>
			{/* {!isMd && infoText} */}
			<Stack direction="row" spacing={4}>
				<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={onBackClick}>
					{t('back')}
				</DefaultButton>
				<DefaultButton variant="contained" endIcon={<ArrowForward />} onClick={handleContinue}>
					{t('continue')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default DivisionSection;
