import React from 'react';
import { CommunityForm } from 'common/presentation/components';
import CommunityTitle from './CommunityTitle';
import { CommunityProvier } from '../provider/community.provider';
import CommentList from './CommentList';

const CommunityPage = () => (
	<main>
		<CommunityProvier>
			<CommunityTitle />
			<CommentList />
			<CommunityForm />
		</CommunityProvier>
	</main>
);

export default CommunityPage;
