import React from 'react';
import {
	Box,
	useTheme,
	Link,
	Stack,
} from '@mui/material';
import { TitleText } from 'common/presentation/components'; // Assuming TitleText is exported from here
import Dimens from 'assets/dimens';
import { signupimage } from 'assets/images';
import { Link as RouterLink } from 'react-router-dom';
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx';

interface Props {
	titleText: string | React.ReactNode,
	titleLink: string;
	subtitleText?: string | React.ReactNode
	subtitleLink?: string
	titleFontSize?: ResponsiveStyleValue<number | string>
	width?: ResponsiveStyleValue<number | string>
	height?: ResponsiveStyleValue<number | string>
}

const AuthenticationIllistration: React.FC<Props> = (props) => {
	const {
		titleText,
		titleLink,
		subtitleText,
		subtitleLink,
		titleFontSize,
		width,
		height,
	} = props;
	const theme = useTheme();

	const getTitle = (text: string | React.ReactNode) => {
		if (typeof text === 'string') {
			return (
				<TitleText
					sx={{
						fontSize: titleFontSize ?? Dimens.TITLE_TEXT_XL_MOBILE,
						fontWeight: Dimens.FONT_WEIGHT_BOLDER,
						color: theme.palette.primary.main,
					}}
				>
					{text}
				</TitleText>
			);
		}
		return text;
	};

	return (

		<Stack
			alignItems="center"
			justifyContent="center"
			sx={{
				width: width ?? Dimens.REGISTRATION_SIDE_ILLUSTRATION_HEIGHT,
				backgroundImage: `url(${signupimage})`,
				backgroundSize: 'contain',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				height: height ?? Dimens.REGISTRATION_SIDE_ILLUSTRATION_HEIGHT,
			}}
		>
			<Box
				sx={{
					alignItems: 'center',
					textAlign: 'center',
					width: '50%',
				}}
			>
				<Link component={RouterLink} to={titleLink} sx={{ textDecoration: 'none' }}>
					{getTitle(titleText)}
				</Link>
				{subtitleText && (
					<Link component={RouterLink} to={subtitleLink ?? ''} sx={{ textDecoration: 'none' }}>
						{getTitle(subtitleText)}
					</Link>
				)}
			</Box>
		</Stack>

	);
};

export default AuthenticationIllistration;
