import React from 'react';
import { Divider, Stack, useTheme } from '@mui/material';
import { BodyText, DefaultButton } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

interface Props {
	totalPrice: number
	onFinalizeClick?: () => void
	renderStartComponent?: React.ReactNode
	buttonText: string
}

const ProductSummaryCard: React.FC<Props> = (props) => {
	const {
		totalPrice, onFinalizeClick, renderStartComponent, buttonText,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const handleFinalizeClick = () => {
		onFinalizeClick?.call(0);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				border: 1.5,
				borderTopLeftRadius: Dimens.CARD_BORDER_RADIUS,
				borderBottomRightRadius: Dimens.CARD_BORDER_RADIUS,
				borderColor: theme.palette.primary.main,
				p: {
					xs: Dimens.XS_SMALL_PADDING_PAIR,
					sm: Dimens.SM_SMALL_PADDING_PAIR,
					md: Dimens.MD_MEDIUM_PADDING_PAIR,
				},
				flexGrow: 1,
				width: Dimens.CHECKOUT_CARD_WIDTH,
				maxWidth: Dimens.COMMUNITY_IMAGE_HEIGHT,
			}}
		>

			<Box>
				<BodyText sx={{
					fontSize: {
						xs: Dimens.SUBTITLE_TEXT_MOBILE,
						sm: Dimens.BODY_TEXT_M,
						md: Dimens.MD_SUMMARY_CARD_TITLE,
					},
					fontWeight: Dimens.FONT_WEIGHT_LIGHTER,
					mb: Dimens.MARGIN_BOTTOM_CARD_TITLE,
				}}
				>
					{t('summary_of_your_purchase')}
				</BodyText>
			</Box>
			{renderStartComponent}
			<Stack direction="row" spacing={2} alignItems="center">
				<BodyText sx={{
					fontSize: Dimens.BODY_TEXT_M,
					fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				}}
				>
					{t('subtotal')}
				</BodyText>
				<Box flexGrow={1} />
				<BodyText pt={2} sx={{ fontSize: Dimens.BODY_TEXT_ML }}>
					{totalPrice.toFixed(2)}
					{' €'}
				</BodyText>

			</Stack>
			<Stack direction="row" spacing={2} alignItems="center">
				<BodyText sx={{
					fontSize: Dimens.BODY_TEXT_M,
					fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				}}
				>
					{t('delivery')}
				</BodyText>
				<Box flexGrow={1} />
				<BodyText pt={2} sx={{ fontSize: Dimens.BODY_TEXT_ML }}>
					{t('free')}
				</BodyText>

			</Stack>
			<Divider sx={{ mt: Dimens.CLIENT_BUTTON_VERTICAL, mb: Dimens.CLIENT_BUTTON_VERTICAL }} />
			<Stack direction="row" spacing={2} alignItems="center">
				<BodyText sx={{
					fontSize: Dimens.BODY_TEXT_L,
					fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				}}
				>
					{t('total')}
				</BodyText>
				<Box flexGrow={1} />
				<BodyText
					pt={2}
					sx={{
						fontSize: Dimens.BODY_TEXT_L,
						fontWeight: Dimens.FONT_WEIGHT_LIGHT,
					}}
				>
					{totalPrice.toFixed(2)}
					{' €'}
				</BodyText>

			</Stack>

			<Stack alignItems="center" sx={{ pt: { md: Dimens.CARD_PADDING_LARGE, xs: Dimens.CARD_PADDING_LARGE_MOBILE } }}>
				<DefaultButton variant="outlined" onClick={handleFinalizeClick}>
					{buttonText}
				</DefaultButton>
			</Stack>
		</Box>

	);
};

export default ProductSummaryCard;
