/* eslint-disable react/no-unescaped-entities */
import Dimens from 'assets/dimens';
import {
	Box, Checkbox, Divider, FormControlLabel, FormGroup, FormHelperText, Grid, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import {
	AlertDialog,
	BodyText, DefaultButton, DefaultInput, TitleText,
} from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import ChoiceLabel from './component/ChoiceLabel';
import useContact from './provider/contact-provider';
import { validateEmail } from 'utils/validation';
import { phone } from 'utils/phone-mask.utils';

enum ContactChoice {
	DUMMY_INDEX,
	TO_BECAME_A_PARTNER,
	TO_BECAME_A_BETA_TESTER,
}

const ContactForm = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		createContact,
		formData,
		formError,
		setFormData,
		setFormError,
		loading,
	} = useContact();

	const [successDialog, setSuccessDialog] = React.useState(false);

	const [selectedChoice, setSelectedChoice] = React.useState<number>(
		ContactChoice.TO_BECAME_A_BETA_TESTER,
	);

	const handleChoiceClick = (index: number) => {
		setSelectedChoice(index);
		setFormData({
			...formData,
			message: index === ContactChoice.TO_BECAME_A_BETA_TESTER
				? t('default_contact_message_beta_tester')
				: t('default_contact_message'),
		});
	};

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const { id, value } = event.target;
		setFormData({
			...formData,
			[id]: value,
		});
	};

	const handleFormSubmit = (): null => {
		const mError = {
			name: '',
			email: '',
			message: '',
			phone: '',
			privacyPolicy: '',
		};
		if (!formData.name) {
			mError.name = t('please_enter_your_name');
		}
		if (!formData.email) {
			mError.email = t('please_enter_your_email');
		}
		if (formData.email && !validateEmail(formData.email)) {
			mError.email = t('email_error');
		}
		if (!formData.phone) {
			mError.phone = t('please_enter_your_phone');
		}
		if (formData.phone && formData.phone.length < 11) {
			mError.phone = t('phone_error');
		}
		if (!formData.message) {
			mError.message = t('please_enter_your_message');
		}
		if (!formData.privacyPolicy) {
			mError.privacyPolicy = t('please_acknowledge_privacy_policy');
		}
		setFormError(mError);
		if (mError.name || mError.email || mError.message || mError.privacyPolicy) {
			return null;
		}
		setFormError({
			name: '',
			email: '',
			message: '',
			phone: '',
			privacyPolicy: '',
		});
		createContact({
			name: formData.name,
			email: formData.email,
			message: formData.message,
			phone: formData.phone,
			cooperateType: selectedChoice,
		});
		return null;
	};

	const handleDialogClose = () => {
		setSuccessDialog(false);
	};

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
			mb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
		}}
		>
			<Box sx={{
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.TITLE_BODY_SPACING_MOBILE },
				ml: { md: Dimens.PAGE_PADDING_LARGE, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: Dimens.PAGE_PADDING_LARGE, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
			>
				<TitleText sx={{
					fontSize: {
						md: Dimens.TITEL_TEXT_M,
						xs: Dimens.TITLE_TEXT_M_MOBILE,
					},
					fontWeight: Dimens.FONT_WEIGHT_NORMAL,
				}}
				>
					{t('welcome_to_iokee').toUpperCase()}
				</TitleText>
				<BodyText sx={{
					fontSize: {
						md: Dimens.BODY_TEXT_XL,
						xs: Dimens.BODY_TEXT_XL_MOBILE,
					},
				}}
				>
					{t('welcome_to_iokee_info').toUpperCase()}
				</BodyText>
				<BodyText
					sx={{
						mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
					}}
				>
					{t('looking_for')}
				</BodyText>
				<Stack direction={{ md: 'row', xs: 'column' }} spacing={2} sx={{ mt: 2 }}>
					<ChoiceLabel title={t('to_become_beta_tester')} onClick={() => handleChoiceClick(ContactChoice.TO_BECAME_A_BETA_TESTER)} />
				</Stack>
				<Grid
					container
					spacing={2}
					sx={{ mt: { md: Dimens.PADDING_TOP_MISSION, xs: Dimens.PADDING_TOP_MISSION } }}
				>
					<Grid item xs={12} md={6}>
						<DefaultInput
							id="name"
							label={t('what_is_your_name')}
							placeholder={t('your_full_name')}
							value={formData.name}
							error={!!formError.name}
							helperText={formError.name}
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<DefaultInput
							id="email"
							label={t('what_is_your_email')}
							placeholder={t('your_email')}
							value={formData.email}
							error={!!formError.email}
							helperText={formError.email}
							onChange={handleChange}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					sx={{ mt: { md: Dimens.PADDING_TOP_MISSION, xs: Dimens.PADDING_TOP_MISSION } }}
				>
					<Grid item xs={12} md={6}>
						<DefaultInput
							id="phone"
							label={t('what_is_your_phone')}
							placeholder={t('your_phone')}
							value={formData.phone}
							error={!!formError.phone}
							helperText={formError.phone}
							onChange={(e) => handleChange(phone(e))}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					sx={{ mt: { md: Dimens.PADDING_TOP_MISSION, xs: Dimens.PADDING_TOP_MISSION } }}
				>
					<Grid item xs={12} md={12}>
						<DefaultInput
							id="message"
							label={t('please_write_your_message')}
							placeholder={t('your_message')}
							value={formData.message}
							error={!!formError.message}
							helperText={formError.message}
							onChange={handleChange}
						/>
					</Grid>
				</Grid>
				<Divider sx={{ mt: Dimens.PADDING_TOP_MISSION, mb: Dimens.PADDING_TOP_MISSION }} />
				<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" sx={{ mt: 2 }}>
					<FormGroup>
						<FormControlLabel
							id="privacyPolicy"
							control={(
								<Checkbox
									value={formData.privacyPolicy}
									onChange={() => setFormData({
										...formData,
										privacyPolicy: !formData.privacyPolicy,
									})}
									icon={<RadioButtonUnchecked color="primary" />}
									checkedIcon={<RadioButtonChecked />}
								/>
							)}
							sx={{ color: theme.palette.textMain.main }}
							label={t('acknowledge_privacy_policy')}
						/>
						<FormHelperText
							error={!!formError.privacyPolicy}
						>
							{formError.privacyPolicy}
						</FormHelperText>
					</FormGroup>
					<DefaultButton loading={loading} onClick={handleFormSubmit}>{t('submit_form')}</DefaultButton>
				</Stack>
			</Box>
			<AlertDialog open={successDialog} handleClose={handleDialogClose} title={t('success')} description={t('request_submitted_successfully')} />
		</Box>
	);
};

export default ContactForm;
