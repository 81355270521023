/* eslint-disable import/no-extraneous-dependencies */
import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface Props {
	graphData: number[];
}

const CostChart: React.FC<Props> = (props) => {
	const { graphData } = props;
	const state = {
		series: [{
			data: graphData,
		}],
	};

	const options: ApexOptions = {
		chart: {
			type: 'bar',
			height: 430,
		},
		plotOptions: {
			bar: {
				barHeight: '99%',
				distributed: true,
				horizontal: true,
				dataLabels: {
					position: 'top',
				},
				borderRadius: 11.5,
			},
		},
		colors: ['#fff', '#4DFFFF', '#7F7F96', '#23233F',
		],
		dataLabels: {
			enabled: true,
			offsetX: -6,
			style: {
				fontSize: '12px',
				colors: ['#000', '#000', '#fff', '#fff'],
			},
			formatter(val) {
				return `${val} €`;
			},
		},
		legend: { show: false },
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'round',
			width: [1, 0, 0, 0],
			colors: ['#000', '#4DFFFF', '#7F7F96', '#23233F'],
			dashArray: [5, 0, 0, 0],
		},
		tooltip: {
			shared: true,
			intersect: false,
		},
		xaxis: {
			categories: ['Expectativa', 'Atual', 'Estimativa', 'Último ciclo'],
			labels: {
				formatter(val) {
					return `${parseFloat(val).toFixed(2)} €`;
				},
			},
		},
		grid: {
			show: true,
			borderColor: '#7f7f96',
			strokeDashArray: 0,
			position: 'back',
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
			row: {
				colors: undefined,
				opacity: 1,
			},
			column: {
				colors: undefined,
				opacity: 1,
			},
		},
	};

	return (
		<ReactApexChart options={options} series={state.series} type="bar" height={430} />
	);
};

export default CostChart;
