/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unescaped-entities */
import { BodyText, DefaultButton, TitleText } from 'common/presentation/components';
import {
	Alert,
	Box,
	Skeleton,
	Stack,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import ProductItem from '../components/ProductItem';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { ArrowBack } from '@mui/icons-material';
import { useStoreProducts } from '../provider/product-provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { useLanding } from 'features/landing/presentation/provider/landing-provider';

const CategoryDetailHero = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { getProducts, products, loading } = useStoreProducts();
	const { categoryById, categoryByIdError, getCategoryById } = useLanding();
	const { categoryId } = useParams();

	const handleGoBack = () => {
		navigate(AppLinks.STORE);
	};

	useEffectCustom(() => {
		if (categoryId) {
			getProducts(categoryId);
			getCategoryById(categoryId);
		}
	}, []);

	return (
		<Box
			sx={{
				pt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
		>
			<Box sx={{
				width: {
					lg: '35%', xl: '35%', md: '50%', xs: '100%', sm: '100%',
				},
			}}
			>
				{loading && (
					<>
						<Skeleton height={30} width="100%" />
						<Skeleton height={26} width="100%" />
						<Skeleton height={200} width="100%" />
					</>
				)}
				{categoryByIdError && (
					<Alert severity="error">
						{categoryByIdError.message}
					</Alert>
				)}
				<TitleText
					sx={{
						fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
				>
					{categoryById?.name?.toUpperCase()}
				</TitleText>
				{/* SHow category subtitle and description  */}
				<BodyText
					sx={{
						fontStyle: 'italic',
						fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE },
						mt: 1,
					}}
				>
					{categoryById?.subtitle?.toUpperCase()}
				</BodyText>
				<BodyText
					sx={{
						fontSize: { md: Dimens.BODY_TEXT_ML, xs: Dimens.BODY_TEXT_ML_MOBILE },
						mt: 1,
					}}
				>
					{categoryById?.description}
				</BodyText>
			</Box>
			<Stack
				direction={{ md: 'row', xs: 'column', sm: 'column' }}
				sx={{ mt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE } }}
				spacing={2}
				flexWrap="wrap"
				useFlexGap
			>
				{!loading && products.length === 0 && (
					<Alert severity="warning">
						{t('no_products')}
					</Alert>
				)}
				{loading && [1, 2].map((el) => (
					<Skeleton key={el} height={600} width="27%" />
				))}
				{products.length !== 0 && products.map((el) => (
					<ProductItem
						// @ts-ignore
						productImage={el.attachment?.attachment?.url}
						title={el.name}
						price={el.price}
						handleSeeMoreClick={() => navigate(AppLinks.PRODUCT.formatMap({ productId: el.id }))}
					/>
				))}
			</Stack>
			<Stack
				alignItems="center"
				sx={{ mt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE } }}
			>
				<DefaultButton
					onClick={handleGoBack}
					endIcon={null}
					startIcon={<ArrowBack />}
				>
					{t('go_back_to_store')}
				</DefaultButton>
			</Stack>
		</Box>
	);
};

export default CategoryDetailHero;
