import {
	Box, Stack, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import Dimens from 'assets/dimens';
import {
	BodyText, DefaultButton, DefaultInput,
} from 'common/presentation/components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { validateEmail, validatePassword } from 'utils/validation';
import AuthenticationIllistration from '../registration/components/AuthenticationIllistration';
import { ILogin } from '../../domain/entities/login';
import AppLinks from 'assets/applinks.routes';

const LoginPage = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const [formData, setFormData] = useState<ILogin>({
		email: '',
		password: '',
	});

	const [errors, setErrors] = useState<ILogin>({
		email: '',
		password: '',

	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
		// Optionally clear errors on change
		setErrors({ ...errors, [name]: '' });
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const newErrors = { ...errors };
		let isValid = true;

		// Validate each required field
		if (!formData.email) {
			isValid = false;
			newErrors.email = t('please_enter_your_email');
		} else if (!validateEmail(formData.email)) {
			isValid = false;
			newErrors.email = t('email_error');
		}
		if (!validatePassword(formData.password)) {
			isValid = false;
			newErrors.password = t('password_error');
		}

		setErrors(newErrors);

		if (isValid) {
			console.log('Submitting form', formData);
			// Here you would handle the form submission, e.g., sending data to an API
		}
	};
	return (
		<Box sx={{
			ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			flexDirection: { md: 'row', sm: 'column' },
			justifyContent: 'space-around',
			alignItems: 'center',
			mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
		}}
		>
			<Stack direction={{ md: 'row', xs: 'column' }} spacing={2} alignItems="center" justifyContent="center">
				<Stack
					alignItems="center"
					sx={{
						width: { md: '50%', xs: '100%' },
					}}
				>
					<AuthenticationIllistration
						height={{ md: Dimens.REGISTRATION_SIDE_ILLUSTRATION_HEIGHT, xs: '45vh' }}
						width={{ md: Dimens.REGISTRATION_SIDE_ILLUSTRATION_HEIGHT, xs: '100%' }}
						titleText={t('login').toUpperCase()}
						titleLink={AppLinks.STORE_AREA_LOGIN}
					/>
				</Stack>
				<Box sx={{ width: { md: '50%', xs: '100%' } }}>
					<Stack spacing={2}>

						<DefaultInput
							label={t('E-mail')}
							placeholder={t('your_email')}
							name="email"
							value={formData.email}
							onChange={handleChange}
							error={!!errors.email}
							helperText={errors.email}
						/>
						<DefaultInput
							label={t('password')}
							placeholder={t('your_password')}
							name="password"
							type="password"
							value={formData.password}
							onChange={handleChange}
							error={!!errors.password}
							helperText={errors.password}
						/>

					</Stack>
					<Box
						component="form"
						onSubmit={handleSubmit}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							pt: 5,
						}}
					>
						<DefaultButton type="submit">
							{t('login')}
						</DefaultButton>
						<BodyText sx={{ mt: 3 }}>
							{t('dont_have_an_account')}
							<span style={{ paddingLeft: Dimens.REGISTRATION_PAGE_SIZE }}>
								<Link
									to={AppLinks.STORE_AREA_REGISTRATION}
									style={{
										textDecoration: 'none',
										color: theme.palette.primary.main,
										fontWeight: Dimens.FONT_WEIGHT_BOLDER,
									}}
								>
									{t('create_account')}
								</Link>
							</span>
						</BodyText>

					</Box>
				</Box>
			</Stack>
		</Box>
	);
};

export default LoginPage;
