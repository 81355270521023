import { ICommunityReq } from '../entities/community';
import { ICommunityRepository } from '../repositories/community-repository';

export class JoinCommunity {
	repository: ICommunityRepository;

	constructor(communityRepository: ICommunityRepository) {
		this.repository = communityRepository;
	}

	call(params: ICommunityReq) {
		return this.repository.joinCommunity(params);
	}
}
