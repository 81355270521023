import React, { useState } from 'react';
import CustomStepper from '../components/CustomStepper';
import { Box, Stack } from '@mui/material';
import PurposeSection from '../purpose/PurposeSection';
import Dimens from 'assets/dimens';
import PrivateSection from '../private/PrivateSection';
// import BusinessSection from '../business/BusinessSection';
import DivisionSection from '../divisions/DivisionSection';
import ElectricalSystem from '../electrical-system/ElectricalSystem';
import SolarPanelSection from '../solar-panels/SolarPanelSection';
import ElectricCarSection from '../electric_car/ElectricCarSection';
// import { useNavigate } from 'react-router-dom';
// import AppLinks from 'assets/applinks.routes';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

enum ViewSelector {
	PURPOSE = 0,
	PRIVATE_PURPOSE = 1,
	// BUSINESS_PURPOSE = 2,
	NUMBER_OF_DIVISIONS = 2,
	ELECTRICAL_SYSTEMS = 3,
	SOLAR_PANELS = 4,
	ELECTRICAL_CARS = 5,
	BEST_FIT_DEVICES = 6,
	// LET_US_CONTACT_YOU = 7,
}

const StepsView = () => {
	const [activeStep, setActiveStep] = useState(0);
	// const navigate = useNavigate();
	const {
		findYourIokeeData, getQuestions, questions, findMyIokee,
	} = useFindYourIokee();

	useEffectCustom(() => {
		getQuestions();
	}, []);

	return (
		<Stack
			sx={{
				minHeight: '80vh',
				pl: {
					md: Dimens.PAGE_PADDING,
					sm: Dimens.PAGE_PADDING_MOBILE,
					xs: Dimens.PAGE_PADDING_MOBILE,
				},
				pr: {
					md: Dimens.PAGE_PADDING,
					sm: Dimens.PAGE_PADDING_MOBILE,
					xs: Dimens.PAGE_PADDING_MOBILE,
				},
			}}
		>
			<Box
				sx={{
					width: '100%',
					mt: {
						md: Dimens.APP_BAR_MARGIN_BOTTOM,
						sm: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE,
						xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE,
					},
				}}
			>
				<CustomStepper activeStep={activeStep} />
			</Box>
			<Box sx={{ height: '100%', pt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE } }}>
				{
					activeStep === ViewSelector.PURPOSE
					&& <PurposeSection onContinueClick={() => setActiveStep((prev) => prev + 1)} question={questions && questions[0]} />
				}
				{
					activeStep === ViewSelector.PRIVATE_PURPOSE
					&& (
						<PrivateSection
							onBackClick={() => setActiveStep((prev) => prev - 1)}
							onContinueClick={() => {
								if (findYourIokeeData.purpose === 0) {
									setActiveStep((prev) => prev + 2);
								} else {
									setActiveStep((prev) => prev + 1);
								}
							}}
							question={questions && questions[1]}
						/>
					)
				}
				{/* {
					activeStep === ViewSelector.BUSINESS_PURPOSE
					&& (
						<BusinessSection
							onBackClick={() => setActiveStep((prev) => prev - 1)}
							onContinueClick={() => setActiveStep((prev) => prev + 1)}
						/>
					)
				} */}
				{
					activeStep === ViewSelector.NUMBER_OF_DIVISIONS
					&& (
						<DivisionSection
							onBackClick={() => setActiveStep((prev) => prev - 1)}
							onContinueClick={() => setActiveStep((prev) => prev + 1)}
							question={questions && questions[2]}
						/>
					)
				}
				{
					activeStep === ViewSelector.ELECTRICAL_SYSTEMS
					&& (
						<ElectricalSystem
							onBackClick={() => setActiveStep((prev) => prev - 1)}
							onContinueClick={() => setActiveStep((prev) => prev + 1)}
							question={questions && questions[3]}
						/>
					)
				}
				{
					activeStep === ViewSelector.SOLAR_PANELS
					&& (
						<SolarPanelSection
							onBackClick={() => setActiveStep((prev) => prev - 1)}
							onContinueClick={() => setActiveStep((prev) => prev + 1)}
							question={questions && questions[4]}
						/>
					)
				}
				{
					activeStep === ViewSelector.ELECTRICAL_CARS
					&& (
						<ElectricCarSection
							onBackClick={() => setActiveStep((prev) => prev - 1)}
							onContinueClick={() => findMyIokee()}
							question={questions && questions[5]}
						/>
					)
				}
			</Box>
		</Stack>
	);
};

export default StepsView;
