/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Box,
	IconButton,
	Modal,
	Stack,
	Theme,
	ThemeProvider,
	createTheme,
	Link,
	Button,
} from '@mui/material';
// import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDesignTokens } from 'utils/theme.utils';
import image from './intersect.svg';
import { Close } from '@mui/icons-material';
import BodyText from '../body-text/BodyText';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import Dimens from 'assets/dimens';
import StyledSwitch from '../styled-switch/StyledSwitch';

interface CookiesDialogProps {
	show: boolean;
	setShow: () => void
}

const CookiesDialog: React.FC<CookiesDialogProps> = (props) => {
	const { show, setShow } = props;
	const { t } = useTranslation('translations');

	const handleConsentClick = () => {
		LocalStorageWrapper.set(LocalStorageKeys.COOKIES, true);
		setShow();
	};

	const [theme, setTheme] = useState<Theme>();

	useEffect(() => {
		getDesignTokens('main').then((th) => {
			setTheme(createTheme(th));
		});
	}, []);

	if (!theme) return <div />;

	return (
		<ThemeProvider theme={theme}>
			<Modal
				open={show}
				onClose={setShow}
				disableAutoFocus
			>
				<Box
					sx={{
						backgroundImage: `url(${image})`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						alignSelf: 'center',
						backgroundSize: '100%',
						height: { xs: '43%', md: '688px' },
						width: { xs: '90%', md: '688px' },
						margin: 'auto',
						border: 'none',
					}}
				>
					<Stack alignItems="center" sx={{ pt: 2 }}>
						<IconButton onClick={setShow}>
							<Close />
						</IconButton>
					</Stack>
					<Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }} spacing={2}>
						<BodyText sx={{ width: { xs: '35%', md: '420px' }, textAlign: 'center' }}>
							{t('cookie_dialog_info')}
						</BodyText>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							sx={{
								border: `1px solid ${theme.palette.textMain.main}`,
								padding: '5px 12px 5px 12px',
								borderRadius: '10px',
								width: '360px',
							}}
						>
							<Box>
								<BodyText
									sx={{
										fontSize: Dimens.BODY_TEXT_S,
										fontWeight: Dimens.FONT_WEIGHT_BOLD,
										color: theme.palette.textMain.main,
									}}
								>
									{t('service_management')}
								</BodyText>
								<BodyText
									sx={{
										fontSize: Dimens.BUTTON_TEXT_MOBILE,
										fontWeight: Dimens.FONT_WEIGHT_LIGHT,
										color: theme.palette.textMain.main,
									}}
								>
									{t('audience_measurment')}
								</BodyText>
							</Box>
							<StyledSwitch />
						</Stack>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							sx={{
								border: `1px solid ${theme.palette.textMain.main}`,
								padding: '5px 12px 5px 12px',
								borderRadius: '10px',
								width: '360px',
							}}
						>
							<Box>
								<BodyText
									sx={{
										fontSize: Dimens.BODY_TEXT_S,
										fontWeight: Dimens.FONT_WEIGHT_BOLD,
										color: theme.palette.textMain.main,
									}}
								>
									{t('google_analytics')}
								</BodyText>
								<BodyText
									sx={{
										fontSize: Dimens.BUTTON_TEXT_MOBILE,
										fontWeight: Dimens.FONT_WEIGHT_LIGHT,
										color: theme.palette.textMain.main,
									}}
								>
									{t('this_service_not_install_cookie')}
								</BodyText>
							</Box>
							<StyledSwitch />
						</Stack>
						<Button
							variant="outlined"
							color="inherit"
							onClick={handleConsentClick}
							sx={{
								borderRadius: 50,
								borderWidth: '2px',
								width: '30%',
								fontWeight: Dimens.FONT_WEIGHT_BOLD,
								'&:hover': {
									bgcolor: theme.palette.textMain.main,
									color: theme.palette.background.default,
									borderColor: theme.palette.textMain.main,
								},
							}}
						>
							{t('confirm')}
						</Button>
						<Link>
							<BodyText
								sx={{
									fontSize: Dimens.BUTTON_TEXT_MOBILE,
									fontWeight: Dimens.FONT_WEIGHT_LIGHT,
									color: theme.palette.textMain.main,
									textDecoration: 'underline',
								}}
							>
								{t('cookie_policy')}
							</BodyText>
						</Link>
					</Stack>
				</Box>
			</Modal>
		</ThemeProvider>
	);
};

export default CookiesDialog;
