import { Either } from '@ajtec/either-typescript';
import { IProductBackofficeRepository } from '../repository/product.repository';
import { ProductBackoffice } from '../entities/backoffice-product';
import { ErrorResponse } from 'common/domain/entities/error-response';

export class DeleteProductById {
	repository: IProductBackofficeRepository;

	constructor(productRepository: IProductBackofficeRepository) {
		this.repository = productRepository;
	}

	call(id: string): Promise<Either<ErrorResponse, ProductBackoffice>> {
		return this.repository.deleteProductById(id);
	}
}
