import React from 'react';
import CategoryDetailHero from './CategoryDetailHero';
import { StoreProductProvider } from '../provider/product-provider';
import { LandingProvider } from 'features/landing/presentation/provider/landing-provider';

const CategoryDetailPage = () => (
	<main>
		<LandingProvider>
			<StoreProductProvider>
				<CategoryDetailHero />
			</StoreProductProvider>
		</LandingProvider>
	</main>
);

export default CategoryDetailPage;
