/* eslint-disable class-methods-use-this */
import { ApiResponse } from 'common/data/entities/api-response';
import { reqPost } from 'common/data/remote/request';
import { Endpoints } from 'assets/Endpoints';
import { DeviceDetailApi } from './device-detail-api';
import { EnergyConsumptionModel } from '../dto/energy-consumption-model';
import { EnergyConsumptionParams } from '../../domain/entities/energy-consuption';

export class DeviceDetailApiImpl implements DeviceDetailApi {
	async getEnergyConsumption(params: EnergyConsumptionParams): Promise<EnergyConsumptionModel> {
		try {
			const resp = await reqPost({
				url: Endpoints.ENERGY_CONSUMPTION,
				data: { ...params },
			});
			const data = resp.data as ApiResponse<EnergyConsumptionModel>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
