import { IEnergyConsumptionRepository } from '../repository/energy-consumption-repository';
import { EnergyConsumptionMonthParams, IEnergyConsumptionPeriod } from '../entities/energy-consumption-period';
import { Either } from '@ajtec/either-typescript';

export class GetEnergyConsumptionByMonth {
	repository: IEnergyConsumptionRepository;

	constructor(energyConsumptionRepository: IEnergyConsumptionRepository) {
		this.repository = energyConsumptionRepository;
	}

	call(params: EnergyConsumptionMonthParams): Promise<Either<string, IEnergyConsumptionPeriod[]>> {
		return this.repository.getEnergyConsumptionByMonth(params);
	}
}
