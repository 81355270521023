import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import { Stack, SvgIcon, useTheme } from '@mui/material';
import React from 'react';

interface Props {
	title: string;
	icon?: React.ReactNode;
	onClick: () => void;
	selected?: boolean;
}

const CheckboxCard: React.FC<Props> = (props) => {
	const {
		title,
		icon,
		onClick,
		selected,
	} = props;
	const theme = useTheme();
	return (
		<Stack
			onClick={onClick}
			spacing={{ md: 4, xs: 2, sm: 2 }}
			alignItems="center"
			sx={{
				bgcolor: selected ? theme.palette.primary.main : 'transparent',
				cursor: 'pointer',
				color: selected ? theme.palette.textMain.main : theme.palette.textMain.main,
				boxShadow: selected ? '3px 3px 6px 0px rgba(77, 255, 255, 0.6)' : 'none',
				pt: Dimens.CLIENT_BUTTON_VERTICAL,
				pb: Dimens.CLIENT_BUTTON_VERTICAL,
				border: `1.5px solid ${selected ? 'transparent' : theme.palette.textMain.main}`,
				borderRadius: Dimens.SMALL_IMAGE_BORDER_RADIUS,
				pl: {
					md: Dimens.CLIENT_AREA_DEVICE_BUTTON_PADDING_HORIZANTAL,
					xs: Dimens.CLIENT_AREA_DEVICE_BUTTON_PADDING_HORIZANTAL_MOBILE,
					sm: Dimens.CLIENT_AREA_DEVICE_BUTTON_PADDING_HORIZANTAL_MOBILE,
				},
				pr: {
					md: Dimens.CLIENT_AREA_DEVICE_BUTTON_PADDING_HORIZANTAL,
					xs: Dimens.CLIENT_AREA_DEVICE_BUTTON_PADDING_HORIZANTAL_MOBILE,
					sm: Dimens.CLIENT_AREA_DEVICE_BUTTON_PADDING_HORIZANTAL_MOBILE,
				},
				'&:hover': {
					border: `1.5px solid ${theme.palette.primary.main}`,
					boxShadow: '3px 3px 6px 0px rgba(77, 255, 255, 0.6)',
					bgcolor: theme.palette.primary.main,
				},
			}}
		>
			{icon && (
				<SvgIcon sx={{ fontSize: { md: 100, xs: 50 } }} color="inherit" component="div" inheritViewBox>
					{icon}
				</SvgIcon>
			)}
			<BodyText
				sx={{
					fontSize: { md: Dimens.TITLE_TEXT_S, xs: Dimens.TITLE_TEXT_S_MOBILE },
					fontStyle: 'italic',
					color: 'inherit',
				}}
			>
				{title.toUpperCase()}
			</BodyText>
		</Stack>
	);
};

export default CheckboxCard;
