/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
	IconButton,
	Stack,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import useEffectCustom from '../../hooks/useEffectCustom';
import Dimens from 'assets/dimens';

interface Props {
	images: string[]
}

const LinearImageCarousel: React.FC<Props> = (props) => {
	const { images } = props;
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleImageChange = () => {
		setSelectedIndex((prev) => {
			let mPrev = prev;
			if (prev === images.length - 1) {
				return 0;
			}
			mPrev += 1;
			return mPrev;
		});
	};

	useEffectCustom(() => {
		let interval: NodeJS.Timer;
		if (images.length > 1) {
			interval = setInterval(() => {
				handleImageChange();
			}, 3000);
		}
		return () => clearInterval(interval);
	}, []);

	const onImageClick = (index: number) => {
		setSelectedIndex(index);
	};

	const handleImageChangeBackwards = () => {
		setSelectedIndex((prev) => {
			let mPrev = prev;
			if (prev === 0) {
				return images.length - 1;
			}
			mPrev -= 1;
			return mPrev;
		});
	};

	return (
		<Stack>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent={images.length > 1 ? 'space-between' : 'center'}
			>
				{images.length > 1 && (
					<IconButton
						color="inherit"
						sx={{
							border: `1px solid ${theme.palette.textMain.main}`,
							'&:hover': {
								bgcolor: theme.palette.primary.main,
							},
						}}
						onClick={() => handleImageChangeBackwards()}
					>
						<ArrowBack />
					</IconButton>
				)}
				<img
					src={images[selectedIndex]}
					alt="Watch"
					style={{
						width: isMd ? '500px' : '250px',
						height: isMd ? '500px' : '250px',
						objectFit: 'scale-down',
					}}
				/>
				{images.length > 1 && (
					<IconButton
						color="inherit"
						sx={{
							border: `1px solid ${theme.palette.textMain.main}`,
							'&:hover': {
								bgcolor: theme.palette.primary.main,
							},
						}}
						onClick={() => handleImageChange()}
					>
						<ArrowForward />
					</IconButton>
				)}
			</Stack>
			<Stack
				direction="row"
				spacing={2}
				justifyContent="center"
				sx={{
					overflowX: 'scroll',
					'&::-webkit-scrollbar': { display: 'none' },
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
					zindex: 1,
					mt: '25px',
				}}
			>
				{images.map((el, index) => (
					<img
						key={el.toString()}
						src={el}
						alt="Watch"
						onClick={() => onImageClick(index)}
						style={{
							cursor: 'pointer',
							objectFit: 'scale-down',
							height: isMd ? '134px' : '95px',
							width: isMd ? '134px' : '95px',
							borderRadius: isMd
								? Dimens.SMALL_IMAGE_BORDER_RADIUS
								: Dimens.SMALL_IMAGE_BORDER_RADIUS_MOBILE,
							border: selectedIndex === index ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.textMain.main}`,
						}}
					/>
				))}
			</Stack>
		</Stack>
	);
};

export default LinearImageCarousel;
