const LocalStorageWrapper = {
	get<Type>(key: string): Type | null {
		try {
			const json = localStorage.getItem(key);
			if (json) {
				const value: Type = JSON.parse(json);
				return value;
			}
			return null;
		} catch (error) {
			return null;
		}
	},
	set(key: string, value: unknown) {
		try {
			const json = JSON.stringify(value);
			localStorage.setItem(key, json);
			return true;
		} catch (error) {
			return false;
		}
	},
	remove(key: string) {
		localStorage.removeItem(key);
	},
	clear() {
		localStorage.clear();
	},
};

export const LocalStorageKeys = {
	THEME: 'Theme',
	LOCALE: 'Locale',
	USER: 'User',
	REFRESH_TOKEN: '_ABYTMLDJJ',
	HASH: '_JAHSG_HJHDSG',
	DEVICE: '_djsakdgsajhhdj',
	COOKIES: '_djsakdgsajhhdjfasdasf',
	BACKOFFICE_USER: '_jfksagfjsahgfjsaghjgwqurtuik',
	CART: '_caegdsauu',
};

export default LocalStorageWrapper;
