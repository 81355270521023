import React from 'react';
import {
	Box, Stack, useMediaQuery, Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TitleText, BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';

interface StoryCardProps {
  imageSrc: string;
  titleKey: string;
  textKey: string;
  direction?: 'row' | 'row-reverse' | 'column';
  imageSize?: '70%' | '100%';
  stackWidth?: string;
  textAlign?: 'left' | 'right' | 'center',
}

const StoryCard: React.FC<StoryCardProps> = (props) => {
	const {
		imageSrc,
		titleKey,
		textKey,
		direction = 'row',
		imageSize = '100%',
		stackWidth = '100%',
		textAlign = 'left',
	} = props;
	const { t } = useTranslation('translations');
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const alignSelfSecondRow = direction === 'row-reverse' ? 'flex-end' : 'flex-start';
	return (
		<Stack
			direction={{ md: direction, sm: 'column' }}
			alignItems="center"
			spacing={{ md: 13 }}
			alignSelf={alignSelfSecondRow}
			sx={{
				mt: { xs: 5 },
				width: { md: stackWidth, sm: '100%' },
			}}
		>
			<Box sx={{
				position: 'relative',
				'&::before': {
					content: '""',
					position: 'absolute',
					width: '359.49px',
					height: '32.53px',
					bottom: '0px',
					left: '23%',
					transform: 'translateX(-50%)',
					bgcolor: '#4DFFFF',
					opacity: 0.4,
					borderRadius: '359.486px',
					filter: 'blur(41.703704833984375px)',
					zIndex: -1,
				},
			}}
			>
				<img src={imageSrc} width={isMd ? imageSize : '100%'} alt="" />
			</Box>

			<Stack spacing={2} sx={{ mt: { xs: 5 }, width: { md: stackWidth, sm: '100%' } }}>
				<TitleText sx={{
					fontSize: {
						md: Dimens.BODY_TEXT_XL,
						xs: Dimens.BODY_TEXT_XL_MOBILE,
						sm: Dimens.BODY_TEXT_XL,
					},
					fontWeight: 'bold',
					textAlign,
				}}
				>
					{t(titleKey)}
				</TitleText>
				<BodyText sx={{ mt: 2, textAlign }}>
					{t(textKey)}
				</BodyText>
			</Stack>
		</Stack>
	);
};

export default StoryCard;
