import { AxiosError } from 'axios';
import { ICategoryRepository } from '../repository/categories-repository';
import { Either } from '@ajtec/either-typescript';

export class DeleteCategoryById {
	repository: ICategoryRepository;

	constructor(categoryRepository: ICategoryRepository) {
		this.repository = categoryRepository;
	}

	call(id: string): Promise<Either<AxiosError, string>> {
		return this.repository.deleteCategoryById(id);
	}
}
