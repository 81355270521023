import React from 'react';
import AuthWelcomePage from './AuthWelcomePage';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import { CommunityForm } from 'common/presentation/components';

const AuthPage = () => (
	<main>
		<CommunityProvier>
			<AuthWelcomePage />
			<CommunityForm />
		</CommunityProvier>
	</main>
);

export default AuthPage;
