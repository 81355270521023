/* eslint-disable class-methods-use-this */

import { ApiResponse } from 'common/data/entities/api-response';
import { ICoorporate } from '../../domain/entities/coorporate';
import { ContactApi } from './contact-api';
import { reqPost } from 'common/data/remote/request';
import { Endpoints } from 'assets/Endpoints';

export class ContactApiImpl implements ContactApi {
	async createContact(params: ICoorporate): Promise<ApiResponse<string>> {
		try {
			const resp = await reqPost({
				url: Endpoints.COORPORATE,
				data: params,
			});
			const data = resp.data as ApiResponse<string>;
			return data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
