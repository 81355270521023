import Dimens from 'assets/dimens';
import {
	DefaultButton, SizedBox, TitleText,
} from 'common/presentation/components';
import {
	Box, Card, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import './product-card.css';

interface Props {
	title: string;
	image: string;
	contrastText?: string;
	id: string;
}

const ProductCard: React.FC<Props> = (props) => {
	const { t } = useTranslation('translations');
	const {
		title, image, contrastText, id,
	} = props;
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<Card
			elevation={0}
			id={`product-card-${id}`}
			className="product-card"
			sx={{
				pt: { md: Dimens.CARD_PADDING, xs: Dimens.CARD_PADDING_MOBILE },
				pl: { md: Dimens.CARD_PADDING, xs: Dimens.CARD_PADDING_MOBILE },
				pr: { md: Dimens.CARD_PADDING, xs: Dimens.CARD_PADDING_MOBILE },
				pb: { md: Dimens.CARD_PADDING, xs: Dimens.CARD_PADDING_MOBILE },
				width: { md: '27%', xs: '100%' },
				bgcolor: theme.palette.background.default,
				border: `1.5px solid ${theme.palette.textMain.main}`,
				borderTopLeftRadius: Dimens.CARD_BORDER_RADIUS,
				borderBottomRightRadius: Dimens.CARD_BORDER_RADIUS,
				transition: 'border-color 0.5s ease',
				cursor: 'pointer',
				'&:hover': {
					borderColor: theme.palette.primary.main,
					boxShadow: '2px 2px 4px 0px rgba(77, 255, 255, 0.6)',
				},
			}}
		>
			<Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
				<Box>
					<TitleText
						color={contrastText}
						sx={{
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
							transition: 'color 0.5s ease',
						}}
					>
						{t(title).toUpperCase()}
					</TitleText>
					<Box sx={{ mt: { md: 4, xs: 2 } }}>
						<DefaultButton
							onClick={() => {
								navigate(AppLinks.PRODUCT_CATEGORY_DETAIL.formatMap({ categoryId: id }));
							}}
						>
							{t('learn_more')}
						</DefaultButton>
					</Box>
				</Box>
				<SizedBox height={4} />
				<Stack direction="row" justifyContent="center" alignItems="center">
					<img src={image} height="100%" width="100%" alt="Product" />
				</Stack>
			</Stack>
		</Card>
	);
};

export default ProductCard;
