/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import Dimens from 'assets/dimens';
import { TitleText } from 'common/presentation/components';
import useCommunity from 'features/community/presentation/provider/community.provider';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'assets/translations';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import CardArticle from 'features/community/presentation/iokee-community/components/CardArticle';

const OtherStories = () => {
	const { t } = useTranslation('translations');
	const lang = i18n.language;
	const navigate = useNavigate();
	const {
		articles, getArticles,
	} = useCommunity();

	React.useEffect(() => {
		getArticles(lang);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
		}}
		>
			<TitleText
				sx={{ fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE } }}
				dangerouslySetInnerHTML={{ __html: t('get_to_know_users').toUpperCase() }}
			/>
			<Stack
				direction="row"
				spacing={4}
				sx={{
					mt: Dimens.FOOTER_PADDING_VERTICAL,
					overflowX: 'scroll',
					'&::-webkit-scrollbar': { display: 'none' },
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
				}}
			>
				{articles?.map((el, index) => {
					if (index < 3) {
						return (
							<CardArticle
								article={el}
								onNavigate={(id) => navigate(AppLinks.COMMUNITY_DETAIL.formatMap({ id }))}
							/>
						);
					}
				})}
			</Stack>
		</Box>
	);
};

export default OtherStories;
