import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import AuthenticationIllistration from '../registration/components/AuthenticationIllistration';
import { illfroward, illbackward } from 'assets/images';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import AppLinks from 'assets/applinks.routes';
import { TitleText } from 'common/presentation/components';

const AuthWelcomePage = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<Stack
			direction="row"
			justifyContent="center"
			alignItems="center"
			spacing={2}
			sx={{
				pr: { md: 0, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
		>
			<Box sx={{
				width: '33%', display: { md: 'flex', xs: 'none' }, justifyContent: 'center',
			}}
			>
				<Box sx={{
					width: '950px',
					height: '850px',
					backgroundImage: `url(${illfroward})`,
					backgroundSize: 'contain',
					backgroundPosition: 'left',
					backgroundRepeat: 'no-repeat',
				}}
				/>
			</Box>

			<Box sx={{ width: { md: '33%', xs: '100%' }, display: 'flex', justifyContent: 'center' }}>
				<AuthenticationIllistration
					width={{ md: '627px', xs: '100%' }}
					height={{ md: '627px', xs: '70vh' }}
					titleText={(
						<TitleText
							sx={{
								fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
								fontWeight: Dimens.FONT_WEIGHT_NORMAL,
								color: theme.palette.textMain.main,
								fontStyle: 'italic',
								'&:hover': {
									color: theme.palette.primary.main,
								},
							}}
						>
							{t('login').toUpperCase()}
						</TitleText>
					)}
					titleLink={AppLinks.STORE_AREA_LOGIN}
					subtitleText={(
						<TitleText
							sx={{
								fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
								fontWeight: Dimens.FONT_WEIGHT_NORMAL,
								color: theme.palette.textMain.main,
								fontStyle: 'italic',
								mt: 3,
								'&:hover': {
									color: theme.palette.primary.main,
								},
							}}
						>
							{t('create_account').toUpperCase()}
						</TitleText>
					)}
					subtitleLink={AppLinks.STORE_AREA_REGISTRATION}
					titleFontSize={Dimens.TITLE_TEXT_S}
				/>

			</Box>

			<Box sx={{ width: '33%', display: { md: 'flex', xs: 'none' }, justifyContent: 'center' }}>
				<Box sx={{
					width: '950px',
					height: '850px',
					backgroundImage: `url(${illbackward})`,
					backgroundSize: 'contain',
					backgroundPosition: 'right',
					backgroundRepeat: 'no-repeat',
				}}
				/>
			</Box>
		</Stack>
	);
};

export default AuthWelcomePage;
