import { CommunityForm } from 'common/presentation/components';
import React from 'react';
import CommunityDetailHero from './CommunityDetailHero';
import BlogBody from './BlogBody';
import { CommunityProvier } from '../provider/community.provider';

const CommunityDetail = () => (
	<main>
		<CommunityProvier>
			<CommunityDetailHero />
			<BlogBody />
			<CommunityForm />
		</CommunityProvier>
	</main>
);

export default CommunityDetail;
