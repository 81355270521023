import Dimens from 'assets/dimens';
import { Stack, Box, useTheme } from '@mui/material';
import React from 'react';
import { BodyText } from 'common/presentation/components';

const filters = [
	'All',
	'SOLAR PANELS',
	'Analyzer',
	'Wallbox',
];

const CommunityFilters = () => {
	const theme = useTheme();
	return (
		<Stack
			direction="row"
			spacing={4}
			justifyContent={{ md: 'center' }}
			sx={{
				overflowX: 'scroll',
				'&::-webkit-scrollbar': { display: 'none' },
				scrollbarWidth: 'none',
				msOverflowStyle: 'none',
				minWidth: 0,
			}}
		>
			{filters.map((el) => (
				<Box
					component="span"
					sx={{
						pl: Dimens.BUTTON_PADDING_HORIZANTAL,
						pr: Dimens.BUTTON_PADDING_HORIZANTAL,
						border: `1px solid ${theme.palette.success.contrastText}`,
						borderRadius: '30px',
						cursor: 'pointer',
						textAlign: 'center',
						flexShrink: 0,
						whiteSpace: 'nowrap',
						transition: 'background-color 0.7s ease, color 0.7s ease, border-color 0.7s ease', // Smooth transition
						'&:hover': {
							backgroundColor: theme.palette.primary.light,
							color: theme.palette.primary.contrastText,
							borderColor: theme.palette.primary.main,
						},
					}}
				>
					<BodyText
						sx={{
							fontSize: Dimens.BODY_TEXT_S,
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
							color: theme.palette.textMain.main,
							'&:hover': {
								color: theme.palette.primary.contrastText,
							},
						}}
					>
						{el.toUpperCase()}
					</BodyText>
				</Box>
			))}
		</Stack>
	);
};

export default CommunityFilters;
