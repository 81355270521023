/* eslint-disable max-len */
import React, { createContext, useMemo } from 'react';
import {
	EnergyConsumptionMonthParams,
	EnergyConsumptionWeekParams,
	EnergyConsumptionYearParams,
	GraphType,
	HistoryPeriod,
	HistoryPeriodType,
	IEnergyConsumptionPeriod,
} from '../../domain/entities/energy-consumption-period';
import { CEnergyConsumptionRepositoryImpl } from '../../data/repository/energy-consumption-respository-impl';
import { GetEnergyConsumptionByMonth } from '../../domain/usecases/get-energy-consumption-month';
import { GetEnergyConsumptionYear } from '../../domain/usecases/get-energy-consumption-year';
import { GetEnergyConsumptionByWeek } from '../../domain/usecases/get-energy-consumption-week';
import { format, addDays, subDays } from 'date-fns';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

interface ClientBillingContextProps {
	energyConsumptionPeriod: IEnergyConsumptionPeriod[];
	loading: boolean;
	getEnergyConsumptionYear: (
		params: EnergyConsumptionYearParams) => void;
	getEnergyConsumptionMonth: (
		params: EnergyConsumptionMonthParams) => void;
	getEnergyConsumptionWeek: (
		params: EnergyConsumptionWeekParams) => void;
	error: string;
	currentYear: number;
	years: number[];
	prevYearToCompare: number | null;
	setYearToCompare: (year: number | null) => void;
	setCurrentYear: (year: number) => void;
	months: HistoryPeriod[];
	currentMonth?: HistoryPeriod;
	prevMonthToCompare: HistoryPeriod;
	setPrevMonthToCompare: (month: HistoryPeriod) => void;
	setCurrentMonth: (month: HistoryPeriod) => void;
	weeks: HistoryPeriod[];
	currentWeek: HistoryPeriod;
	comparisonData: IEnergyConsumptionPeriod[];
	setComparisonData: (data: IEnergyConsumptionPeriod[]) => void;
	getEnergyConsumptionForComparison: (
		params: EnergyConsumptionMonthParams | EnergyConsumptionYearParams, graphType: GraphType) => void;
	setCurrentWeek: (week: HistoryPeriod) => void;
}

const ClientBillingContext = createContext({} as ClientBillingContextProps);

export const ClientBillingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const thisYear = new Date().getFullYear();
	const [energyConsumptionPeriod, setEnergyConsumptionPeriod] = React.useState(
		[] as IEnergyConsumptionPeriod[],
	);
	const [comparisonData, setComparisonData] = React.useState([] as IEnergyConsumptionPeriod[]);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');

	const [years] = React.useState([thisYear, thisYear - 1, thisYear - 2]);
	const [currentYear, setCurrentYear] = React.useState(thisYear);
	const [prevYearToCompare, setYearToCompare] = React.useState<number | null>(null);

	const [months, setMonths] = React.useState([] as HistoryPeriod[]);
	const [currentMonth, setCurrentMonth] = React.useState({} as HistoryPeriod);
	const [prevMonthToCompare, setPrevMonthToCompare] = React.useState({} as HistoryPeriod);

	const [weeks, setWeeks] = React.useState<HistoryPeriod[]>([]);
	const [currentWeek, setCurrentWeek] = React.useState<HistoryPeriod>({} as HistoryPeriod);

	const energyConsumptionPeriodRepository = new CEnergyConsumptionRepositoryImpl();

	const getEnergyConsumptionYear = async (params: EnergyConsumptionYearParams) => {
		setLoading(true);
		const energyConsumptionYearly = new GetEnergyConsumptionYear(energyConsumptionPeriodRepository);
		const energyConsumptionPeriodResult = await energyConsumptionYearly.call(params);
		if (energyConsumptionPeriodResult.isRight()) {
			setComparisonData([] as IEnergyConsumptionPeriod[]);
			setPrevMonthToCompare({} as HistoryPeriod);
			setEnergyConsumptionPeriod(energyConsumptionPeriodResult.value);
		} else {
			setError(energyConsumptionPeriodResult.error);
		}
		setLoading(false);
	};

	const getEnergyConsumptionMonth = async (params: EnergyConsumptionMonthParams) => {
		setLoading(true);
		const energyConsumptionMonthly = new
		GetEnergyConsumptionByMonth(energyConsumptionPeriodRepository);
		const energyConsumptionPeriodResult = await energyConsumptionMonthly.call(params);
		if (energyConsumptionPeriodResult.isRight()) {
			setComparisonData([] as IEnergyConsumptionPeriod[]);
			setPrevMonthToCompare({} as HistoryPeriod);
			setEnergyConsumptionPeriod(energyConsumptionPeriodResult.value);
		} else {
			setError(energyConsumptionPeriodResult.error);
		}
		setLoading(false);
	};

	const getEnergyConsumptionWeek = async (params: EnergyConsumptionWeekParams) => {
		setLoading(true);
		const energyConsumptionWeekly = new
		GetEnergyConsumptionByWeek(energyConsumptionPeriodRepository);
		const energyConsumptionPeriodResult = await energyConsumptionWeekly.call(params);
		if (energyConsumptionPeriodResult.isRight()) {
			setComparisonData([] as IEnergyConsumptionPeriod[]);
			setPrevMonthToCompare({} as HistoryPeriod);
			setEnergyConsumptionPeriod(energyConsumptionPeriodResult.value);
		} else {
			setError(energyConsumptionPeriodResult.error);
		}
		setLoading(false);
	};

	const getEnergyConsumptionForComparison = async (params: EnergyConsumptionMonthParams | EnergyConsumptionYearParams, graphType: GraphType) => {
		setLoading(true);
		if (graphType === GraphType.YEAR) {
			const energyConsumptionYearly = new GetEnergyConsumptionYear(energyConsumptionPeriodRepository);
			const energyConsumptionPeriodResult = await energyConsumptionYearly.call(params);
			if (energyConsumptionPeriodResult.isRight()) {
				setComparisonData(energyConsumptionPeriodResult.value);
			} else {
				setError(energyConsumptionPeriodResult.error);
			}
		} else if (graphType === GraphType.MONTH) {
			const mParams = params as EnergyConsumptionMonthParams;
			const energyConsumptionMonthly = new
			GetEnergyConsumptionByMonth(energyConsumptionPeriodRepository);
			const energyConsumptionPeriodResult = await energyConsumptionMonthly.call(mParams);
			if (energyConsumptionPeriodResult.isRight()) {
				setComparisonData(energyConsumptionPeriodResult.value);
			} else {
				setError(energyConsumptionPeriodResult.error);
			}
		}
		setLoading(false);
	};

	const generateWeeks = () => {
		const list: HistoryPeriod[] = [];
		const sdf = (date: Date) => format(date, 'dd/MM');
		const sdfYear = (date: Date) => format(date, 'yyyy');

		for (let pos = 15; pos >= 0; pos -= 1) {
			const date = new Date();
			date.setDate(date.getDate() - 7 * pos);
			const start = subDays(date, date.getDay() - 1);
			const end = addDays(date, 7 - date.getDay());

			list.push({
				id: 15 - pos,
				name: `${sdf(start)} - ${sdf(end)}, ${sdfYear(date)}`,
				startDate: start,
				endDate: end,
				type: HistoryPeriodType.Week,
			});
		}

		setCurrentWeek(list[list.length - 1]);
		setWeeks(list.reverse());
	};

	const generatePreviousMonths = () => {
		const list: HistoryPeriod[] = [];
		const sdf = (date: Date) => format(date, 'MMM yyyy');

		for (let pos = 35; pos >= 0; pos -= 1) {
			const date = new Date();
			date.setMonth(date.getMonth() - pos);
			list.push({
				id: 35 - pos,
				name: sdf(date),
				startDate: date,
				endDate: date,
				type: HistoryPeriodType.Month,
			});
		}

		setCurrentMonth(list[list.length - 1]);
		setMonths(list.reverse());
	};

	useEffectCustom(() => {
		generatePreviousMonths();
		generateWeeks();
	}, []);

	const value = useMemo(() => ({
		energyConsumptionPeriod,
		loading,
		getEnergyConsumptionYear,
		getEnergyConsumptionMonth,
		getEnergyConsumptionWeek,
		error,
		years,
		prevYearToCompare,
		setYearToCompare,
		currentYear,
		setCurrentYear,
		months,
		currentMonth,
		setCurrentMonth,
		weeks,
		currentWeek,
		setCurrentWeek,
		prevMonthToCompare,
		setPrevMonthToCompare,
		comparisonData,
		setComparisonData,
		getEnergyConsumptionForComparison,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [energyConsumptionPeriod,
		loading,
		error,
		years,
		currentYear,
		months,
		currentMonth,
		weeks,
		currentWeek,
		comparisonData,
		setComparisonData,
		prevMonthToCompare,
		prevYearToCompare,
		setYearToCompare,
		setPrevMonthToCompare]);
	return <ClientBillingContext.Provider value={value}>{children}</ClientBillingContext.Provider>;
};

export default function useClientBilling() {
	return React.useContext(ClientBillingContext);
}
