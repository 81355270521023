import { Either, Left, Right } from '@ajtec/either-typescript';
import { ITariffRepository } from '../../domain/repository/tariff-repository';
import { TariffApiImpl } from '../remote/tariff-api-impl';
import { ITariffDataset } from '../../domain/entities/tariff-dataset';

export class CTariffRepositoryImpl implements ITariffRepository {
	tariffApi = new TariffApiImpl();

	async getTariffDataSet(userProductId: number): Promise<Either<string, ITariffDataset>> {
		try {
			const tariffDataset = await this.tariffApi.getTariffDataset(userProductId);
			return new Right(tariffDataset);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
