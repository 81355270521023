import React from 'react';
import DeviceDetailPage from './DeviceDetailPage';
import { DeviceDetailProvider } from './provider/device-detail.provider';

const DeviceDetail = () => (
	<DeviceDetailProvider>
		<DeviceDetailPage />
	</DeviceDetailProvider>
);

export default DeviceDetail;
