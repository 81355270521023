/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import {
	Button,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const Transition = React.forwardRef((
	props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

interface Props {
	open: boolean
	handleClose: () => void
	title: string
	description: string
}

const AlertDialog: React.FC<Props> = (props) => {
	const {
		open, handleClose, title, description,
	} = props;
	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle color="primary">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description" color="secondary">
					{description}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AlertDialog;
