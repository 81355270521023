/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
	Autocomplete as AutocompleteMUI,
	AutocompleteChangeDetails,
	AutocompleteChangeReason,
	AutocompleteRenderInputParams,
	AutocompleteRenderOptionState,
	TextField, CircularProgress, useTheme,
	AutocompleteValue,
	AutocompleteFreeSoloValueMapping,
} from '@mui/material';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

// Change the type of Value looking to other property, in this case 'Multiple'

interface AutocompleteProps<T, Multiple extends undefined | boolean> {
	label: string;
	placeholder: string;
	multiple?: Multiple;
	loading?: boolean;
	options: T[];
	groupBy?: (option: T) => string;
	getOptionLabel?: (option: T | AutocompleteFreeSoloValueMapping<Multiple>) => string;
	limitTags?: number;
	defaultValue?: AutocompleteValue<T, Multiple, false, Multiple> | undefined;
	disabled?: boolean;
	renderOption?: (
		props: React.HTMLAttributes<HTMLLIElement>,
		option: T,
		state: AutocompleteRenderOptionState,
	) => React.ReactNode;
	getOptionDisabled?: (option: T) => boolean;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	error?: boolean;
	helperText?: string | false;
	/**
	 * Callback fired when the value changes.
	 *
	 * @param {object} event The event source of the callback.
	 * @param {T|T[]} value The new value of the component.
	 * @param {string} reason One of "create-option",
	 * "select-option", "remove-option", "blur", "clear".
	 */
	onChange?: (
		event: React.ChangeEvent<any>,
		value: AutocompleteValue<T, Multiple, false, Multiple> | undefined,
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<T>,
	) => void;

	onInputChange?: (text: string) => void;
}

const defaultOptions = {
	loading: false,
};

const Autocomplete = <T, Multiple extends boolean | undefined = undefined>(
	mProps: AutocompleteProps<T, Multiple>,
) => {
	const props = { ...defaultOptions, ...mProps };
	const theme = useTheme();

	const [val, setVal] = useState<AutocompleteValue<T, Multiple, false, Multiple> | undefined>();
	useEffectCustom(() => {
		if (props.defaultValue !== val) {
			setVal(props.defaultValue);
		}
	}, [props.defaultValue]);

	const renderInput = (params: AutocompleteRenderInputParams) => (
		<TextField
			{...params}
			onChange={(event) => {
				props.onInputChange?.call(0, event.target.value);
			}}
			sx={{
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						borderColor: theme.palette.divider,
						transition: 'border-color 0.3s',
					},
				},
				'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					borderColor: theme.palette.primary.main,
				},
			}}
			variant="outlined"
			error={props.error}
			helperText={props.helperText}
			label={props.label}
			size="small"
			placeholder={props.placeholder}
			autoComplete="off"
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
						{props.loading ? (
							<CircularProgress color="inherit" size={15} />
						) : null}
						{params.InputProps.endAdornment}
					</>
				),
			}}
		/>
	);

	const aParams = {
		autoHighlight: true,
		filterSelectedOptions: true,
		multiple: props.multiple,
		limitTags: props.limitTags,
		options: props.options,
		groupBy: props.groupBy,
		getOptionLabel: props.getOptionLabel,
		renderOption: props.renderOption,
		getOptionDisabled: props.getOptionDisabled,
		onChange: props.onChange,
	};

	return val === undefined ? (
		<AutocompleteMUI
			key="auto-1"
			{...aParams}
			size="small"
			disabled={props.disabled}
			renderInput={(params) => renderInput(params)}
		/>
	) : (
		<AutocompleteMUI
			key="auto-2"
			{...aParams}
			size="small"
			disabled={props.disabled}
			renderInput={(params) => renderInput(params)}
			isOptionEqualToValue={props.isOptionEqualToValue}
			value={val}
		/>
	);
};

export default Autocomplete;
