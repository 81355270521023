import React from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Define the Props interface, extending from the DatePickerProps
interface ClientDatePickerProps extends DatePickerProps<Date> {
	label: string;
}

// Define the reusable ClientDatePicker component
const ClientDatePicker: React.FC<ClientDatePickerProps> = (props) => {
	const {
		label, value, sx, onChange, ...rest
	} = props;

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DatePicker
				label={label}
				value={value}
				onChange={onChange}
				slotProps={{
					textField: {
						size: 'small',
						sx: { width: '150px', ...sx },
					},
				}}
				{...rest}
			/>
		</LocalizationProvider>
	);
};

export default ClientDatePicker;
