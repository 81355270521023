/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { BodyText } from 'common/presentation/components';
import {
	Box,
	Card,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	Stack,
	Switch,
	Typography,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackofficeTextField from 'features/backoffice/common/presentation/input/BackofficeTextField';
import Space from 'common/presentation/components/space/Space';
import { useCategories } from '../../../providers/product-categories.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import Autocomplete from 'features/backoffice/common/presentation/auto-complete/Autocomplete';
import { LoadingButton } from '@mui/lab';
import { Add, Delete } from '@mui/icons-material';
import { useBackofficeProducts } from '../../../providers/product.provider';
import { currency } from 'utils/price.utils';
import { Formik, Form, FormikHelpers } from 'formik';
import { useUseProductValidation } from '../hooks/useProductValidation';
import { BackofficeProductRequest } from '../../domain/entities/backoffice-product';
import ProductImage from '../components/ProductImage';
import SpecImage from '../components/SpecImage';
import { Editor } from 'primereact/editor';
import { EmptyProduct } from '../../domain/entities/empty-product-values';

type TSetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void

const BackofficeAddEditProduct = () => {
	const { productId } = useParams();
	const { t } = useTranslation('translations');
	const {
		formValues,
		submit,
		uploadImage,
		getProductById,
		setFormValues,
	} = useBackofficeProducts();
	const { getCategories, categories } = useCategories();
	const { productSchema } = useUseProductValidation();
	useEffectCustom(() => {
		getCategories();
		if (productId) {
			getProductById(productId);
		} else {
			setFormValues(EmptyProduct);
		}
		return () => {
			setFormValues(EmptyProduct);
		};
	}, []);

	const handlAddInstallationStep = (
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mInstallationSteps = [...values.installationSteps];
		mInstallationSteps.push({
			header: '',
			description: '',
			stepOrder: values.installationSteps.length + 1,
			attachmentId: '',
		});
		setFieldValue('installationSteps', mInstallationSteps);
	};

	const handleAddSpecification = (
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mSpecifications = [...values.specifications];
		mSpecifications.push({
			header: '',
			description: '',
			attachmentId: '',
		});
		setFieldValue('specifications', mSpecifications);
	};

	const handleRemoveSpecification = (
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mSpecifications = [...values.specifications];
		mSpecifications.splice(index, 1);
		setFieldValue('specifications', mSpecifications);
	};

	const handleDeleteStep = (
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mInstallationSteps = [...values.installationSteps];
		mInstallationSteps.splice(index, 1);
		setFieldValue('installationSteps', mInstallationSteps);
	};

	const handleAddImage = (values: BackofficeProductRequest, setFieldValue: TSetFieldValue) => {
		const mAttachments = [...values.attachments];
		mAttachments.push({
			attachmentId: '',
			isMain: false,
		});
		setFieldValue('attachments', mAttachments);
	};
	const handleRemoveImage = (
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mAttachments = [...values.attachments];
		mAttachments.splice(index, 1);
		setFieldValue('attachments', mAttachments);
	};

	const handleAttachmentChange = async (
		key: string,
		value: File | boolean,
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mAttachments = [...values.attachments];
		if (key === 'isMain' && value === true) {
			// Switch off all other isMain values
			mAttachments.forEach((attachment, idx) => {
				if (idx !== index) {
					attachment.isMain = false;
				}
			});
		} else if (key === 'isMain' && value === false) {
			const isAnyMain: number[] = [];
			mAttachments.forEach((attachment, idx) => {
				if ((attachment.isMain && idx !== index)) {
					isAnyMain.push(idx);
				}
			});
			if (isAnyMain.length === 0) {
				mAttachments[0].isMain = true;
			}
		}
		if (key === 'attachmentId' && typeof value === 'object' && value.size) {
			const imageRes = await uploadImage(value);
			if (imageRes) {
				mAttachments[index] = {
					attachmentId: imageRes.id.toString(),
					url: imageRes.url,
					isMain: mAttachments[index].isMain,
				};
			}
		} else {
			mAttachments[index] = {
				...mAttachments[index],
				[key]: value,
			};
		}
		setFieldValue('attachments', mAttachments);
	};

	const handleSpecificationDescriptionChange = (
		key: string,
		value: string | null,
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mSpecifications = [...values.specifications];
		mSpecifications[index] = {
			...mSpecifications[index],
			[key]: value,
		};
		mSpecifications[index] = {
			...mSpecifications[index],
			[key]: value,
		};
		setFieldValue('specifications', mSpecifications);
	};

	const handleSpecificationChange = async (
		key: string,
		value: File | string | number,
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mSpecifications = [...values.specifications];
		mSpecifications[index] = {
			...mSpecifications[index],
			[key]: value,
		};
		if (key === 'attachmentId' && typeof value === 'object' && value.size) {
			const imageRes = await uploadImage(value);
			if (imageRes) {
				mSpecifications[index] = {
					...mSpecifications[index],
					attachmentId: imageRes.id.toString(),
					url: imageRes.url,
				};
			}
		} else {
			mSpecifications[index] = {
				...mSpecifications[index],
				[key]: value,
			};
		}
		setFieldValue('specifications', mSpecifications);
	};

	const handleStepsChange = async (
		key: string,
		value: File | string | number,
		index: number,
		values: BackofficeProductRequest,
		setFieldValue: TSetFieldValue,
	) => {
		const mSteps = [...values.installationSteps];
		if (key === 'attachmentId' && typeof value === 'object' && value.size) {
			const imageRes = await uploadImage(value);
			if (imageRes) {
				mSteps[index] = {
					...mSteps[index],
					attachmentId: imageRes.id.toString(),
					url: imageRes.url,
				};
			}
		} else {
			mSteps[index] = {
				...mSteps[index],
				[key]: value,
			};
		}
		setFieldValue('installationSteps', mSteps);
	};

	const handleFormSubmit = (
		values: BackofficeProductRequest,
		formikHelpers: FormikHelpers<BackofficeProductRequest>,
	) => {
		if (productId) {
			submit(values, formikHelpers, productId);
		} else {
			submit(values, formikHelpers);
		}
	};

	return (
		<Box>
			<BodyText>
				{productId
					? t('edit_product')
					: t('add_product')}
			</BodyText>
			<Card
				sx={{
					boxShadow: 'none',
					p: 4,
					gap: 2,
					mt: 4,
				}}
			>
				<Formik
					initialValues={formValues}
					validationSchema={productSchema}
					onSubmit={handleFormSubmit}
					enableReinitialize
				>
					{({
						errors,
						values,
						handleChange,
						setFieldValue,
						isSubmitting,
					}) => (
						<Form>
							<Grid container spacing={2}>
								<Grid item xs={12} md={4}>
									<BackofficeTextField
										name="name"
										error={!!errors.name}
										helperText={errors.name}
										disabled={isSubmitting}
										onChange={handleChange}
										value={values.name}
										label={t('name')}
										placeholder={t('name')}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<BackofficeTextField
										name="price"
										onChange={(e) => setFieldValue('price', currency(e).target.value)}
										value={values.price}
										error={!!errors.price}
										helperText={errors.price}
										disabled={isSubmitting}
										label={t('price')}
										placeholder={t('price')}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<BackofficeTextField
										name="warrantyMonths"
										type="number"
										onChange={handleChange}
										value={values.warrantyMonths}
										error={!!errors.warrantyMonths}
										helperText={errors.warrantyMonths}
										disabled={isSubmitting}
										label={t('warranty_months')}
										placeholder={t('warranty_months')}
									/>
								</Grid>
							</Grid>
							<Space height={2} />
							<Grid container spacing={2}>
								<Grid item xs={12} md={4}>
									<BackofficeTextField
										name="storageNote"
										onChange={handleChange}
										value={values.storageNote}
										error={!!errors.storageNote}
										helperText={errors.storageNote}
										disabled={isSubmitting}
										label={t('storage_note')}
										placeholder={t('storage_note')}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<Autocomplete
										label={t('category')}
										placeholder={t('category')}
										defaultValue={categories.find((el) => el.id === values.categoryId)}
										error={!!errors.categoryId}
										helperText={errors.categoryId}
										disabled={isSubmitting}
										options={categories}
										renderOption={(props, option) => (
											<li {...props} key={option.id}>{option.name}</li>
										)}
										getOptionLabel={(option) => option.name || ''}
										onChange={(_, value) => setFieldValue('categoryId', value?.id)}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<FormGroup>
										<FormControlLabel control={<Switch disabled={isSubmitting} checked={values.isHighlighted} onChange={() => setFieldValue('isHighlighted', !values.isHighlighted)} />} label={t('highlighted')} />
									</FormGroup>
								</Grid>
							</Grid>
							<Space height={2} />
							<Grid container spacing={2}>
								<Grid item xs={12} md={8}>
									<BackofficeTextField
										name="description"
										value={values.description}
										error={!!errors.description}
										helperText={errors.description}
										disabled={isSubmitting}
										onChange={handleChange}
										multiline
										minRows={3}
										label={t('description')}
										placeholder={t('description')}
									/>
								</Grid>
							</Grid>
							<Space height={4} />
							<Grid container spacing={2}>
								<Grid item xs={12} md={8}>
									<BodyText>
										{t('how_it_works')}
									</BodyText>
									<Space height={1} />
									<Editor
										value={values.howItWorks}
										disabled={isSubmitting}
										placeholder={t('how_it_works')}
										onTextChange={(e) => setFieldValue('howItWorks', e.htmlValue)}
										style={{ height: '100px', width: '100%' }}
									/>
									<Typography variant="caption" color="error">
										{
										// @ts-ignore
											errors?.howItWorks && String(errors?.howItWorks || '')
										}
									</Typography>
								</Grid>
							</Grid>
							<Space height={4} />
							<Stack direction="row" alignItems="center" spacing={2}>
								<BodyText>
									{t('images')}
								</BodyText>
								<IconButton
									disabled={isSubmitting}
									onClick={() => handleAddImage(values, setFieldValue)}
								>
									<Add />
								</IconButton>
							</Stack>
							<Space height={4} />
							<Stack direction="row" alignItems="center" useFlexGap spacing={2} flexWrap="wrap">
								{values.attachments.map((el, index) => (
									<ProductImage
										disabled={isSubmitting}
										showRemoveButton={index !== 0}
										onImageChange={(image) => {
											handleAttachmentChange(
												'attachmentId',
												image,
												index,
												values,
												setFieldValue,
											);
										}}
										onRemoveClick={() => {
											handleRemoveImage(index, values, setFieldValue);
										}}
										imageToPreview={el.url}
										isMain={el.isMain}
										// @ts-ignore
										error={errors?.attachments && errors?.attachments[index]?.attachmentId}
										handleMainChange={(value) => {
											handleAttachmentChange(
												'isMain',
												value,
												index,
												values,
												setFieldValue,
											);
										}}
									/>
								))}
							</Stack>
							<Space height={3} />
							<Stack direction="row" alignItems="center" spacing={2}>
								<BodyText>
									{t('specifications')}
								</BodyText>
								<IconButton
									disabled={isSubmitting}
									onClick={() => handleAddSpecification(values, setFieldValue)}
								>
									<Add />
								</IconButton>
							</Stack>
							{values.specifications.map((specification, index) => (
								<Box key={`specification-${index}`}>
									<Space height={2} />
									<Grid container spacing={2}>
										<Grid item xs={12} md={3}>
											<BackofficeTextField
												name="header"
												onChange={(e) => handleSpecificationChange('header', e.target.value, index, values, setFieldValue)}
												value={specification.header}
												disabled={isSubmitting}
												// error={!!errors?.specifications[index]?.header}
												// helperText={errors?.specifications[index]?.header}
												label={t('header')}
												placeholder={t('header')}
											/>
											<Typography variant="caption" color="error">
												{
													// @ts-ignore
													errors?.specifications && String(errors?.specifications[index]?.header || '')
												}
											</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<Editor
												value={specification.description}
												disabled={isSubmitting}
												placeholder={t('description')}
												onTextChange={(e) => handleSpecificationDescriptionChange('description', e.htmlValue, index, values, setFieldValue)}
												style={{ height: '100px' }}
											/>
											<Typography variant="caption" color="error">
												{
													// @ts-ignore
													errors?.specifications && String(errors?.specifications[index]?.description || '')
												}
											</Typography>
										</Grid>
										<Grid item xs={12} md={1}>
											<SpecImage
												id={`specification-${index}`}
												imageToPreview={specification.url}
												disabled={isSubmitting}
												onImageChange={(image) => {
													handleSpecificationChange('attachmentId', image, index, values, setFieldValue);
												}}
											/>
										</Grid>
										{index !== 0 && (
											<Grid item xs={12} md={2}>
												<IconButton
													disabled={isSubmitting}
													color="error"
													onClick={() => handleRemoveSpecification(index, values, setFieldValue)}
												>
													<Delete />
												</IconButton>
											</Grid>
										)}
									</Grid>
								</Box>
							))}
							<Space height={3} />
							<Stack direction="row" alignItems="center" spacing={2}>
								<BodyText>
									{t('installation_steps')}
								</BodyText>
								<IconButton
									disabled={isSubmitting}
									onClick={() => handlAddInstallationStep(values, setFieldValue)}
								>
									<Add />
								</IconButton>
							</Stack>
							{/* Map from array of inputs */}
							{values.installationSteps.map((step, index) => (
								<Box key={`step-${index}`}>
									<Space height={2} />
									<Grid container spacing={2}>
										<Grid item xs={12} md={3}>
											<BackofficeTextField
												name="header"
												disabled={isSubmitting}
												onChange={(e) => handleStepsChange('header', e.target.value, index, values, setFieldValue)}
												value={step.header}
												label={t('header')}
												placeholder={t('header')}
											/>
											<Typography variant="caption" color="error">
												{
													// @ts-ignore
													errors?.installationSteps && String(errors?.installationSteps[index]?.header || '')
												}
											</Typography>
										</Grid>
										<Grid item xs={12} md={3}>
											<BackofficeTextField
												name="description"
												disabled={isSubmitting}
												onChange={(e) => handleStepsChange('description', e.target.value, index, values, setFieldValue)}
												value={step.description}
												label={t('description')}
												placeholder={t('description')}
											/>
											<Typography variant="caption" color="error">
												{
													// @ts-ignore
													errors?.installationSteps && String(errors?.installationSteps[index]?.description || '')
												}
											</Typography>
										</Grid>
										<Grid item xs={12} md={3}>
											<BackofficeTextField
												type="number"
												disabled
												value={index + 1}
												name="stepOrder"
												label={t('step_order')}
												placeholder={t('step_order')}
											/>
										</Grid>
										<Grid item xs={12} md={1}>
											<SpecImage
												id={`step-${index}`}
												imageToPreview={step.url}
												disabled={isSubmitting}
												onImageChange={(image) => {
													handleStepsChange('attachmentId', image, index, values, setFieldValue);
												}}
											/>
										</Grid>
										{index !== 0 && (
											<Grid item xs={12} md={2}>
												<IconButton disabled={isSubmitting} color="error" onClick={() => handleDeleteStep(index, values, setFieldValue)}>
													<Delete />
												</IconButton>
											</Grid>
										)}
									</Grid>
								</Box>
							))}
							<Space height={2} />
							<LoadingButton loading={isSubmitting} type="submit" variant="contained">
								{t('save')}
							</LoadingButton>
						</Form>
					)}
				</Formik>
			</Card>
		</Box>
	);
};

export default BackofficeAddEditProduct;
