import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { IOrder } from '../orders/domain/entities/order';
import { OrderBackofficeRepositoryImpl } from '../orders/data/repository/order-repository-impl';
import { GetBackofficeOrders } from '../orders/domain/usecases/GetOrders';
import { ChangeOrderStatus } from '../orders/domain/usecases/ChangeOrderStatus';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { useTranslation } from 'react-i18next';

type Props = {
	loading: boolean
	getOrders: () => void
	orders: IOrder[]
	changeStatus: (id: number, status: number) => void
}

const OrderContext = createContext({} as Props);

export const BackofficeOrderProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const [orders, setOrders] = useState([] as IOrder[]);
	const { t } = useTranslation('translations');
	const { addDialog } = useFeedback();

	const orderRepositoryImpl = new OrderBackofficeRepositoryImpl();

	const getOrders = async () => {
		setLoading(true);
		const getOrderUsecase = new GetBackofficeOrders(orderRepositoryImpl);
		const getOrdersResult = await getOrderUsecase.call();
		if (getOrdersResult.isRight()) {
			setOrders(getOrdersResult.value);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const changeStatus = async (id: number, status: number) => {
		setLoading(true);
		const changeStatusUseCase = new ChangeOrderStatus(orderRepositoryImpl);
		const changeStatusResult = await changeStatusUseCase.call(id, status);
		if (changeStatusResult.isRight()) {
			setLoading(false);
			setOrders((prev) => {
				const mOrders = [...prev];
				const index = mOrders.findIndex((el) => el.id === id);
				mOrders[index] = {
					...mOrders[index],
					status,
				};
				return mOrders;
			});
		} else {
			setLoading(false);
			addDialog({
				error: true,
				message: t('unable_to_change_status'),
				title: t('error'),
			});
		}
	};

	const value = useMemo(() => ({
		loading,
		orders,
		getOrders,
		changeStatus,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [loading, orders]);

	return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

export function useBackofficeOrders() {
	return useContext(OrderContext);
}
