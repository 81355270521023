import { ErrorResponse } from 'common/domain/entities/error-response';
import { ILandingRepository } from '../repository/landing-repository';
import { ICategories } from '../entities/categories';
import { Either } from '@ajtec/either-typescript';

export class GetCategoryById {
	repository: ILandingRepository;

	constructor(landingRepository: ILandingRepository) {
		this.repository = landingRepository;
	}

	call(id: string): Promise<Either<ErrorResponse, ICategories>> {
		return this.repository.getCategoryById(id);
	}
}
