import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { IEnergyConsumptionPeriod } from '../../domain/entities/energy-consumption-period';
import { useTheme } from '@mui/material';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { useTranslation } from 'react-i18next';
// import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

interface Props {
	graphData: IEnergyConsumptionPeriod[];
	comparisonData?: IEnergyConsumptionPeriod[];
	primaryTitle?: string;
	secondaryTitle?: string;
}

const EnergyConsumptionGraph: React.FC<Props> = (props) => {
	const {
		graphData, comparisonData, primaryTitle, secondaryTitle,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const [state, setState] = React.useState({
		series: [
			{
				name: primaryTitle ?? 'Series 1',
				type: 'column',
				color: theme.palette.primary.main,
				data: graphData.map((item) => item.consumptionAmount),
			},
		],
	});

	useEffectCustom(() => {
		if (comparisonData) {
			setState({
				series: [
					{
						name: primaryTitle ?? 'Series 1',
						type: 'column',
						color: theme.palette.primary.main,
						data: graphData.map((item) => item.consumptionAmount),
					},
					{
						name: secondaryTitle ?? 'Series 2',
						type: 'line',
						color: theme.palette.secondary.main,
						data: comparisonData.map((item) => item.consumptionAmount),
					},
				],
			});
		} else {
			setState({
				series: [
					{
						name: primaryTitle ?? 'Series 1',
						type: 'column',
						color: theme.palette.primary.main,
						data: graphData.map((item) => item.consumptionAmount),
					},
				],
			});
		}
	}, [comparisonData, graphData]);

	const options: ApexOptions = {
		chart: {
			height: 430,
			type: 'line',
		},
		stroke: {
			width: [0, 2],
		},
		plotOptions: {
			bar: {
				barHeight: '90%',
				distributed: false,
				horizontal: false,
				dataLabels: {
					position: 'top',
				},
			},
		},
		dataLabels: {
			enabled: true,
			enabledOnSeries: [1],
			formatter(val) {
				return `${parseFloat(val.toString() || '0').toFixed(3)} €`;
			},
		},
		tooltip: {
			enabled: true,
			custom: ({
				series, seriesIndex, dataPointIndex,
			}) => '<div class="arrow_box">'
			+ `<div></div><div>${t('cost')}: ${series[seriesIndex][dataPointIndex] || 0} €</div>
			<div>${t('consumption')}: ${seriesIndex === 0 ? graphData[dataPointIndex]?.consumption || 0 : comparisonData && comparisonData[dataPointIndex]?.consumption} kwh</div>`
			+ '</div>',
		},
		legend: { show: false },
		xaxis: {
			categories: (
				comparisonData && (comparisonData.length > graphData.length)
					? comparisonData.map((item) => item.barDisplay)
					: graphData.map((item) => item.barDisplay)
			),
		},
		yaxis: {
			show: true,
			labels: {
				show: true,
				formatter: (value) => `${parseFloat(`${value}`).toFixed(3)} €`,
			},
		},
		grid: {
			show: true,
			strokeDashArray: 0,
			position: 'back',
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
			row: {
				colors: undefined,
				opacity: 1,
			},
			column: {
				colors: undefined,
				opacity: 1,
			},
		},
	};

	return (
		<div>
			<ReactApexChart options={options} series={state.series} height={430} />
		</div>
	);
};

export default EnergyConsumptionGraph;
