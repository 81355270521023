import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { clientArrowCard } from 'assets/icons';
import { useTranslation } from 'react-i18next';

interface EnergyPriceSummaryProps {
	consumption: number;
	cost: number;
}

const EnergyPriceSummary: React.FC<EnergyPriceSummaryProps> = ({
	consumption,
	cost,
}) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<Stack
			direction="row"
			spacing={2}
			sx={{
				backgroundColor: theme.palette.background.paper,
				p: {
					xs: 1,
					sm: 1.2,
					// lg: 1.5,
				},
				borderRadius: Dimens.CLIENT_AREA_PAGE_BORDER_RADIUS_MOBILE,
				alignItems: 'center',
				boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
				width: '100%',
				maxWidth: {
					xs: '100%', // Full width for mobile screens
					sm: Dimens.COMMUNITY_CARD_XS_WIDTH, // Smaller max width for small screens
					md: Dimens.COMMUNITY_CARD_MD_WIDTH, // Default max width for medium and larger screens
				},
				justifyContent: 'space-between',
				mx: 'auto',
			}}
		>
			{/* Left side - accumulated energy */}
			<Stack spacing={0.5} alignItems="flex-start">
				<BodyText
					sx={{
						color: theme.palette.textLight.main,
						fontSize: {
							xs: '8px',
							sm: Dimens.BODY_TEXT_S,
						},
					}}
				>
					{t('accumulated_energy')}
				</BodyText>
				<BodyText
					sx={{
						display: 'flex',
						alignItems: 'center',
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
						color: theme.palette.textMain.main,
						fontSize: {
							xs: Dimens.TITLE_TEXT_M_MOBILE,

						},
					}}
				>
					⚡
					{' '}
					{consumption}
					<span
						style={{
							fontSize: Dimens.TEXT_DEFAULT,
							fontWeight: Dimens.FONT_WEIGHT_LIGHT,
							marginLeft: Dimens.REGISTRATION_PAGE_SIZE,
						}}
					>
						kWh
					</span>
				</BodyText>
			</Stack>

			{/* Middle Arrow */}
			<img src={clientArrowCard} alt="" style={{ width: 'auto', height: '24px' }} />

			{/* Right side - total price */}
			<Stack spacing={0.5} alignItems="flex-end">
				<BodyText
					sx={{
						fontSize: {
							xs: Dimens.BUTTON_TEXT_MOBILE,
						},
						color: theme.palette.textLight.main,
					}}
				>
					{t('client_total_price')}
				</BodyText>
				<BodyText
					sx={{
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
						color: theme.palette.textMain.main,
						fontSize: {
							xs: '18px',
							sm: 'px',
						},
					}}
				>
					{cost}
					{' '}
					€
				</BodyText>
			</Stack>
		</Stack>
	);
};

export default EnergyPriceSummary;
