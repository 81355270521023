import React from 'react';
import { BodyText, TitleText } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import DefaultButton from 'common/presentation/components/default-button/DefaultButton';
import { greenPlanet } from 'assets/images';

const GreenManifesto = () => {
	const { t } = useTranslation('translations');

	return (
		<Box
			sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
		>
			<Box sx={{ mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE } }}>
				<TitleText
					size={Dimens.TITLE_TEXT_L}
					sx={{
						fontStyle: 'italic',
						textTransform: 'uppercase',
					}}
					dangerouslySetInnerHTML={{ __html: t('green_manifesto') }}
				/>
			</Box>
			<Stack
				direction={{ md: 'row', sm: 'column', xs: 'column' }}
				justifyContent="space-around"
				alignItems={{ md: 'flex-end', xs: 'center', sm: 'center' }}
				sx={{
					pt: {
						md: Dimens.TITLE_SPACING,
						xs: Dimens.TITLE_SPACING_MOBILE,
					},
				}}
			>
				<img src={greenPlanet} alt="Green manifesto" />
				<Box sx={{ width: { md: '60%', xs: '100%' } }}>
					<BodyText
						size={Dimens.BODY_TEXT_L}
						sx={{
							fontWeight: Dimens.FONT_WEIGHT_NORMAL,
						}}
					>
						{t('green_manifesto_info')}
					</BodyText>
					<Stack sx={{ mt: 5 }} alignItems={{ md: 'flex-start', xs: 'flex-end', sm: 'flex-end' }}>
						<DefaultButton>{t('read_all')}</DefaultButton>
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default GreenManifesto;
