import { ICartProduct } from './cart-product';

export enum CartActionKind {
	ADD_TO_CART = 'ADD_TO_CART',
	REMOVE_FROM_CART = 'REMOVE_FROM_CART',
	INCREMENT = 'INCREMENT',
	DECREMENT = 'DECREMENT',
	TOTAL = 'TOTAL',
	CLEAR_CART = 'CLEAR_CART'
}

export interface ICartAction {
	type: CartActionKind;
	payload: ICartProduct
}
