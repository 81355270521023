import { Either } from '@ajtec/either-typescript';
import { IOrderBackofficeRepository } from '../repository/order-repository';
import { AxiosError } from 'axios';
import { IOrder } from '../entities/order';

export class GetBackofficeOrders {
	repository: IOrderBackofficeRepository;

	constructor(orderRepository: IOrderBackofficeRepository) {
		this.repository = orderRepository;
	}

	call(): Promise<Either<AxiosError, IOrder[]>> {
		return this.repository.getOrders();
	}
}
