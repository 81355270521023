import React, {
	FC, createContext, useContext, useMemo, useState,
} from 'react';
import { EnergyConsumptionParams, IEnergyConsumption } from '../../domain/entities/energy-consuption';
import { CDeviceDetailRepositoryImpl } from '../../data/repository/device-detail-repository-impl';
import { GetEnergyConsumption } from '../../domain/usecases/get-energy-consumption';

interface DeviceDetailContextProps {
   energyConsumption?: IEnergyConsumption;
   loading: boolean;
   getEnergyConsumption: (params: EnergyConsumptionParams) => void;
   error: string
}

const DeviceDetailContext = createContext({} as DeviceDetailContextProps);

export const DeviceDetailProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [energyConsumption, setEnergyCOnsumption] = useState({} as IEnergyConsumption);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const deviceDetailRepository = new CDeviceDetailRepositoryImpl();

	const getEnergyConsumption = async (params: EnergyConsumptionParams) => {
		setLoading(true);
		const energyConsumptionUseCase = new GetEnergyConsumption(deviceDetailRepository);
		const energyConsumptionResult = await energyConsumptionUseCase.call(params);
		if (energyConsumptionResult.isRight()) {
			setEnergyCOnsumption(energyConsumptionResult.value);
		} else {
			setError(energyConsumptionResult.error);
		}
		setLoading(false);
	};

	const value = useMemo(() => ({
		energyConsumption,
		loading,
		getEnergyConsumption,
		error,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [energyConsumption,
		loading,
		error]);
	return <DeviceDetailContext.Provider value={value}>{children}</DeviceDetailContext.Provider>;
};

export default function useDeviceDetail() {
	return useContext(DeviceDetailContext);
}
