import React from 'react';
import {
	Box, Stack,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText, TitleText } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import { communityLanding } from 'assets/images';

const CommunitySection = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mb: Dimens.FOOTER_PADDING_VERTICAL,
				mt: Dimens.SECTION_SPACING,
			}}
			>
				<Stack direction={{ md: 'row', sm: 'column', xs: 'column' }} justifyContent="space-between" spacing={4}>
					<Stack sx={{ width: { md: '45%', sm: '100%', xs: '100%' } }} spacing={3}>
						<TitleText
							sx={{
								textAlign: 'right',
								fontWeight: Dimens.FONT_WEIGHT_BOLDER,
								fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
							}}
						>
							{t('save_planet_title').toUpperCase()}
						</TitleText>
						<Box
							sx={{
								height: Dimens.COMMUNITY_IMAGE_HEIGHT_LANDING,
								background: `url(${communityLanding})`,
								backgroundRepeat: 'no-repeat',
								backgroundSize: '100%',
								backgroundPositionY: 'bottom',
								borderRadius: Dimens.SMALL_IMAGE_BORDER_RADIUS,
							}}
						/>
					</Stack>
					<Stack
						direction={{ md: 'column', sm: 'column-reverse', xs: 'column-reverse' }}
						sx={{ width: { md: '45%', sm: '100%', xs: '100%' } }}
						spacing={3}
					>
						<Box
							sx={{
								height: Dimens.COMMUNITY_IMAGE_HEIGHT_LANDING_SECOND,
								background: `url(${communityLanding})`,
								backgroundRepeat: 'no-repeat',
								backgroundSize: '100%',
								backgroundPositionY: 'top',
								borderRadius: Dimens.SMALL_IMAGE_BORDER_RADIUS,
							}}
						/>
						<BodyText
							sx={{
								fontWeight: Dimens.FONT_WEIGHT_LIGHT,
								fontSize: { md: Dimens.BODY_TEXT_ML, xs: Dimens.BODY_TEXT_ML_MOBILE },
							}}
						>
							{t('save_planet_title_info_one')}
							{' '}
						</BodyText>
						<BodyText
							sx={{
								mt: 3,
								fontWeight: Dimens.FONT_WEIGHT_BOLD,
								fontSize: { md: Dimens.BODY_TEXT_ML, xs: Dimens.BODY_TEXT_ML_MOBILE },
							}}
						>
							{t('save_planet_title_info_two')}
							{' '}
						</BodyText>
					</Stack>
				</Stack>
			</Box>
		</section>
	);
};

export default CommunitySection;
