import React from 'react';
import LoginImageView from '../components/LoginImageView';
import { Stack, Theme, useMediaQuery } from '@mui/material';
import ForgotPasswordForm from './ForgotPasswordForm';
import { logoBlackMobile } from 'assets/icons';
import Dimens from 'assets/dimens';

const ForgotPassword = () => {
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
	return (
		<main>
			{!isMd && <img src={logoBlackMobile} style={{ marginLeft: Dimens.PAGE_PADDING_MOBILE }} alt="Logo" />}
			<Stack direction={{ md: 'row', xs: 'column-reverse' }} columnGap={4}>
				<ForgotPasswordForm />
				<LoginImageView />
			</Stack>
		</main>
	);
};

export default ForgotPassword;
