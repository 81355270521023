import React, { useState } from 'react';
import {
	Box,
	Collapse,
	IconButton,
	Table, TableBody, TableCell, TableHead, TableRow, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { IOrder } from '../../domain/entities/order';
import { formatPrice } from 'utils/price.utils';
import { Tag } from 'primereact/tag';
import { getOrderStatusColors } from 'utils/general.utils';
import { formatDate } from 'utils/date/date.utils';

interface Props {
	status: number
}

const GetOrderStatus: React.FC<Props> = ({ status }) => {
	const { t } = useTranslation('translations');
	let statusLabel = '';
	switch (status) {
	case 1:
		statusLabel = t('new');
		break;
	case 2:
		statusLabel = t('in_progress');
		break;
	case 3:
		statusLabel = t('served');
		break;
	case 4:
		statusLabel = t('rejected');
		break;
	default:
		break;
	}
	const { color, bgColor } = getOrderStatusColors(status);
	return (
		<Tag
			value={statusLabel}
			style={{ backgroundColor: bgColor, color, textAlign: 'center' }}
		/>
	);
};

type OrderRowProps = {
	data: IOrder
	rowSelected: IOrder | null
	setRowSelected: (params: IOrder) => void
}

type StyledRowProps = {
	selected: boolean
}

export const OrderRow: React.FC<OrderRowProps> = (props) => {
	const { data, rowSelected, setRowSelected } = props;
	const { t } = useTranslation('translations');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	const StyledTableRow = styled(TableRow)<StyledRowProps>(({ selected }) => ({
		padding: '34px',
		height: '60px',
		backgroundColor: selected
			? theme.palette.primary.light
			: theme.palette.background.paper,
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
		cursor: 'pointer',
	}));

	const handleClick = () => {
		setRowSelected(data);
	};

	return (
		<>
			<StyledTableRow
				key={data.id}
				onClick={handleClick}
				selected={rowSelected?.id === data.id}
			>
				<TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={(event) => {
							event.stopPropagation();
							setOpen(!open);
						}}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
					#
					{' '}
					{data.id}
				</TableCell>
				<TableCell align="right">{data.fullName}</TableCell>
				<TableCell align="center">{data.email}</TableCell>
				<TableCell align="right">{data.phone}</TableCell>
				<TableCell align="right">{formatDate(new Date(data.createDate), 'DD-MM-yyyy')}</TableCell>
				<TableCell align="right"><GetOrderStatus status={data.status} /></TableCell>
			</StyledTableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1, marginLeft: 6 }}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>{t('ID')}</TableCell>
										<TableCell>{t('name')}</TableCell>
										<TableCell align="center">{t('quantity')}</TableCell>
										<TableCell align="center">{t('price_with_tax')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.items.map((item) => (
										<TableRow key={item.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
											<TableCell component="th" scope="row">
												{item.id}
											</TableCell>
											<TableCell component="th" scope="row">
												{item?.product?.name}
											</TableCell>
											<TableCell component="th" scope="row" align="center">
												{item.quantity}
											</TableCell>
											<TableCell component="th" scope="row" align="center">
												{formatPrice(item.unitPrice * item.quantity)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};
