/* eslint-disable @typescript-eslint/no-explicit-any */
import { Either, Left, Right } from '@ajtec/either-typescript';
import { AxiosError } from 'axios';
import { IOrder } from '../../domain/entities/order';
import { OrderBackofficeApiImpl } from '../remote/order-api-impl';
import { IOrderBackofficeRepository } from '../../domain/repository/order-repository';

export class OrderBackofficeRepositoryImpl implements IOrderBackofficeRepository {
	orderApi = new OrderBackofficeApiImpl();

	async getOrders(): Promise<Either<AxiosError, IOrder[]>> {
		try {
			const resp = await this.orderApi.getOrders();
			return new Right(resp);
		} catch (error: any) {
			throw new Left(error);
		}
	}

	async changeOrderStatus(id: number, status: number): Promise<Either<AxiosError, number>> {
		try {
			const resp = await this.orderApi.changeOrderStatus(id, status);
			return new Right(resp);
		} catch (error: any) {
			throw new Left(error);
		}
	}
}
