/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import { reqGet } from 'common/data/remote/request';
import { ICharge, IChargeParams } from '../entities/charges.model';
import { ApiResponse } from 'common/data/entities/api-response';
import { Endpoints } from 'assets/Endpoints';
import { ErrorResponseBase } from 'common/domain/entities/error-response';
import { IUserData } from '../entities/users.model';
import { IChargeEventData } from '../entities/charge-event.model';

export class WallboxApiImpl {
	async getCharges(params: IChargeParams): Promise<ApiResponse<ICharge>> {
		try {
			const resp = await reqGet({
				url: Endpoints.WALLBOX_CHARGES.format(
					params.id,
					params?.initiator || '',
					params?.begin || '',
					params?.end || '',
					params?.page || '',
					params?.size || '',
				),
			});
			const data = resp.data as ApiResponse<ICharge>;
			return data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async getUsers(productId: number): Promise<ApiResponse<IUserData[]>> {
		try {
			const resp = await reqGet({
				url: Endpoints.USER_PRODUCTS_USERS.format(productId.toString()),
			});
			const data = resp.data as ApiResponse<IUserData[]>;
			return data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async getChargeEvents(
		{ chargeId, page = 1, size = 100 }:
		{chargeId: number, page?: number, size?: number },
	): Promise<ApiResponse<IChargeEventData>> {
		try {
			const resp = await reqGet({
				url: Endpoints.CHARGES_EVENTS_BY_ID.format(
					chargeId.toString(),
					page,
					size,
				),
			});
			return resp.data as ApiResponse<IChargeEventData>;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors?.[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}
}
