/* eslint-disable @typescript-eslint/no-explicit-any */
import { Either, Left, Right } from '@ajtec/either-typescript';
import { IOrderRequestParams } from '../../domain/entities/order';
import { IOrderRepository } from '../../domain/repository/order-repository';
import { OrderApiImpl } from '../remote/order-api-impl';
import { ApiResponse } from 'common/data/entities/api-response';
import { AxiosError } from 'axios';

export class COrderRepositoryImpl implements IOrderRepository {
	orderApi = new OrderApiImpl();

	async createOrder(params: IOrderRequestParams): Promise<Either<AxiosError, ApiResponse<string>>> {
		try {
			const order = await this.orderApi.createOrder(params);
			return new Right(order);
		} catch (error: any) {
			return new Left(error);
		}
	}
}
