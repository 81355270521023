/* eslint-disable import/no-extraneous-dependencies */
import {
	Box, Divider, Stack, useTheme,
} from '@mui/material';
import { IAlert } from 'features/client-login/domain/entities/alert';
import React from 'react';
import moment from 'moment';
import BodyText from '../body-text/BodyText';

interface Props {
	alert: IAlert
}

const AlertItem: React.FC<Props> = (props) => {
	const { alert } = props;
	const theme = useTheme();
	return (
		<Box>
			<Stack direction="row" justifyContent="space-between">
				<Box
					sx={{
						pt: '5px',
						pb: '5px',
						pl: '10px',
						pr: '10px',
						bgcolor: theme.palette.secondary.main,
						fontSize: '12px',
						fontWeight: 'bold',
						borderRadius: '5px',
					}}
					component="span"
				>
					{alert?.alertType?.name}
				</Box>
				<BodyText>{moment(alert.timestamp).format('DD/MM/yyyy HH:mm')}</BodyText>
			</Stack>
			<BodyText sx={{ mt: 1 }}>{alert?.name}</BodyText>
			<BodyText sx={{ mt: 1 }}>{alert?.description}</BodyText>
			<Divider sx={{ mt: 2, mb: 2 }} />
		</Box>
	);
};

export default AlertItem;
