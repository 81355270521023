/* eslint-disable @typescript-eslint/ban-ts-comment */
import AppLinks from 'assets/applinks.routes';
import Dimens from 'assets/dimens';
import { BodyText, DefaultButton, TitleText } from 'common/presentation/components';
import {
	Box,
	Stack,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { foundDeviceIllustrationOne, foundDeviceIllustrationTwo } from 'assets/find-your-iokee-icons';
import ConfettiExplosion from 'react-confetti-explosion';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import ProductItem from 'features/products/presentation/components/ProductItem';

const FoundDevices = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const [isExploding] = React.useState(true);
	const { foundProducts } = useFindYourIokee();

	const handleStoreClick = () => {
		navigate(AppLinks.STORE);
	};

	return (
		<Stack
			alignItems="center"
			spacing={{ md: 10, xs: 2 }}
			sx={{ mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE } }}
		>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={10}
			>
				{isMd && <img width="115px" src={foundDeviceIllustrationOne} alt="Found devices illustration" />}
				<Box>
					<TitleText
						textAlign="center"
						sx={{
							fontStyle: 'italic',
							fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
						}}
						dangerouslySetInnerHTML={{ __html: t('congratulations').toUpperCase() }}
					/>
					<BodyText
						textAlign="center"
						sx={{ fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE } }}
					>
						{t('list_of_product_fit_your_needs').toUpperCase()}
					</BodyText>
				</Box>
				{isMd && <img width="115px" src={foundDeviceIllustrationTwo} alt="Found devices illustration" />}
			</Stack>
			<Stack direction="row" spacing={4} alignItems="flex-end">
				{foundProducts?.length === 0 && <TitleText>{t('no_devices_found')}</TitleText>}
				{foundProducts?.length > 0 && foundProducts.map((el) => (
					<ProductItem
						key={el.product.id}
						// @ts-ignore
						productImage={el?.product?.attachment?.attachment?.url}
						title={el.product.name}
						price={el.product.price}
						handleSeeMoreClick={() => navigate(AppLinks.PRODUCT.formatMap({ productId: el.product.id }))}
					/>
				))}
				{isExploding && (
					<ConfettiExplosion
						particleCount={800}
						force={1.5}
						colors={[theme.palette.primary.main, theme.palette.textMain.main]}
						duration={2000}
					/>
				)}
			</Stack>
			<Stack direction="row" spacing={4}>
				<DefaultButton variant="outlined" onClick={handleStoreClick}>
					{t('go_to_store')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default FoundDevices;
