import {
	Avatar, Box, Stack, useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import Dimens from 'assets/dimens';
import ListItem from './ListItem';
import { ITariffDataset } from '../../domain/entities/tariff-dataset';
import { useTranslation } from 'react-i18next';

interface TariffPlanItemProps {
	tariffDataSet: ITariffDataset;
	r2Enabled: boolean;
	r3Enabled: boolean;
}

const TariffPriceItem: FC<TariffPlanItemProps> = (props) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const { tariffDataSet, r2Enabled, r3Enabled } = props;

	return (
		<Box sx={{ mt: 5 }}>
			<ListItem title={t('power_price')} value={`${tariffDataSet?.tariff?.powerPrice} €`} />
			<Stack direction="row" justifyContent="center" spacing={10} sx={{ mt: 2.5 }}>
				<Avatar sx={{
					bgcolor: theme.palette.secondary.main,
					height: Dimens.TARIFF_PRICE_ITEM_AVATAR_SIZE,
					width: Dimens.TARIFF_PRICE_ITEM_AVATAR_SIZE,
					color: theme.palette.secondary.contrastText,
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
				}}
				>
					R1
				</Avatar>
				{r2Enabled && (
					<Avatar sx={{
						bgcolor: theme.palette.secondary.main,
						height: Dimens.TARIFF_PRICE_ITEM_AVATAR_SIZE,
						width: Dimens.TARIFF_PRICE_ITEM_AVATAR_SIZE,
						color: theme.palette.secondary.contrastText,
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
					>
						R2
					</Avatar>
				)}
				{r3Enabled && (
					<Avatar sx={{
						bgcolor: theme.palette.secondary.main,
						height: Dimens.TARIFF_PRICE_ITEM_AVATAR_SIZE,
						width: Dimens.TARIFF_PRICE_ITEM_AVATAR_SIZE,
						color: theme.palette.secondary.contrastText,
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
					>
						R3
					</Avatar>
				)}
			</Stack>
			<ListItem title={t('empty_price')} value={`${tariffDataSet.tariff?.rates[0]?.price} €`} />
			{r2Enabled && <ListItem title={t('peak_price')} value={`${tariffDataSet.tariff?.rates[1]?.price} €`} />}
			{r3Enabled && <ListItem title={t('flooded_price')} value={`${tariffDataSet.tariff?.rates[2]?.price} €`} />}
		</Box>
	);
};

export default TariffPriceItem;
