import { TFunction } from 'i18next';

export const getRuleType = (id: number, t: TFunction) => {
	let type = '';
	switch (id) {
	case 1:
		type = t('add');
		break;
	case 2:
		type = t('remove');
		break;
	default:
		break;
	}

	return type;
};
