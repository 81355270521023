/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
declare global {
    interface String{
        format(...args:any):string;
        formatMap(args:any):string;
		capitalizeFIrstDigit():string;
    }
}

String.prototype.format = function format(...args:any) {
	return this.replace(/{([0-9]+)}/g, (match, index) => (typeof args[index] === 'undefined' ? match : args[index])); // Check if the argument is there
};

String.prototype.formatMap = function formatMap(args:any) {
	return this.replace(/:([a-zA-Z]+)(\/|)/g, (match) => {
		const key = match.replace(/[:\/]/g, '');
		// check if the argument is there
		return args[key] ? `${args[key]}/` : match;
	});
};

String.prototype.capitalizeFIrstDigit = function capitalizeFIrstDigit() {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

export {};
