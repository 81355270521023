import Dimens from 'assets/dimens';
import { Checked, UnChecked } from 'assets/icons';
import { BodyText } from 'common/presentation/components';
import {
	Checkbox, Stack, SvgIcon, SxProps, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { GraphType } from '../../domain/entities/energy-consumption-period';
import { useTranslation } from 'react-i18next';

interface Props {
	onClick: (value: GraphType) => void;
	value: GraphType.MONTH | GraphType.WEEK | GraphType.YEAR;
	containerSx?: SxProps;
}

const GraphPeriodCheckbox: React.FC<Props> = (props) => {
	const {
		onClick, containerSx, value,
	} = props;
	const [valueChecked, setValueChecked] = useState(value ?? GraphType.YEAR);
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const handleChange = (val: GraphType.MONTH | GraphType.WEEK | GraphType.YEAR) => {
		onClick.call(0, val);
		setValueChecked(val);
	};
	return (
		<Stack direction="row" alignItems="flex-end" sx={containerSx}>
			<BodyText sx={{
				fontSize: Dimens.BODY_TEXT_ML,
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
				color: valueChecked === GraphType.YEAR ? theme.palette.textLight.main : 'inherit',
				mt: { md: 5, xs: 1 },
			}}
			>
				{t('annual')}
			</BodyText>
			<Checkbox
				checked={valueChecked === GraphType.YEAR}
				onChange={() => handleChange(GraphType.YEAR)}
				icon={(
					<SvgIcon component="div">
						<UnChecked />
					</SvgIcon>
				)}
				checkedIcon={(
					<SvgIcon component="div">
						<Checked />
					</SvgIcon>
				)}
			/>
			<BodyText sx={{
				fontSize: Dimens.BODY_TEXT_ML,
				ml: 4,
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
				color: valueChecked === GraphType.MONTH ? theme.palette.textLight.main : 'inherit',
			}}
			>
				{t('monthly')}
			</BodyText>
			<Checkbox
				checked={valueChecked === GraphType.MONTH}
				onChange={() => handleChange(GraphType.MONTH)}
				icon={(
					<SvgIcon component="div">
						<UnChecked />
					</SvgIcon>
				)}
				checkedIcon={(
					<SvgIcon component="div">
						<Checked />
					</SvgIcon>
				)}
			/>
			<BodyText sx={{
				fontSize: Dimens.BODY_TEXT_ML,
				ml: 4,
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
				color: valueChecked === GraphType.WEEK ? theme.palette.textLight.main : 'inherit',
			}}
			>
				{t('weekly')}
			</BodyText>
			<Checkbox
				checked={valueChecked === GraphType.WEEK}
				onChange={() => handleChange(GraphType.WEEK)}
				icon={(
					<SvgIcon component="div">
						<UnChecked />
					</SvgIcon>
				)}
				checkedIcon={(
					<SvgIcon component="div">
						<Checked />
					</SvgIcon>
				)}
			/>
		</Stack>
	);
};

export default GraphPeriodCheckbox;
