/* eslint-disable consistent-return */
import Dimens from 'assets/dimens';
import { Close, Search } from '@mui/icons-material';
import {
	Box,
	Dialog,
	IconButton,
	Slide,
	Stack,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultInput from '../default-input/DefaultInput';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

const Transition = React.forwardRef((
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

interface Props {
	open: boolean
	handleClose: () => void
}

const SearchModal: React.FC<Props> = (props) => {
	const { open, handleClose } = props;
	const [searchText, setSearchText] = useState('');
	const [searchError, setSearchError] = useState('');
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const handleSearchClick = () => {
		if (!searchText) {
			return setSearchError(t('required'));
		}
		setSearchError('');
		navigate(`${AppLinks.SEARCH_RESULT_PAGE}?query=${searchText}`);
		handleClose();
	};

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={handleClose}
			TransitionComponent={Transition}
			sx={{ bgcolor: 'rgba(255, 254, 242, 0.8)' }}
		>
			<Box sx={{
				pt: Dimens.APP_BAR_MARGIN_BOTTOM,
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
			>
				<Stack direction="row" justifyContent="flex-end">
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Stack>
				<Stack sx={{ mt: Dimens.APP_BAR_MARGIN_BOTTOM }} alignItems="center">
					<Box sx={{
						width: {
							lg: '30%',
							xl: '30%',
							md: '40%',
							xs: '100%',
							sm: '100%',
						},
					}}
					>
						<DefaultInput
							InputProps={{
								startAdornment: (
									<IconButton onClick={handleSearchClick} sx={{ mr: 1 }}><Search /></IconButton>
								),
							}}
							placeholder={t('what_are_you_looking_for')}
							sx={{ fontStyle: 'italic' }}
							onChange={(e) => setSearchText(e.target.value)}
							error={!!searchError}
							helperText={searchError}
						/>
					</Box>
				</Stack>
			</Box>
		</Dialog>
	);
};

export default SearchModal;
