/* eslint-disable class-methods-use-this */
/* eslint-disable no-useless-catch */
import { storeGet } from 'common/data/remote/store-requests';
import { ICommunityRequest } from '../../domain/entities/community-request';
import { CommunityRequestApi } from './community-request-api';
import { Endpoints } from 'assets/Endpoints';
import { ApiResponse } from 'common/data/entities/api-response';

export class CommunityRequestApiImpl implements CommunityRequestApi {
	async getCommunityRequests(): Promise<ICommunityRequest[]> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_COMMUNITY_REQUESTS,
			});
			const data = resp.data as ApiResponse<ICommunityRequest[]>;
			return data.data;
		} catch (error) {
			throw error;
		}
	}
}
