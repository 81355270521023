import { TitleText } from 'common/presentation/components';
import {
	Box, Stack,
} from '@mui/material';
import React, { useState } from 'react';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import InstallationSteps from '../components/InstallationSteps';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { useStoreProducts } from '../provider/product-provider';
// import BannerQuizz from 'features/community/presentation/iokee-community/components/BannerQuizz';

const Setup = () => {
	const { t } = useTranslation('translations');
	const [activeIndex, setActiveIndex] = useState(0);
	const { productById } = useStoreProducts();

	useEffectCustom(() => {
		const interval = setInterval(() => {
			setActiveIndex((prev) => {
				let mPrev = prev;
				if (mPrev !== productById?.installationSteps?.length) {
					mPrev += 1;
					return mPrev;
				}
				return 0;
			});
		}, 6000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Box
			sx={{
				height: {
					md: Dimens.ABOUT_IOKEE_SECTION,
				},
				ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
		>
			<TitleText sx={{
				fontSize: {
					md: Dimens.TITEL_TEXT_M,
					xs: Dimens.TITLE_TEXT_M_MOBILE,
				},
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
			}}
			>
				{t('installation').toUpperCase()}
			</TitleText>
			<TitleText sx={{
				fontSize: { md: Dimens.BODY_TEXT_XL },
				fontWeight: Dimens.FONT_WEIGHT_LIGHT,
			}}
			>
				{t('install_and_setup').toUpperCase().format(productById?.installationSteps?.length)}
			</TitleText>
			<Stack rowGap={5}>
				{productById?.installationSteps.map((step, index) => (
					<InstallationSteps
						title={step.header}
						description={step.description}
						count={index + 1}
						isHighlighted={activeIndex + 1 === 1}
					/>
				))}
			</Stack>
			{/* <Box sx={{
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			>
				<BannerQuizz />
			</Box> */}
		</Box>
	);
};

export default Setup;
