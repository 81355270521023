import {
	Box, Stack,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import { BodyText, TitleText } from 'common/presentation/components';
import { mobileimage } from 'assets/images/user-stories';
import { useTranslation } from 'react-i18next';
import { appstore, playstore } from 'assets/images';
import DownloadAppButton from './components/DownloadAppButton';

const HeroSection = () => {
	const { t } = useTranslation('translations');
	return (
		<Box sx={{
			ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },

		}}
		>
			<Stack alignItems="center" direction={{ md: 'row', xs: 'column' }}>
				<Box>
					<TitleText sx={{
						fontStyle: 'italic',
						fontSize: {
							md: Dimens.TITLE_TEXT_L,
							xs: Dimens.TITLE_TEXT_L_MOBILE,

						},
					}}
					>
						{t('save_energy_with_iokee').toUpperCase()}
					</TitleText>
					<TitleText sx={{
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
						fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
					}}
					>
						{t('your_iokee_app').toUpperCase()}
					</TitleText>
					<BodyText size={Dimens.BODY_TEXT_M} sx={{ mt: 5 }}>
						{t('your_iokee_app_info')}
					</BodyText>
					<Stack
						direction={{ md: 'row', sm: 'row', xs: 'row' }}
						spacing={2}
						sx={{
							mt: 10,
							justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
						}}
						alignItems="flex-start"
					>
						<DownloadAppButton
							appStoreLink="https://apps.apple.com/pt/app/iokee/id6477700979"
							appStoreImage={appstore}
							downloadText={t('donwnload_on_the')}
							storeName="App Store"
						/>
						<DownloadAppButton
							appStoreLink="https://play.google.com/store/apps/details?id=io.iokee"
							appStoreImage={playstore}
							downloadText={t('get_it_on')}
							storeName="Google Play"
						/>
					</Stack>

				</Box>
				<Box sx={{
					mt: 5,
					width: {
						lg: '50%', md: '80%', sm: '50%', xs: '100%',
					},
				}}
				>
					<img src={mobileimage} style={{ width: '80%', height: 'auto' }} alt="Mobile Application" />
				</Box>

			</Stack>
		</Box>

	);
};

export default HeroSection;
