/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { KeyboardArrowDown } from '@mui/icons-material';
import {
	Button, Menu, MenuItem, useTheme,
} from '@mui/material';
import React from 'react';

interface Props<T> {
	options: T[];
	renderOption: (option: T) => React.ReactNode;
	value: T;
	renderOptionLabel: (option: T) => string;
	handleSelect: (option: T) => void;
}

const DefaultDropdown = <T extends unknown>(props: Props<T>) => {
	const {
		options, renderOptionLabel, renderOption, value, handleSelect,
	} = props;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const theme = useTheme();

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (option?: T) => {
		setAnchorEl(null);
		if (option) {
			handleSelect(option);
		}
	};

	return (
		<div>
			<Button
				id="dropdown-button"
				aria-controls={open ? 'sensor-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				variant="outlined"
				endIcon={<KeyboardArrowDown />}
				sx={{ mt: { md: 0, xs: 2 } }}
			>
				{renderOptionLabel(value)}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={() => handleClose()}
				MenuListProps={{
					'aria-labelledby': 'sensor-button',
				}}
			>
				{options.map((option) => (
					<MenuItem
						sx={{ bgcolor: value === option ? theme.palette.primary.main : undefined }}
						key={JSON.stringify(option)}
						onClick={() => handleClose(option)}
					>
						{renderOption(option)}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default DefaultDropdown;
