import Dimens from 'assets/dimens';
import { DefaultButton, TitleText } from 'common/presentation/components';
import { Stack, SvgIcon } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxCard from '../components/CheckboxCard';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { SolarPanelIcon } from 'assets/find-your-iokee-icons';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import { IQuestion } from '../../domain/question.model';

interface Props {
	onBackClick: () => void
	onContinueClick: () => void
	question?: IQuestion
}

const SolarPanelSection: React.FC<Props> = (props) => {
	const { onBackClick, onContinueClick, question } = props;
	const { t } = useTranslation('translations');
	const { getValue, handleValueChange, isAnyAnswerSelected } = useFindYourIokee();
	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 6 }}>
			<TitleText
				sx={{
					fontStyle: 'italic',
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
			>
				{question?.description}
			</TitleText>
			<Stack alignItems="center" justifyContent="flex-end">
				<SvgIcon sx={{ fontSize: { md: 100, xs: 80, sm: 80 } }} color="inherit" component="div" inheritViewBox>
					<SolarPanelIcon />
				</SvgIcon>
			</Stack>
			<Stack direction="row" spacing={4} alignItems="flex-end">
				{question?.answers.map((el) => (
					<CheckboxCard
						key={el.id}
						selected={getValue(question.id, el.id)}
						onClick={() => handleValueChange(question.id, el.id)}
						title={el.text.toUpperCase()}
					/>
				))}
			</Stack>
			<Stack direction="row" spacing={4}>
				<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={onBackClick}>
					{t('back')}
				</DefaultButton>
				<DefaultButton disabled={!isAnyAnswerSelected(question?.id)} variant="contained" endIcon={<ArrowForward />} onClick={onContinueClick}>
					{t('continue')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default SolarPanelSection;
