import React from 'react';
import Tariff from './Tariff';
import { TariffProvider } from './provider/tariff-provider';

const ClientTariff = () => (
	<TariffProvider>
		<Tariff />
	</TariffProvider>
);

export default ClientTariff;
