import React from 'react';
import { FindYourIokeeProvider } from './provider/find-your-iokee.provider';
import { Outlet } from 'react-router-dom';

const FindYourIokeeMain = () => (
	<main>
		<FindYourIokeeProvider>
			<Outlet />
		</FindYourIokeeProvider>
	</main>
);

export default FindYourIokeeMain;
