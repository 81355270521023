/* eslint-disable class-methods-use-this */
import { Endpoints } from 'assets/Endpoints';
import { LoginParams } from '../../domain/entities/login';
import { AuthResponseModel } from '../dto/login-response-model';
import { AuthApi } from './auth-api';
import { ApiResponse } from 'common/data/entities/api-response';
import { storePost } from 'common/data/remote/store-requests';

export class AuthApiImpl implements AuthApi {
	async login(params: LoginParams): Promise<AuthResponseModel> {
		try {
			const resp = await storePost({
				url: Endpoints.STORE_AUTH,
				data: params,
			});
			const response = resp.data as ApiResponse<AuthResponseModel>;
			return response.data;
		} catch (error) {
			throw new Error('Invalid');
		}
	}
}
