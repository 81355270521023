import { Box, useTheme } from '@mui/material';
import React from 'react';

export interface SpaceProps {
   width?: number;
   height?: number;
}

const Space = (props: SpaceProps) => {
	const theme = useTheme();
	const { width, height } = props;
	return <Box width={theme.spacing(width ?? 0)} height={theme.spacing(height ?? 0)} />;
};

export default Space;
