/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable consistent-return */
import { BodyText } from 'common/presentation/components';
import { Box, IconButton, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { useBackofficeProducts } from '../../providers/product.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatPrice } from 'utils/price.utils';
import { formatDate } from 'utils/date/date.utils';
import Dialog from 'common/presentation/components/dailog/Dailog';
import { ProductBackoffice } from '../domain/entities/backoffice-product';
import Dimens from 'assets/dimens';

const BackofficeProductsPage = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const {
		getProducts,
		products,
		deleteProduct,
		productsState,
	} = useBackofficeProducts();
	const [selectedProducts, setSelectedProducts] = useState<DataTableValue | null>(null);
	const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

	const handleAddClick = () => {
		navigate(AppLinks.BACKOFFICE_ADD_PRODUCT);
	};

	useEffectCustom(() => {
		getProducts();
	}, []);

	const handleEditClick = () => navigate(
		AppLinks.BACKOFFICE_EDIT_PRODUCT.formatMap({ productId: selectedProducts?.id }),
	);

	const handleDeleteClick = () => {
		deleteProduct(selectedProducts?.id);
	};

	const getNameAndDescription = (product: ProductBackoffice) => (
		<Box>
			<BodyText sx={{ fontSize: Dimens.BODY_TEXT_M }}>{product.name}</BodyText>
			<BodyText
				sx={{
					fontSize: Dimens.BODY_TEXT_S,
				}}
			>
				{product.description}
			</BodyText>
		</Box>
	);

	// @ts-ignore
	const imageBodyTemplate = (product: ProductBackoffice) => <img src={product?.attachment?.attachment?.url} alt={product.name} className="w-6rem shadow-2 border-round" />;

	return (
		<Box>
			<Dialog
				title={t('alert')}
				description={t('confirm_delete_info')}
				open={confirmDeleteDialog}
				handleClose={() => setConfirmDeleteDialog(false)}
				primaryButtonText={t('confirm')}
				primaryButtonAction={handleDeleteClick}
				secondaryButtonText={t('cancel')}
				secondaryButtonAction={() => setConfirmDeleteDialog(false)}
			/>
			<Stack direction="row" spacing={2}>
				<BodyText>
					{t('products')}
				</BodyText>
				<Box sx={{ flexGrow: 1 }} />
				<IconButton onClick={handleAddClick}>
					<Add />
				</IconButton>
				{selectedProducts && (
					<>
						<IconButton onClick={handleEditClick}>
							<Edit />
						</IconButton>
						<IconButton onClick={() => setConfirmDeleteDialog(true)}>
							<Delete />
						</IconButton>
					</>
				)}
			</Stack>
			<Box className="card" mt={6}>
				<DataTable
					value={products}
					size="normal"
					selection={selectedProducts}
					selectionMode="single"
					onSelectionChange={(e) => setSelectedProducts(e.value)}
					rowClassName={(rowData) => {
						if (rowData.id === selectedProducts?.id) return 'bg-cyan-100';
					}}
					loading={productsState.loading}
					dataKey="id"
					tableStyle={{ minWidth: '50rem' }}
				>
					<Column field="id" header="ID" align="center" />
					<Column field="name" header={t('name')} style={{ width: '30%' }} align="left" body={(options) => getNameAndDescription(options)} />
					<Column field="price" header={t('price')} align="center" body={(options) => formatPrice(options.price)} />
					<Column field="category.name" header={t('category')} align="center" />
					<Column field="attachments" header={t('image')} align="center" body={(options) => imageBodyTemplate(options)} />
					<Column field="isHighlighted" header={t('main')} align="center" />
					<Column field="createDate" header={t('created_on')} align="center" body={(options) => formatDate(options.createDate, 'DD-MM-yyyy')} />
				</DataTable>
			</Box>
		</Box>
	);
};

export default BackofficeProductsPage;
