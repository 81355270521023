import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	title: string
	image:	string
}

const CartSummeryItem:React.FC<Props> = (props) => {
	const { title, image } = props;
	const { t } = useTranslation('translations');
	return (
		<Stack direction="row">
			<img src={image} width="20%" alt="Product" />
			<Box sx={{ ml: 2 }}>
				<BodyText
					sx={{
						fontSize: {
							md: Dimens.BODY_TEXT_L,
							xs: Dimens.BODY_TEXT_L_MOBILE,
						},
					}}
					dangerouslySetInnerHTML={{ __html: title }}
				/>
				<BodyText sx={{
					fontWeight: Dimens.FONT_WEIGHT_LIGHTER,
					fontSize: { md: Dimens.BUTTON_TEXT, xs: Dimens.BUTTON_TEXT_MOBILE },
				}}
				>
					{t('three_years_warranty')}
				</BodyText>
			</Box>
		</Stack>
	);
};

export default CartSummeryItem;
