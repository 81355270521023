import {
	Box,
	Stack,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React from 'react';
import CategoryItem from '../category-item/CategoryItem';
import Dimens from 'assets/dimens';
import { BodyText, SizedBox } from 'common/presentation/components';
import './user-story.css';

interface Props {
	date: string
	title: string
	tag: string
	image: string
	isHorizantal?: boolean
	imageWidth?: string
	imageHeight?: string
	onClick: () => void
}

const UserStoryCard: React.FC<Props> = (props) => {
	const {
		date,
		title,
		tag,
		image,
		isHorizantal,
		imageWidth,
		imageHeight,
		onClick,
	} = props;
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Stack
			direction={{ md: isHorizantal ? 'row' : 'column', sm: 'column-reverse', xs: 'column-reverse' }}
			alignItems="center"
			onClick={onClick}
			className="story-container"
			sx={{
				borderStyle: 'solid',
				borderWidth: '1.5px',
				borderColor: theme.palette.textMain.main,
				borderTopLeftRadius: Dimens.CARD_BORDER_RADIUS,
				borderBottomRightRadius: Dimens.CARD_BORDER_RADIUS,
				pl: Dimens.CARD_PADDING,
				pr: Dimens.CARD_PADDING,
				pt: Dimens.CARD_PADDING,
				pb: Dimens.CARD_PADDING,
				height: '100%',
				cursor: 'pointer',
				transition: 'border-color 0.5s ease',
				'&:hover': {
					borderColor: theme.palette.primary.main,
					boxShadow: '2px 2px 4px 0px rgba(77, 255, 255, 0.6)',
				},
			}}
		>
			<Box component="div">
				<CategoryItem
					title={tag}
					tagColor={theme.palette.textMain.main}
					dateColor={theme.palette.textMain.main}
					date={date}
				/>
				<BodyText
					size={Dimens.BODY_TEXT_L}
					sx={{ width: '80%', color: theme.palette.textMain.main, fontWeight: Dimens.FONT_WEIGHT_BOLD }}
				>
					&quot;
					{title}
					&quot;
				</BodyText>
			</Box>
			{(!isHorizantal) && <SizedBox height={4} />}
			{(isHorizantal && !isMd) && <SizedBox height={4} />}
			<img
				src={image}
				height={imageHeight ?? '50%'}
				width={imageWidth ?? '100%'}
				style={{ borderRadius: Dimens.CARD_BORDER_RADIUS }}
				alt="User stories"
			/>
		</Stack>
	);
};

export default UserStoryCard;
