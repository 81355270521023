import React from 'react';
import {
	Box, CssBaseline, useTheme,
} from '@mui/material';
import {
	AppFooter, Appbar, LanguageSelector, ScrollToTop,
} from 'common/presentation/components';
import { Outlet } from 'react-router-dom';

import type {} from '@mui/lab/themeAugmentation';
import ErrorDialog from './common/presentation/components/error-dailog/ErrorDailog';
import Toast from './common/presentation/components/toast/Toast';

const Main = () => {
	const { mixins, spacing } = useTheme();
	return (
		<>
			<ScrollToTop />
			<ErrorDialog />
			<Toast />
			<Box
				boxShadow={2}
				sx={{
					display: { xs: 'flex', md: 'none' },
					position: 'fixed',
					bottom: '5%',
					left: spacing(2),
					borderRadius: 31 / 2,
					zIndex: 1000,
				}}
			>
				<LanguageSelector />
			</Box>
			<CssBaseline />
			<Box>
				<Appbar />
				<Box sx={{ pt: `${parseInt(String(mixins.toolbar.minHeight), 10) + 70}px` }} component="main">
					<Outlet />
				</Box>
				<AppFooter />
			</Box>
		</>
	);
};

export default Main;
