import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { AuthResponse } from 'features/backoffice/features/auth/domain/entities/login';
import { Endpoints } from 'assets/Endpoints';

const STORE_BASE_URL = process.env.REACT_APP_STORE_BASE_URL;

const axiosConfig = {
	baseURL: STORE_BASE_URL,
};

export const $axios = axios.create(axiosConfig);

const UNAUTORIZED = 401;
const STATUS_SUCCESS = 200;

const storeRequest = async (config: AxiosRequestConfig) => {
	const user = LocalStorageWrapper.get<AuthResponse>(LocalStorageKeys.BACKOFFICE_USER);
	// const lang = LocalStorageWrapper.get<string>(LocalStorageKeys.LOCALE)?.substring(0, 2);
	const bearerToken = user?.authToken;
	const refreshToken = user?.refreshToken;

	config.headers = { // eslint-disable-line no-param-reassign
		...config.headers,
		'Access-Control-Allow-Origin': STORE_BASE_URL,
		// Lang: lang ?? i18next.language.substring(0, 2),
		Accept: 'application/json',
		...(bearerToken
			? {
				Authorization: `Bearer ${bearerToken}`,
			}
			: {}),
	};

	/** CHECK IF THE USER IS UNAUTHORIZED AND REFRESHE THE TOKEN */
	/** AXIOS RESPONSE INTERCEPTOR */
	$axios.interceptors.response.use(
		(response) => response,
		async (error: AxiosError) => {
			const originalRequest = error.config;
			if (error?.response?.status === UNAUTORIZED) {
				try {
					const resp = await $axios({
						url: Endpoints.STORE_REFRESH_TOKEN,
						method: 'POST',
						data: {
							refreshToken,
							authenticationToken: bearerToken,
						},
						headers: {
							...config.headers,
							Accept: 'application/json',
							Authorization: `Bearer ${bearerToken}`,
						},
					});
					if (resp.status !== STATUS_SUCCESS) {
						LocalStorageWrapper.remove(LocalStorageKeys.BACKOFFICE_USER);
						window.location.reload();
						return null;
					}
					/**
						If the token is refreshed successfully then retry the original request with the new
						authentication token
				 	*/
					if (resp.status === 200) {
						LocalStorageWrapper.set(LocalStorageKeys.BACKOFFICE_USER, resp.data.data);
						const returnValue = await $axios({
							...originalRequest,
							headers: {
								...originalRequest?.headers,
								Authorization: `Bearer ${resp.data.data.authenticationToken}`,
							},
						});
						return returnValue;
					}
				} catch (err) {
					LocalStorageWrapper.remove(LocalStorageKeys.BACKOFFICE_USER);
					window.location.reload();
				}
			}

			throw error;
		},
	);

	const returnValue = await $axios({ ...config });
	return returnValue;
};

export const storeGet = async (config: AxiosRequestConfig) => storeRequest({
	method: 'GET',
	...config,
});

export const storePost = async (config: AxiosRequestConfig) => storeRequest({
	method: 'POST',
	...config,
});
export const storePatch = async (config: AxiosRequestConfig) => storeRequest({
	method: 'PATCH',
	...config,
});
export const storePut = async (config: AxiosRequestConfig) => storeRequest({
	method: 'PUT',
	...config,
});

export const storeDelete = async (config: AxiosRequestConfig) => storeRequest({
	method: 'DELETE',
	...config,
});
