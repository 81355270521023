/* eslint-disable max-len */
/* eslint-disable react/no-this-in-sfc */
import AppLinks from 'assets/applinks.routes';
import ClientBackButton from 'common/presentation/components/client-back-button/ClientBackButton';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GraphType, HistoryPeriod, IEnergyConsumptionPeriod } from '../domain/entities/energy-consumption-period';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import useClientBilling from './provider/client-billing.provider';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { IDevice } from 'features/client-device-list/entities/device';
import EnergyConsumptionGraph from './components/EnergyConsumptionGraph';
import GraphPeriodCheckbox from './components/GraphPeriodCheckbox';
import DateLabel from './components/DateLabel';
import { formatDate, getMonthNumber, getYearNumber } from 'utils/date/date.utils';
import { BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';

const ClientBillingPage = () => {
	const navigate = useNavigate();
	const {
		getEnergyConsumptionYear,
		getEnergyConsumptionMonth,
		getEnergyConsumptionWeek,
		energyConsumptionPeriod,
		years,
		currentYear,
		setCurrentYear,
		prevYearToCompare,
		setYearToCompare,
		months,
		currentMonth,
		prevMonthToCompare,
		setPrevMonthToCompare,
		setCurrentMonth,
		weeks,
		setCurrentWeek,
		currentWeek,
		comparisonData,
		setComparisonData,
		getEnergyConsumptionForComparison,
	} = useClientBilling();
	const device = LocalStorageWrapper.get(LocalStorageKeys.DEVICE) as IDevice;
	const { t } = useTranslation('translations');
	const [graphType, setGraphType] = React.useState(GraphType.YEAR);

	const getGraphData = () => {
		switch (graphType) {
		case GraphType.YEAR:
			getEnergyConsumptionYear({ year: currentYear, userProductId: device.userProductID });
			setComparisonData([] as IEnergyConsumptionPeriod[]);
			break;
		case GraphType.MONTH:
			getEnergyConsumptionMonth({
				month: getMonthNumber(currentMonth?.startDate ?? new Date()),
				year: getYearNumber(currentMonth?.startDate ?? new Date()),
				userProductId:
				device.userProductID,
			});
			break;
		case GraphType.WEEK:
			getEnergyConsumptionWeek({
				fromDate: currentWeek?.startDate?.toISOString(),
				toDate: currentWeek?.endDate?.toISOString(),
				userProductId: device.userProductID,
			});
			break;
		default:
			break;
		}
	};

	useEffectCustom(() => {
		getGraphData();
	}, [graphType]);

	const handleGoBack = () => {
		navigate(AppLinks.CLIENT_AREA_DEVICE_DETAIL);
	};
	const handleGraphTypeChange = (value: GraphType) => {
		setGraphType(value);
	};

	const handleWeekClick = (week: HistoryPeriod) => {
		setCurrentWeek(week);
		setComparisonData([] as IEnergyConsumptionPeriod[]);
		getEnergyConsumptionWeek({
			fromDate: week?.startDate?.toISOString(),
			toDate: week?.endDate?.toISOString(),
			userProductId: device.userProductID,
		});
	};

	const handleYearClick = (year: number) => {
		if (!prevYearToCompare) {
			getEnergyConsumptionForComparison({
				year,
				userProductId: device.userProductID,
			}, GraphType.YEAR);
			setYearToCompare(year);
			return;
		}
		setYearToCompare(null);
		setCurrentYear(year);
		getEnergyConsumptionYear({ year, userProductId: device.userProductID });
	};

	const handleMonthClick = (month: HistoryPeriod) => {
		if (Object.keys(prevMonthToCompare).length === 0) {
			getEnergyConsumptionForComparison({
				month: getMonthNumber(month.startDate),
				year: getYearNumber(month.startDate),
				userProductId: device.userProductID,
			}, GraphType.MONTH);
			return setPrevMonthToCompare(month);
		}
		setPrevMonthToCompare({} as HistoryPeriod);
		setCurrentMonth(month);
		getEnergyConsumptionMonth({
			month: getMonthNumber(month.startDate),
			year: getYearNumber(month.startDate),
			userProductId: device.userProductID,
		});
		return null;
	};

	return (
		<Box>
			<ClientBackButton onBackClick={handleGoBack} />
			<BodyText
				dangerouslySetInnerHTML={{ __html: t('consult_billing_html').format(device.name) }}
				sx={{ fontSize: Dimens.BODY_TEXT_XL, fontWeight: Dimens.FONT_WEIGHT_LIGHT, mt: 3 }}
			/>
			<GraphPeriodCheckbox
				value={graphType}
				onClick={handleGraphTypeChange}
			/>
			{(graphType !== GraphType.WEEK) && (
				<BodyText sx={{ fontSize: Dimens.BODY_TEXT_S }}>{t('select_multiple_for_comparison')}</BodyText>
			)}
			<Stack
				direction="row"
				justifyContent="flex-start"
				useFlexGap
				spacing={2}
				sx={{
					mt: 2,
					overflowX: 'scroll',
					'&::-webkit-scrollbar': { display: 'none' },
					position: 'relative',
				}}
			>
				{graphType === GraphType.YEAR && years.length > 0 && years.map((year) => (
					<DateLabel
						key={year}
						title={year}
						selected={year === currentYear}
						highlighted={year === prevYearToCompare}
						onClick={() => handleYearClick(year)}
					/>
				))}
				{graphType === GraphType.MONTH && months.length > 0 && months.map((month) => (
					<DateLabel
						key={month.id}
						title={formatDate(month.startDate as Date, 'MMM yyyy')}
						selected={month.id === currentMonth?.id}
						highlighted={month.id === prevMonthToCompare?.id}
						onClick={() => handleMonthClick(month)}
					/>
				))}
				{graphType === GraphType.WEEK && weeks.length > 0 && weeks.map((week) => (
					<DateLabel
						key={week.id}
						title={
							`${formatDate(week.startDate as Date, 'DD/MM/yyyy')} 
							- ${formatDate(week.endDate as Date, 'DD/MM/yyyy')}`
						}
						selected={week.id === currentWeek.id}
						onClick={() => handleWeekClick(week)}
					/>
				))}
			</Stack>
			{energyConsumptionPeriod?.length > 0 && (
				<EnergyConsumptionGraph
					graphData={energyConsumptionPeriod}
					comparisonData={comparisonData}
				/>
			)}
		</Box>
	);
};

export default ClientBillingPage;
