/* eslint-disable no-param-reassign */
import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { BackofficeProductRequest, ProductBackoffice } from '../products/domain/entities/backoffice-product';
import { useTranslation } from 'react-i18next';
import { ProductBackofficeRepositoryImpl } from '../products/data/repository/product-repository-impl';
import { AddProductBackoffice } from '../products/domain/usecases/add-product';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { UploadProductAttachment } from '../products/domain/usecases/upload-product-attachment';
import { IAttachmentResp } from '../../common/domain/entities/attachment';
import { GetProducts } from '../products/domain/usecases/get-products';
import { DeleteProductById } from '../products/domain/usecases/delete-product';
import { GetProductById } from '../products/domain/usecases/get-product-by-id';
import { FormikHelpers } from 'formik';
import { UpdateProductBackoffice } from '../products/domain/usecases/update-product';
import { EmptyProduct } from '../products/domain/entities/empty-product-values';

interface FetchState {
	loading: boolean;
	error: string;
}

type Props = {
	formValues: BackofficeProductRequest
	setFormValues: React.Dispatch<React.SetStateAction<BackofficeProductRequest>>
	submit: (
		values: BackofficeProductRequest,
		formikHelpers: FormikHelpers<BackofficeProductRequest>,
		id?: string
	) => void
	uploadImage: (params: File) => Promise<IAttachmentResp | false>
	getProducts: () => void
	products: ProductBackoffice[]
	deleteProduct: (id: string) => void
	getProductById: (id: string) => void
	productsState: FetchState
}

export const BackofficeProductContext = createContext({} as Props);

export const BackofficeProductProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const [formValues, setFormValues] = useState<BackofficeProductRequest>(EmptyProduct);
	const [products, setProducts] = useState([] as ProductBackoffice[]);
	const [productsState, setProductsState] = useState<FetchState>({ loading: false, error: '' });
	const { addDialog } = useFeedback();

	const productRepositoryImpl = new ProductBackofficeRepositoryImpl();

	const submit = async (
		values: BackofficeProductRequest,
		formikHelpers: FormikHelpers<BackofficeProductRequest>,
		id?: string,
	) => {
		formikHelpers.setSubmitting(true);
		if (id) {
			const updateProductUsecase = new UpdateProductBackoffice(productRepositoryImpl);
			const updateProductResult = await updateProductUsecase.call(values, id);
			if (updateProductResult.isRight()) {
				addDialog({
					title: t('success'),
					message: t('success'),
					error: false,
				});
				formikHelpers.setSubmitting(false);
			} else {
				addDialog({
					title: updateProductResult.error.title,
					message: updateProductResult.error.message,
					error: true,
				});
				formikHelpers.setSubmitting(false);
			}
		} else {
			const addProductUsecase = new AddProductBackoffice(productRepositoryImpl);
			const addProductResult = await addProductUsecase.call(values);
			if (addProductResult.isRight()) {
				navigate(AppLinks.BACKOFFICE_DASHBOARD);
				formikHelpers.setSubmitting(false);
			} else {
				addDialog({
					title: addProductResult.error.title,
					message: addProductResult.error.message,
					error: true,
				});
				formikHelpers.setSubmitting(false);
			}
		}
	};

	const uploadImage = async (params: File): Promise<IAttachmentResp | false> => {
		const uploadUsecase = new UploadProductAttachment(productRepositoryImpl);
		const uploadResult = await uploadUsecase.call(params);
		if (uploadResult.isRight()) {
			return uploadResult.value;
		}
		addDialog({
			error: true,
			title: t('error'),
			message: t('an_error_occurred'),
		});
		return false;
	};

	const getProducts = async () => {
		setProductsState({ loading: true, error: '' });
		const productUsecase = new GetProducts(productRepositoryImpl);
		const productsResult = await productUsecase.call();
		if (productsResult.isRight()) {
			setProducts(productsResult.value);
			setProductsState({ loading: false, error: '' });
		} else {
			addDialog({
				title: '',
				message: '',
				error: true,
			});
			setProductsState({ loading: false, error: 'An Error Occured' });
		}
	};

	const getProductById = async (id: string) => {
		const productByIdUsecase = new GetProductById(productRepositoryImpl);
		const productResult = await productByIdUsecase.call(id);
		if (productResult.isRight()) {
			setFormValues({
				...formValues,
				name: productResult.value.name,
				description: productResult.value.description,
				price: productResult.value.price,
				warrantyMonths: (productResult.value.warrantyMonths).toString(),
				storageNote: productResult.value.storageNote,
				howItWorks: productResult.value.howItWorks,
				categoryId: productResult.value?.category?.id,
				isHighlighted: productResult.value.isHighlighted,
				attachments: productResult.value?.attachments?.map(((el) => ({
					attachmentId: el.id?.toString(),
					isMain: el.isMain,
					url: el.attachment.url,
					id: el.id,
				}))),
				installationSteps: productResult.value?.installationSteps?.map((el) => ({
					id: el.id,
					header: el.header,
					description: el.description,
					stepOrder: el.stepOrder,
					attachmentId: el.attachment?.id?.toString(),
					url: el.attachment?.url,
				})),
				specifications: productResult.value?.specifications?.map((el) => ({
					id: el.id,
					header: el.header,
					description: el.description,
					attachmentId: el.attachment?.id?.toString(),
					url: el.attachment?.url,
				})),
			});
		} else {
			addDialog({
				error: true,
				title: '',
				message: '',
			});
		}
	};

	const deleteProduct = async (id: string) => {
		const deleteProductUsecase = new DeleteProductById(productRepositoryImpl);
		const deleteProductResult = await deleteProductUsecase.call(id);
		if (deleteProductResult.isRight()) {
			addDialog({
				title: '',
				message: '',
				error: false,
			});
			getProducts();
		} else {
			addDialog({
				title: deleteProductResult.error.title,
				message: deleteProductResult.error.message,
				error: true,
			});
		}
	};

	const value = useMemo(() => ({
		formValues,
		setFormValues,
		submit,
		uploadImage,
		products,
		getProducts,
		deleteProduct,
		getProductById,
		productsState,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [formValues, products, productsState]);

	return (
		<BackofficeProductContext.Provider value={value}>{children}</BackofficeProductContext.Provider>
	);
};

export function useBackofficeProducts() {
	return useContext(BackofficeProductContext);
}
