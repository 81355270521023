/* eslint-disable @typescript-eslint/no-explicit-any */
import { Either, Left, Right } from '@ajtec/either-typescript';
import { ICommunityRequestRepository } from '../../domain/repository/community-requests-repository';
import { CommunityRequestApiImpl } from '../remote/community-request-api-impl';
import { AxiosError } from 'axios';
import { ICommunityRequest } from '../../domain/entities/community-request';

export class CommunityRequestRepositoryImpl implements ICommunityRequestRepository {
	communityReqApi = new CommunityRequestApiImpl();

	async getCommunityRequests(): Promise<Either<AxiosError, ICommunityRequest[]>> {
		try {
			const communityResponse = await this.communityReqApi.getCommunityRequests();
			return new Right(communityResponse);
		} catch (error: any) {
			throw new Left(error);
		}
	}
}
