import React from 'react';
import {
	Box, Stack,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { mobileimage1, mobileimage2, mobileimage3 } from 'assets/images/user-stories';
import StoryCard from './components/StoryCard';
import { useTranslation } from 'react-i18next';

const CardsSection = () => {
	const { t } = useTranslation('translations');

	return (
		<Box sx={{
			ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mt: {
				md: Dimens.CLIENT_AREA_DEVICE_OPTION_CARD_WIDTH,
				xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE,
			},
		}}
		>
			<Stack>
				<StoryCard
					imageSrc={mobileimage1}
					titleKey={t('personalize_your_monthly')}
					textKey="personlize_your_monthly_description"
					direction="row"
					stackWidth="48%"
					imageSize="70%"

				/>

				<StoryCard
					imageSrc={mobileimage2}
					titleKey={t('protect_your_equipment')}
					textKey={t('protect_your_equipment_details')}
					direction="row-reverse"
					imageSize="70%"
					stackWidth="48%"
					textAlign="right"
				/>
				<StoryCard
					imageSrc={mobileimage3}
					titleKey={t('always_be_up_to_date')}
					textKey={t('always_be_up_to_date_details')}
					direction="row"
					stackWidth="48%"
					imageSize="70%"
				/>
			</Stack>
		</Box>
	);
};
export default CardsSection;
