// import { bulbOn } from 'assets/images';
import { Stack, useTheme } from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import { BodyText, SizedBox, TitleText } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import './CheckoutSuccess.css';

const CheckoutSuccessPage = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<Stack
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{
				overflow: 'hidden',
			}}
		>
			<div className="bulb">
				<span />
			</div>
			{/* <img src={bulbOn} alt="Purchase success" /> */}
			<SizedBox height={5} />
			<TitleText
				sx={{
					fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
					fontWeight: Dimens.FONT_WEIGHT_NORMAL,
				}}
				color={theme.palette.textMain.main}
			>
				{t('thank_you').toUpperCase()}
			</TitleText>
			<BodyText
				textAlign="center"
				sx={{
					fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE },
					fontWeight: Dimens.FONT_WEIGHT_LIGHT,
					mt: 1,
					fontStyle: 'italic',
				}}
			>
				{t('order_placed_successfully').toUpperCase()}
			</BodyText>
		</Stack>
	);
};

export default CheckoutSuccessPage;
