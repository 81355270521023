import { AxiosError } from 'axios';
import { IProductBackofficeRepository } from '../repository/product.repository';
import { IAttachmentResp } from 'features/backoffice/common/domain/entities/attachment';
import { Either } from '@ajtec/either-typescript';

export class UploadProductAttachment {
	repository: IProductBackofficeRepository;

	constructor(productRepository: IProductBackofficeRepository) {
		this.repository = productRepository;
	}

	call(params: File): Promise<Either<AxiosError, IAttachmentResp>> {
		return this.repository.uploadProductAttachments(params);
	}
}
