import { Either } from '@ajtec/either-typescript';
import { IProductStoreRepository } from '../repository/product-repository';
import { AxiosError } from 'axios';
import { IStoreProduct } from '../entities/product';

export class GetStoreProductById {
	repository: IProductStoreRepository;

	constructor(productRepository: IProductStoreRepository) {
		this.repository = productRepository;
	}

	call(id: string): Promise<Either<AxiosError, IStoreProduct>> {
		return this.repository.getProductById(id);
	}
}
