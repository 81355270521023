export interface IEnergyConsumptionPeriod {
	consumption: number;
    consumptionAmount: number;
    barDisplay: string
}

export interface EnergyConsumptionYearParams {
    userProductId: number;
    year: number;
}
export interface EnergyConsumptionMonthParams {
    userProductId: number;
    year: number;
    month: number;
}

export interface EnergyConsumptionWeekParams {
    userProductId: number;
    fromDate: string;
    toDate: string;
}
export enum HistoryPeriodType {
  Week,
  Month
}

export interface HistoryPeriod {
    id: number;
    name: string;
    startDate: Date;
    endDate: Date;
    type: HistoryPeriodType;
}
export enum GraphType {
    YEAR = 'year',
    MONTH = 'month',
    WEEK = 'week'
}
