/* eslint-disable class-methods-use-this */
import { ApiResponse } from 'common/data/entities/api-response';
import { reqGet, reqPost, reqPut } from 'common/data/remote/request';
import { Endpoints } from 'assets/Endpoints';
import { UserModel } from '../dto/user-model';
import { LoginApi } from './login-api';
import { LoginParams } from '../../domain/usecases/login';
import { ALertModal } from '../dto/alert-model';

export class LoginApiImpl implements LoginApi {
	async login(params: LoginParams): Promise<UserModel> {
		try {
			const resp = await reqPut({
				url: Endpoints.LOGIN,
				data: {
					...params,
					osUniqueHash: 'dskadkjshakjdaks',
					deviceTypeID: 1,
					appID: 1,
					platformID: 1,
				},
			});
			const data = resp.data as ApiResponse<UserModel>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async getAlerts(): Promise<ALertModal[]> {
		try {
			const resp = await reqGet({
				url: Endpoints.ALERTS,
			});
			const data = resp.data as ApiResponse<ALertModal[]>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async forgotPassword(email: string): Promise<string> {
		try {
			const resp = await reqPost({
				url: Endpoints.FORGOT_PASSWORD,
				data: {
					email,
				},
			});
			const data = resp.data as ApiResponse<void>;
			return data.message;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
