import Dimens from 'assets/dimens';
import {
	Box, Stack,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import CommunityFilters from './components/CommunityFilters';
import useCommunity from '../provider/community.provider';
import i18n from 'assets/translations';
import CardArticle from './components/CardArticle';
// import BannerQuizz from './components/BannerQuizz';

const CommentList = () => {
	const { getArticles, articles } = useCommunity();
	const navigate = useNavigate();
	const lang = i18n.language;

	const handleNavigate = (id: number) => {
		navigate(AppLinks.COMMUNITY_DETAIL.formatMap({ id }));
	};

	useEffect(() => {
		getArticles(lang);
	}, [lang, getArticles]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
			}}
		>
			<CommunityFilters />
			<Stack
				direction="row"
				flexWrap="wrap"
				useFlexGap
				spacing={3}
				sx={{ mt: Dimens.FOOTER_PADDING_VERTICAL }}
			>
				{articles?.map((el) => (
					<CardArticle article={el} onNavigate={handleNavigate} />
				))}
			</Stack>

			{/* <Box sx={{ mt: Dimens.COMMUNITY_CARD_QUIZZ_SPACE }}>
				<BannerQuizz />
			</Box> */}

		</Box>
	);
};

export default CommentList;
