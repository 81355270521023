/* eslint-disable @typescript-eslint/no-explicit-any */
import AppLinks from 'assets/applinks.routes';
import {
	Breadcrumbs, Link, LinkProps, Typography,
} from '@mui/material';
import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const ClientAreaBreadCrumb = () => {
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x) => x);

	const { t } = useTranslation('translations');

	const breadcrumbNameMap: { [key: string]: string } = {
		'/client-area/cliente/devices/device-detail': t('dashboard'),
		'/client-area/cliente/devices/device-detail/billing': t('billing'),
		'/client-area/cliente/devices/device-detail/tariff': t('tariff'),
	};

	return (
		<Breadcrumbs aria-label="breadcrumb" sx={{ mb: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL_MOBILE }}>
			<LinkRouter underline="hover" color="inherit" to={AppLinks.CLIENT_AREA_DEVICE_LIST}>
				{t('your_devices')}
			</LinkRouter>
			{pathnames.map((value, index) => {
				const last = index === pathnames.length - 1;
				const to = `/${pathnames.slice(0, index + 1).join('/')}`;
				const startIndex = index > 2;

				return last ? (
					<Typography color="inherit" fontWeight="bold" key={to}>
						{breadcrumbNameMap[to]}
					</Typography>
				) : (
					startIndex && (
						<LinkRouter underline="hover" color="inherit" to={to} key={to}>
							{breadcrumbNameMap[to]}
						</LinkRouter>
					)
				);
			})}
		</Breadcrumbs>
	);
};

export default ClientAreaBreadCrumb;
