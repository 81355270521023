import Dimens from 'assets/dimens';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import { BodyText } from 'common/presentation/components';

interface Props {
	title: string | number;
	onClick: () => void;
	highlighted?: boolean;
	selected?: boolean;
}

const DateLabel: React.FC<Props> = (props) => {
	const {
		title, onClick, highlighted, selected,
	} = props;
	const theme = useTheme();

	const getBackgroundColor = () => {
		if (selected) {
			return theme.palette.primary.main;
		}
		if (highlighted) {
			return theme.palette.secondary.main;
		}
		return 'transparent';
	};

	return (
		<Box
			onClick={onClick}
			sx={{
				pt: 1,
				pb: 1,
				pl: 1.5,
				pr: 1.5,
				bgcolor: getBackgroundColor(),
				border: `1px solid ${theme.palette.primary.light}`,
				borderRadius: Dimens.OPTION_LABEL_BORDER_RADIUS,
				cursor: 'pointer',
			}}
		>
			<BodyText sx={{
				fontSize: Dimens.BODY_TEXT_MS,
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
				color: selected ? theme.palette.background.default : theme.palette.primary.main,
				flexGrow: 0,
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			}}
			>
				{title}
			</BodyText>
		</Box>
	);
};

export default DateLabel;
