import { Either } from '@ajtec/either-typescript';
import { IProductCategoryReq } from '../entities/product-categories';
import { ICategoryRepository } from '../repository/categories-repository';
import { AxiosError } from 'axios';
import { ApiResponse } from '@/common/data/entities/api-response';

export class AddCategory {
	repository: ICategoryRepository;

	constructor(categoryRepository: ICategoryRepository) {
		this.repository = categoryRepository;
	}

	call(params: IProductCategoryReq): Promise<Either<AxiosError, ApiResponse<string>>> {
		return this.repository.addCategory(params);
	}
}
