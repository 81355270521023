import React from 'react';
import { ClientBillingProvider } from './provider/client-billing.provider';
import ClientBillingPage from './ClientBillingPage';

const ClientBilling = () => (
	<main>
		<ClientBillingProvider>
			<ClientBillingPage />
		</ClientBillingProvider>
	</main>
);

export default ClientBilling;
