import Autocomplete from 'features/backoffice/common/presentation/auto-complete/Autocomplete';
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackofficeProducts } from '../../../providers/product.provider';
import Space from 'common/presentation/components/space/Space';
import BackofficeTextField from 'features/backoffice/common/presentation/input/BackofficeTextField';
import { useBackofficeQuestions } from '../../../providers/question.provider';
import { LoadingButton } from '@mui/lab';

interface Props {
	isDialogOpen: boolean;
	onClose: () => void;
	onSave: () => void
}

const AddAnswerRulesDialog: FC<Props> = (props) => {
	const { t } = useTranslation('translations');
	const { isDialogOpen, onClose, onSave } = props;
	const { products } = useBackofficeProducts();
	const { answrRule, setAnswerRule, answerRuleLoading } = useBackofficeQuestions();

	const ruleType = [
		{ name: t('add'), id: 1 },
		{ name: t('remove'), id: 2 },
	];

	return (
		<Dialog
			open={isDialogOpen}
			fullWidth
			maxWidth="sm"
			onClose={onClose}
			aria-labelledby="rule-dialog-title"
			aria-describedby="rule-dialog-description"
		>
			<DialogTitle id="rule-dialog-title">
				{t('add_rules')}
			</DialogTitle>
			<DialogContent>
				<Space height={2} />
				<Autocomplete
					label={t('products')}
					placeholder={t('products')}
					disabled={answerRuleLoading}
					defaultValue={products.find((el) => el.id === answrRule.productId)}
					options={products || []}
					onChange={(_, value) => setAnswerRule({ ...answrRule, productId: value?.id ?? 0 })}
					renderOption={(prop, option) => <li {...prop}>{option.name}</li>}
					getOptionLabel={(option) => option.name}
				/>
				<Space height={2} />
				<FormControl fullWidth size="small">
					<InputLabel id="demo-simple-select-label">{t('type')}</InputLabel>
					<Select
						label={t('type')}
						size="small"
						fullWidth
						disabled={answerRuleLoading}
						value={answrRule.ruleType}
						onChange={(e) => setAnswerRule({ ...answrRule, ruleType: e.target.value as number })}
					>
						{ruleType.map((el) => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}
					</Select>
				</FormControl>
				<Space height={2} />
				<BackofficeTextField
					label={t('num_devices_handled_by_product')}
					type="number"
					disabled={answerRuleLoading}
					InputProps={{
						inputProps: { min: 0, max: 100 },
					}}
					value={answrRule.devicesHandledByProduct}
					onChange={(e) => setAnswerRule({
						...answrRule,
						devicesHandledByProduct: parseInt(e.target.value, 10),
					})}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="error"
					disabled={answerRuleLoading}
					onClick={onClose}
					sx={{ textTransform: 'none' }}
				>
					{t('cancel')}
				</Button>
				<LoadingButton
					variant="contained"
					onClick={onSave}
					disabled={!answrRule.productId || !answrRule.devicesHandledByProduct || !answrRule.ruleType}
					loading={answerRuleLoading}
					sx={{ textTransform: 'none' }}
				>
					{t('add')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default AddAnswerRulesDialog;
