import { Either } from '@ajtec/either-typescript';
import { ICommunityRequestRepository } from '../repository/community-requests-repository';
import { AxiosError } from 'axios';
import { ICommunityRequest } from '../entities/community-request';

export class GetCommunityRequests {
	repository: ICommunityRequestRepository;

	constructor(communityReqRepository: ICommunityRequestRepository) {
		this.repository = communityReqRepository;
	}

	call(): Promise<Either<AxiosError, ICommunityRequest[]>> {
		return this.repository.getCommunityRequests();
	}
}
