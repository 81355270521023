import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import moment from 'moment';
import { Article } from 'features/community/domain/entities/article';

interface CardArticleProps {
  article: Article;
  onNavigate: (id: number) => void;
}

const CardArticle: React.FC<CardArticleProps> = ({ article, onNavigate }) => {
	const theme = useTheme();

	return (
		<Box
			key={article.id}
			sx={{
				width: {
					xs: '100%',
					sm: `calc(50% - ${Dimens.CARD_PADDING_MOBILE})`,
					md: `calc(33.33% - ${Dimens.CARD_PADDING_MOBILE})`,
					lg: `calc(25% - ${Dimens.CARD_PADDING_MOBILE})`,
				},
				minWidth: '250px',
				cursor: 'pointer',
				border: `2px solid ${theme.palette.textMain.main}`,
				borderRadius: {
					xs: Dimens.COMMUNITY_CARD_SM_BORDER_RADIUS,
					md: '33px 1px 27px 0px / 27px 0px 33px 0px',
				},
				padding: {
					xs: Dimens.CARD_PADDING_MOBILE,
					sm: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL_MOBILE,
					md: Dimens.COMMUNITY_CARD_SM_BORDER_RADIUS,
				},
				boxSizing: 'border-box',
				transition: 'all 0.25s',
				':hover': {
					border: `2px solid ${theme.palette.primary.main}`,
					boxShadow: '2px 2px 4px 0px rgba(77, 255, 255, 0.6)',
					'.hover-grow': {
						fontSize: 'calc(100% + 2px)',
					},
				},
				marginBottom: 3,
			}}
			onClick={() => onNavigate(article.id)}
		>
			<img
				style={{
					width: '100%',
					height: 'auto',
					borderRadius: Dimens.CARD_BORDER_RADIUS,
					maxWidth: '100%',
					objectFit: 'cover',
				}}
				src={article.coverImage}
				alt={article.title}
			/>
			<Stack
				direction="row"
				sx={{
					paddingTop: Dimens.CARD_PADDING_MOBILE,
					width: '100%',
				}}
			>
				<BodyText
					className="hover-grow"
					sx={{
						color: theme.palette.textMain.main,
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
						transition: 'font-size 0.3s ease-in-out',
					}}
				>
					{article?.tags.join(' | ')}
				</BodyText>
				<BodyText
					sx={{
						ml: 2,
						color: theme.palette.textMain.main,
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
						transition: 'font-size 0.3s ease-in-out',
					}}
				>
					|
				</BodyText>
				<BodyText
					sx={{
						ml: 2,
						color: theme.palette.textMain.main,
						transition: 'font-size 0.3s ease-in-out',
					}}
				>
					{moment(article?.publishedDate).format('DD MMM YYYY')}
				</BodyText>
			</Stack>
			<BodyText
				sx={{
					fontSize: Dimens.BODY_TEXT_ML,
					fontWeight: Dimens.FONT_WEIGHT_LIGHTER,
					flexGrow: 1,
				}}
			>
				{article?.title}
			</BodyText>
		</Box>
	);
};

export default CardArticle;
