/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { Endpoints } from 'assets/Endpoints';
import { EnergyConsumptionMonthParams, EnergyConsumptionWeekParams, EnergyConsumptionYearParams } from '../../domain/entities/energy-consumption-period';
import { EnergyConsumptionPeriodModel } from '../dto/energy-consumption-period-model';
import { IEnergyConsumptionPeriodApi } from './energy-consumption-api';
import { ApiResponse } from '@/common/data/entities/api-response';
import { reqPost } from 'common/data/remote/request';

export class EnergyConsumptionApiImpl implements IEnergyConsumptionPeriodApi {
	async getEnergyConsumptionByYear(
		params: EnergyConsumptionYearParams,
	): Promise<EnergyConsumptionPeriodModel[]> {
		try {
			const resp = await reqPost({
				url: Endpoints.ENERGY_CONSUMPTION_PERIOD_YEAR,
				data: { ...params },
			});
			const data = resp.data as ApiResponse<EnergyConsumptionPeriodModel[]>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async getEnergyConsumptionByMonth(
		params: EnergyConsumptionMonthParams,
	): Promise<EnergyConsumptionPeriodModel[]> {
		try {
			const resp = await reqPost({
				url: Endpoints.ENERGY_CONSUMPTION_PERIOD_MONTH,
				data: { ...params },
			});
			const data = resp.data as ApiResponse<EnergyConsumptionPeriodModel[]>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async getEnergyConsumptionByWeek(
		params: EnergyConsumptionWeekParams,
	): Promise<EnergyConsumptionPeriodModel[]> {
		try {
			const resp = await reqPost({
				url: Endpoints.ENERGY_CONSUMPTION_PERIOD_WEEK,
				data: { ...params },
			});
			const data = resp.data as ApiResponse<EnergyConsumptionPeriodModel[]>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
