import { BodyText } from 'common/presentation/components';
import { Box, Card, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BackofficeTextField from 'features/backoffice/common/presentation/input/BackofficeTextField';
import Space from 'common/presentation/components/space/Space';
import { LoadingButton } from '@mui/lab';
import { useBackofficeQuestions } from '../../../providers/question.provider';
import { Form, Formik } from 'formik';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

const BackofficeAddEditQuestions = () => {
	const { questionId } = useParams();
	const { t } = useTranslation('translations');
	const {
		formValues,
		submit,
		getQuestionById,
	} = useBackofficeQuestions();

	useEffectCustom(() => {
		if (questionId) {
			getQuestionById(questionId);
		}
	}, []);

	return (
		<Box>
			<BodyText>
				{questionId
					? t('edit_question')
					: t('add_question')}
			</BodyText>
			<Card
				sx={{
					boxShadow: 'none',
					p: 4,
					mt: 4,
				}}
			>
				<Formik
					initialValues={formValues}
					onSubmit={(values, helpers) => submit(values, helpers, questionId)}
					enableReinitialize
				>
					{({
						errors,
						values,
						handleChange,
						isSubmitting,
					}) => (
						<Form>
							<Grid container spacing={2}>
								<Grid item md={5} xs={12}>
									<BackofficeTextField
										name="description"
										value={values.description}
										error={!!errors.description}
										helperText={errors.description}
										disabled={isSubmitting}
										onChange={handleChange}
										label={t('description')}
										placeholder={t('description')}
									/>
									<Space height={2} />
									<BackofficeTextField
										name="stepOrder"
										type="number"
										value={values.stepOrder}
										error={!!errors.stepOrder}
										helperText={errors.stepOrder}
										disabled={isSubmitting}
										onChange={handleChange}
										label={t('step_order')}
										placeholder={t('step_order')}
									/>
									<Space height={2} />
									<LoadingButton type="submit" loading={isSubmitting} variant="contained">
										{t('save')}
									</LoadingButton>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</Card>
		</Box>
	);
};

export default BackofficeAddEditQuestions;
