import React from 'react';
import useLanguage from 'common/presentation/providers/language.provider';
import i18next from 'i18next';
import {
	Button,
	Menu,
	MenuItem,
	SvgIcon,
	useTheme,
} from '@mui/material';
import { Check, KeyboardArrowDownOutlined, LanguageOutlined } from '@mui/icons-material';
import { LANGUAGES } from '../../../../assets/translations/languages.constant';

const LanguageSelector = () => {
	const { changeLocale } = useLanguage();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChange = (mLang: string) => {
		const locale = mLang === 'pt' ? 'ptPT' : 'enUS';
		i18next.changeLanguage(mLang);
		changeLocale(locale);
		handleClose();
	};

	// <MaterialUISwitch checked={i18next.language.substring(0, 2) === 'pt'}
	// onChange={handleChange} />

	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{
					color: theme.palette.textMain.main,
					'MuiSvgIcon-root': {
						color: theme.palette.primary.main,
					},
				}}
				startIcon={(
					<SvgIcon
						sx={{ color: theme.palette.primary.main }}
					>
						<LanguageOutlined />
					</SvgIcon>
				)}
				endIcon={(
					<SvgIcon
						sx={{ color: theme.palette.primary.main }}
					>
						<KeyboardArrowDownOutlined />
					</SvgIcon>
				)}
			>
				{i18next.language.substring(0, 2)}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{LANGUAGES.map((el) => (
					<MenuItem
						key={el.id}
						sx={{
							justifyContent: 'space-between',
							'&:hover': {
								bgcolor: theme.palette.primary.main,
							},
						}}
						onClick={() => handleChange(el.language)}
					>
						{el.name}
						{el.language === i18next.language.substring(0, 2) && (
							<SvgIcon color="primary">
								<Check fontSize="small" />
							</SvgIcon>
						)}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default LanguageSelector;
