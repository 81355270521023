export const phone = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
	e.target.maxLength = 11;
	let { value } = e.target;
	value = value.replace(/\D+$/g, '');
	value = value.replace(/(\d{3})(\d)/, '$1 $2');
	e.target.value = value;
	return e;
};

export const phoneString = (value?: string) => {
	let val = value;
	val = val?.replace(/(\d{3})(?=\S)/g, '$1 ');
	return val;
};
