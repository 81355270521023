import { Either } from '@ajtec/either-typescript';
import { IAuthRepository } from '../repository/auth-repository';
import { AuthResponse, LoginParams } from '../entities/login';

export class Login {
	repository: IAuthRepository;

	constructor(authRepository: IAuthRepository) {
		this.repository = authRepository;
	}

	call(params: LoginParams): Promise<Either<string, AuthResponse>> {
		return this.repository.login(params);
	}
}
