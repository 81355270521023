import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/translations';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'index.css';
import './assets/fonts/Aventa/Aventa-Black.ttf';
import './assets/fonts/Aventa/Aventa-BlackItalic.ttf';
import './assets/fonts/Aventa/Aventa-Bold.ttf';
import './assets/fonts/Aventa/Aventa-BoldItalic.ttf';
import './assets/fonts/Aventa/Aventa-ExtraBold.ttf';
import './assets/fonts/Aventa/Aventa-ExtraBoldItalic.ttf';
import './assets/fonts/Aventa/Aventa-ExtraLight.ttf';
import './assets/fonts/Aventa/Aventa-ExtraLightItalic.ttf';
import './assets/fonts/Aventa/Aventa-Italic.ttf';
import './assets/fonts/Aventa/Aventa-Light.ttf';
import './assets/fonts/Aventa/Aventa-LightItalic.ttf';
import './assets/fonts/Aventa/Aventa-Medium.ttf';
import './assets/fonts/Aventa/Aventa-MediumItalic.ttf';
import './assets/fonts/Aventa/Aventa-Regular.ttf';
import './assets/fonts/Aventa/Aventa-SemiBold.ttf';
import './assets/fonts/Aventa/Aventa-SemiBoldItalic.ttf';
import './assets/fonts/Aventa/Aventa-Thin.ttf';
import './assets/fonts/Aventa/Aventa-ThinItalic.ttf';
import './assets/fonts/Aventa/AventaItalicVariable.ttf';
import './assets/fonts/Aventa/AventaVariable.ttf';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
);
