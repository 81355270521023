/* eslint-disable no-case-declarations */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import { CartActionKind, ICartAction } from 'common/domain/entities/cart-action';
import { ICartState } from 'common/domain/entities/cart-state';

export const useCartReducer = (state: ICartState, action: ICartAction) => {
	const { type, payload } = action;
	switch (type) {
	case CartActionKind.ADD_TO_CART:
		const id = state.cartProducts.findIndex((product) => product.productId === payload.productId);
		if (id !== -1) {
			return { ...state };
		}
		return { ...state, cartProducts: [...state.cartProducts, { ...payload }] };

	case CartActionKind.REMOVE_FROM_CART:
		return {
			...state,
			cartProducts: state.cartProducts.filter(((product) => (
				product.productId !== payload.productId))),
		};

	case CartActionKind.INCREMENT:
		return {
			...state,
			cartProducts: state.cartProducts.filter(
				(
					(product) => (product.productId === payload.productId ? product.qty += 1 : product.qty)),
			),
		};

	case CartActionKind.DECREMENT:
		return {
			...state,
			cartProducts: state.cartProducts.filter(
				(
					(product) => (
						(
							product.productId === payload.productId && payload.qty !== 1
						) ? product.qty -= 1 : product.qty)),
			),
		};

	case CartActionKind.CLEAR_CART:
		return {
			...state,
			cartProducts: [],
		};

	default:
		return state;
	}
};
