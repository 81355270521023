import Dimens from 'assets/dimens';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { mobileApp } from 'assets/images';
import React from 'react';

const MobileApp = () => {
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Stack alignItems="center" sx={{ mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE } }}>
			<img src={mobileApp} width={isSm ? '90%' : '50%'} alt="Mobile App" />
		</Stack>
	);
};

export default MobileApp;
