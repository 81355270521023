import React from 'react';
import { TextField as MuiTextField, TextFieldProps, useTheme } from '@mui/material';

const BackofficeTextField: React.FC<TextFieldProps> = (props) => {
	const theme = useTheme();

	return (
		<MuiTextField
			autoComplete="off"
			sx={{
				'& .MuiOutlinedInput-root': {
					// '& fieldset': {
					// 	borderColor: theme.palette.divider,
					// 	transition: 'border-color 0.3s',
					// },
				},
				'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					borderColor: theme.palette.primary.main,
				},
			}}
			{...props}
			size="small"
			fullWidth
		/>
	);
};

export default BackofficeTextField;
