enum AppLinks {
	MAIN = '/',
	LANDING = '/',
	COMMUNITY = '/community',
	COMMUNITY_DETAIL = '/community/detail/:id',
	CONTACT_US = '/contact-us',
	STORE = '/store',
	PRODUCT_CATEGORY_DETAIL = '/store/categories/:categoryId',
	PRODUCT = '/store/categories/product-detail/:productId',
	FIND_YOUR_IOKEE = '/find-your-iokee',
	FIND_YOUR_IOKEE_PURPOSE = '/find-your-iokee/purpose',
	FIND_YOUR_IOKEE_FOUND_DEVICES = '/find-your-iokee/found-devices',
	FIND_YOUR_IOKEE_LET_US_CONTACT = '/find-your-iokee/let-us-contact',
	SEARCH_RESULT_PAGE = '/search',
	// Change to user login/profile
	USER_LOGIN = '/auth',
	MOBILE_APP = '/mobile-app',
	CLIENT_AREA = '/client-area',
	CLIENT_AREA_LOGIN = '/client-area/login',
	CLIENT_AREA_FORGOT_PASSWORD = '/client-area/forgot-password',
	CHECKOUT_PAGE = '/checkout',
	FINALIZE_ORDER = '/finalize-order',
	CHECKOUT_SUCCESS = '/checkout-success',
	CLIENT_AREA_AUTH = '/client-area/cliente',
	CLIENT_AREA_DEVICE_LIST = '/client-area/cliente/devices',
	CLIENT_AREA_DEVICE_DETAIL = '/client-area/cliente/devices/device-detail',
	CLIENT_AREA_BILLING= '/client-area/cliente/devices/device-detail/billing',
	CLIENT_AREA_TARIFF = '/client-area/cliente/devices/device-detail/tariff',
	CLIENT_AREA_WALLBOX_HISTORY = '/client-area/cliente/devices/wallbox-history/',
	COOKIE_POLICY = '/cookie-policy',

	// Registeration / Login store
	STORE_MAIN_AUTH_PAGE = '/auth',
	STORE_AREA_REGISTRATION = '/registration',
	STORE_AREA_LOGIN = '/login',
	STORE_BECOME_DISTRIBUTER = '/become-distributer',

	// About us page
	ABOUT_US_PAGE = '/aboutus',

	// BACKOFFICE
	BACKOFFICE_LOGIN = '/backoffice/login',
	BACKOFFICE_DASHBOARD = '/backoffice',
	BACKOFFICE_ADD_PRODUCT = '/backoffice/add',
	BACKOFFICE_EDIT_PRODUCT = '/backoffice/edit/:productId',
	BACKOFFICE_CATEGORIES = '/backoffice/product-categories',
	BACKOFFICE_ADD_PRODUCT_CATEGORIES = '/backoffice/product-categories/new',
	BACKOFFICE_EDIT_PRODUCT_CATEGORIES = '/backoffice/product-categories/edit/:categoryId',
	BACKOFFICE_ORDERS = '/backoffice/orders',
	BACKOFFICE_COMMUNITY_REQUESTS = '/backoffice/community-requests',
	BACKOFFICE_QUESTIONS = '/backoffice/questions',
	BACKOFFICE_ADD_QUESTIONS = '/backoffice/questions/add',
	BACKOFFICE_EDIT_QUESTIONS = '/backoffice/edit/questions/:questionId',
	BACKOFFICE_ADD_ANSWERS = '/backoffice/edit/questions/:questionId/answers'
}

export default AppLinks;
