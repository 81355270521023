export const LANGUAGES = [
	{
		id: 2,
		isoCode: '620',
		name: 'Portuguese',
		phoneCode: '+351',
		shortName: 'PT',
		language: 'pt',
		locale: 'ptPT',
	},
	{
		id: 1,
		isoCode: '620',
		name: 'English',
		phoneCode: '44',
		shortName: 'GB',
		language: 'en',
		locale: 'enUS',
	},
];
