import Dimens from 'assets/dimens';
import {
	Box, Stack, SvgIcon,
} from '@mui/material';
import React, { useState } from 'react';
import { DefaultButton } from 'common/presentation/components';
import { ArrowBack } from '@mui/icons-material';
import { Arrowforwardsquare } from 'assets/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SocialMediaShareLink from './SocialMediaShareLink';

const CommunityDetailHero = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	// const theme = useTheme();

	const [openShareModal, setOpenShareModal] = useState(false);

	const handleGoBack = () => {
		navigate(-1);
	};

	const handleSharePost = () => {
		setOpenShareModal(true);
	};

	const handleCloseShareModal = () => {
		setOpenShareModal(false);
	};

	return (
		<section>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
			}}
			>
				<Stack direction="row" justifyContent="space-between">
					<DefaultButton
						startIcon={<ArrowBack />}
						onClick={handleGoBack}
						endIcon={<div />}
					>
						{t('back_to_blog')}
					</DefaultButton>
					<DefaultButton
						sx={{ color: 'black' }}
						variant="text"
						endIcon={<SvgIcon component="div"><Arrowforwardsquare /></SvgIcon>}
						onClick={handleSharePost}
					>
						{t('share_post')}
					</DefaultButton>
				</Stack>
			</Box>
			<SocialMediaShareLink
				open={openShareModal}
				handleClose={handleCloseShareModal}
				shareLink="http://10.10.10.212:3000/community/detail/2/"
			/>
		</section>
	);
};

export default CommunityDetailHero;
