import Dimens from 'assets/dimens';
import { SizedBox, TitleText } from 'common/presentation/components';
import {
	Box, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import DefaultButton from 'common/presentation/components/default-button/DefaultButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useCommunity from 'features/community/presentation/provider/community.provider';
import i18n from 'assets/translations';
import UserStoryCard from './components/user-story-card/UserStoryCard';

const UserStories = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const navigate = useNavigate();
	const lang = i18n.language;
	const { getArticles, articles } = useCommunity();

	useEffect(() => {
		getArticles(lang);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleReadMoreClick = () => {
		navigate(AppLinks.COMMUNITY);
	};

	return (
		<section>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: Dimens.SECTION_SPACING,
			}}
			>
				<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" alignItems="center">
					<TitleText
						size={Dimens.TITLE_TEXT_L}
						fontStyle="italic"
						dangerouslySetInnerHTML={{ __html: t('user_stories_html').toUpperCase() }}
					/>
					<DefaultButton onClick={handleReadMoreClick}>
						{t('read_more_cases')}
					</DefaultButton>
				</Stack>
				<SizedBox height={4} />
				{articles.length >= 3
				&& (
					<Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
						<Box sx={{ width: { md: '50%', sm: '100%', xs: '100%' } }}>
							<UserStoryCard
								date={articles[0].publishedDate}
								title={articles[0].title}
								tag={articles[0].tags[0]}
								image={articles[0].coverImage}
								imageWidth="100%"
								imageHeight="90%"
								onClick={
									() => navigate(AppLinks.COMMUNITY_DETAIL.formatMap({ id: articles[0].id }))
								}
							/>
						</Box>
						<Stack sx={{ width: { md: '60%', sm: '100%', xs: '100%' }, height: '100%' }}>
							<UserStoryCard
								date={articles[1].publishedDate}
								title={articles[1].title}
								tag={articles[1].tags[0]}
								image={articles[1].coverImage}
								isHorizantal
								imageWidth={isMd ? '50%' : '100%'}
								imageHeight={isMd ? '50%' : '90%'}
								onClick={
									() => navigate(AppLinks.COMMUNITY_DETAIL.formatMap({ id: articles[1].id }))
								}
							/>
							<SizedBox height={2} />
							<UserStoryCard
								isHorizantal
								date={articles[2].publishedDate}
								title={articles[2].title}
								tag={articles[2].tags[0]}
								image={articles[2].coverImage}
								imageWidth={isMd ? '50%' : '100%'}
								imageHeight={isMd ? '50%' : '100%'}
								onClick={
									() => navigate(AppLinks.COMMUNITY_DETAIL.formatMap({ id: articles[2].id }))
								}
							/>
						</Stack>
					</Stack>
				)}
			</Box>
		</section>
	);
};

export default UserStories;
